import React from "react";
import "./flyout-menu.scss";
import { LoggedUser } from "../../types";
import helpers from "../../helpers/helper";

interface FlywayMenuProps {
  showMenu: boolean;
  handleClose: () => void;
  user: LoggedUser;
  setView?: React.Dispatch<React.SetStateAction<string>>;
  currentView?: string;
}

export const FlyoutMenu: React.FC<FlywayMenuProps> = ({ showMenu, handleClose, user, setView, currentView }) => {
  return (
    <nav className={showMenu ? "flyout-menu" : "flyout-menu-closed"} data-testid="flyout-menu">
      <div className="flyout-close-text" onClick={handleClose}>
        Close
      </div>
      <div className="flyout-link-container">
        <div className="flyout-links">
          <div className="user-first-name" data-testid="user-first-name">
            {user && user.firstName && "Hi, " + user.firstName}
            {["ADMIN", "PLANNER", "SUPER ADMIN"].indexOf(user.role) >= 0 && (
              <small>
                <i> ({helpers.toTitleCase(user.role)})</i>
              </small>
            )}
          </div>
          <h3 className="flyout-title">Links</h3>
          <div className="button-row">
            {user.role === "SUPER ADMIN" && user.inFaaNetwork === true && 
              ["reservations", "controlCenter", "programSetup", "reports"].indexOf(currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    onClick={() => {
                      setView!("accounts");
                      handleClose();
                    }}
                  >
                    Accounts
                  </button>
                </div>
              )}
            {((user.role === "PLANNER" && user.inFaaNetwork === true)  || (user.role === "SUPER ADMIN" && user.inFaaNetwork === true)) &&
              ["reservations", "programSetup", "accounts", "reports"].indexOf(currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    onClick={() => {
                      setView!("controlCenter");
                      handleClose();
                    }}
                  >
                    Control Center
                  </button>
                </div>
              )}
            {((user.role === "ADMIN" && user.inFaaNetwork === true) || (user.role === "PLANNER" && user.inFaaNetwork === true) || (user.role === "SUPER ADMIN" && user.inFaaNetwork === true) || (user.role === "FAA" && user.inFaaNetwork === true)) &&
              ["controlCenter", "programSetup", "accounts", "reservations"].indexOf(currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    title="Reports"
                    onClick={() => {
                      setView!("reports");
                      handleClose();
                    }}
                  >
                    Reports
                  </button>
                </div>
              )}
            {(user.role === "PLANNER" || user.role === "SUPER ADMIN" || user.role === "FAA") &&
              ["controlCenter", "programSetup", "accounts", "reports"].indexOf(currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    title="Reservations"
                    onClick={() => {
                      setView!("reservations");
                      handleClose();
                    }}
                  >
                    Reservations
                  </button>
                </div>
              )}
            {user && user.firstName ? (
              <form
                action="/logout"
                method="POST"
                onSubmit={(event) => {
                  const isLoggedin = localStorage.getItem("loggedin");
                  if (isLoggedin === "true") {
                    // restHelpers.get(`${API.LogLogOut}`);
                    localStorage.clear();
                    return true;
                  } else {
                    event.preventDefault();
                    window.location.replace("/");
                    return false;
                  }
                }}
              >
                <input type="hidden" />
                <div className="link-button">
                  <button className="logout-button">Sign Out</button>
                </div>
              </form>
            ) : (
              <div className="link-button" data-testid="login">
                <button className="logout-button" onClick={() => window.location.replace("/signin")}>
                  Sign In
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default FlyoutMenu;
