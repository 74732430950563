import React, { ReactElement, useEffect, useState } from "react";
import { ReservationEvent, Rule, Event } from "../../../types";
import "./dataTable.scss";
import { Card } from "../../cards/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import DataRow from "./DataRow";
import Paginator from "../paginator/paginator";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";

export interface DataTableProps {
  identifier: string;
  reservations: ReservationEvent[];
  resetSearch: boolean;
  airports: string[];
  events?: Event[];
  rules?: Rule[];
}

const reservatonsPerPage = 25;

const DataTable = ({
  identifier,
  reservations,
  resetSearch,
  airports,
  events,
  rules,
}: DataTableProps): ReactElement => {
  const [requestType, setRequestType] = React.useState<String>("");
  const [checkedArrival, setCheckedArrival] = React.useState<boolean>(false);
  const [checkedDeparture, setCheckedDeparture] = React.useState<boolean>(false);
  const [airportFilter, setAirportFilter] = React.useState<string[]>([]);
  const [searchWord, setSearchWord] = React.useState<string>("");
  const [showAirportFilter, setShowAirportFilter] = React.useState<boolean>(false);
  const [pagingParams, setPagingParams] = useState({
    firstReservationIndex: 0,
    lastReservationIndex: reservatonsPerPage,
    maxReservationIndex: 0,
    toResetPaging: false,
  });

  // use useRef rather than onChange input capture to avoid rerendering when typing
  const searchBox = React.useRef<HTMLInputElement>(null);

  const startSearch = (keyEvent: React.KeyboardEvent<HTMLElement>): void => {
    if (keyEvent.key === "Enter") {
      keyEvent.preventDefault();
      const searchStr = searchBox.current?.value;
      setSearchWord(searchStr ? searchStr.toLowerCase().trim() : "");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked === true && requestType === "") {
      setRequestType(event.target.value.toLowerCase());
    } else if (event.target.checked === false && requestType === "") {
      if (event.target.value === "ARRIVAL") {
        setRequestType("DEPARTURE");
      } else if (event.target.value === "DEPARTURE") {
        setRequestType("ARRIVAL");
      }
    } else {
      setRequestType("");
    }
  };

  const applyFilter = (uEvent: ReservationEvent): boolean => {
    const reservation = uEvent.reservationDTO;
    const user = uEvent.user;
    const toKeep =
      (requestType === "" || reservation.requestType.toLowerCase() === requestType.toLowerCase()) &&
      (airportFilter.length === 0 || airportFilter.indexOf(reservation.airportId) >= 0) &&
      (searchWord === "" ||
        reservation.reservNumb.toLowerCase().indexOf(searchWord) >= 0 ||
        reservation.callSign.toLowerCase().indexOf(searchWord) >= 0 ||
        reservation.requestType.toLowerCase().indexOf(searchWord) >= 0 ||
        reservation.aircraftType.toLowerCase().indexOf(searchWord) >= 0 ||
        (reservation.requestorEmail !== undefined &&
          reservation.requestorEmail!.toLowerCase().indexOf(searchWord) >= 0) ||
        (reservation.requestorName !== undefined && reservation.requestorName!.replaceAll(" ",',').toLowerCase().indexOf(searchWord.replaceAll(" ",',')) >= 0) ||
        (user.firstName.toLowerCase() + " " + user.lastName.toLowerCase()).indexOf(searchWord) >= 0);
    return toKeep;
  };

  const resetPaging = () => {
    const displayedRsv = reservations.filter((reserv) => applyFilter(reserv));
    setPagingParams({
      firstReservationIndex: 0,
      lastReservationIndex: reservatonsPerPage,
      maxReservationIndex: displayedRsv.length,
      toResetPaging: !pagingParams.toResetPaging,
    });
  };

  useEffect(() => {
    if (resetSearch) {
      setCheckedArrival(false);
      setCheckedDeparture(false);
      setRequestType("");
      setAirportFilter([]);
      if (searchBox.current) {
        searchBox.current.value = "";
      }
      setSearchWord("");
    }
    resetPaging();
    // eslint-disable-next-line
  }, [identifier, reservations, resetSearch, airports]);

  useEffect(() => {
    resetPaging();
    // eslint-disable-next-line
  }, [requestType, airportFilter, searchWord]);

  function toggle(value: any) {
    return !value;
  }

  function onPagingChange(firstIndex: number, lastIndex: number) {
    setPagingParams({
      firstReservationIndex: firstIndex,
      lastReservationIndex: lastIndex,
      maxReservationIndex: pagingParams.maxReservationIndex,
      toResetPaging: pagingParams.toResetPaging,
    });
  }

  const changeAirportFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cbx = event.target;
    const airportId = cbx.value;
    const filterCopy = [...airportFilter];
    const i = filterCopy.indexOf(airportId);
    if (cbx.checked) {
      if (i < 0) {
        filterCopy.push(airportId);
      }
    } else {
      if (i >= 0) {
        filterCopy.splice(i, 1);
      }
    }
    setAirportFilter(filterCopy);
  };

  const tableRows = reservations
    .filter((reservationEvent) => applyFilter(reservationEvent))
    .sort(
      (a, b) =>
        a.reservationDTO.reservDate.localeCompare(b.reservationDTO.reservDate) ||
        a.reservationDTO.reservTime.localeCompare(b.reservationDTO.reservTime)
    )
    .map((airportReservation, index) => {
      return (
        pagingParams.firstReservationIndex <= index &&
        index < pagingParams.lastReservationIndex && (
          <DataRow
            key={airportReservation.reservationDTO.reservNumb + index}
            identifier={identifier}
            reservationEvent={airportReservation}
            index={index}
            events={events}
            rules={rules}
          />
        )
      );
    });

  const renderAirportFilter = (): ReactElement | null => {
    if (airports.length < 2) return null;

    return (
      <>
        <strong className="filter-subheader">Airports:</strong>
        <div className="airport-filter-container">
          {airports.map((_airport, _index) => {
            return (
              <label className="airport-filter" key={`lab_${_airport}_${_index}`}>
                <input
                  type="checkbox"
                  key={`cbx_${_airport}_${_index}`}
                  value={_airport}
                  onChange={changeAirportFilter}
                />
                &nbsp;{_airport}
              </label>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="filter-and-table">
      <div className="filter-icon-label" onClick={() => setShowAirportFilter(toggle)}>
        <FontAwesomeIcon icon={faFilter} className="filter-icon"></FontAwesomeIcon>
        <h2 className="filter-icon-label">Filter</h2>
      </div>
      <div className={showAirportFilter === true ? "filter-container filter-icon-clicked" : "filter-container"}>
        <h3 className="filter-header">Filter By:</h3>
        {renderAirportFilter()}
        <strong className="filter-subheader">Request:</strong>
        <label className="filter-label">
          <input
            type="checkbox"
            onChange={handleChange}
            onClick={() => setCheckedArrival(toggle)}
            value="ARRIVAL"
            aria-label="ARRIVAL"
            checked={checkedArrival}
          />
          &nbsp; Arrival
        </label>
        <label className="filter-label">
          <input
            type="checkbox"
            onChange={handleChange}
            onClick={() => setCheckedDeparture(toggle)}
            value="DEPARTURE"
            aria-label="DEPARTURE"
            checked={checkedDeparture}
          />
          &nbsp; Departure
        </label>
        <button
          className="filter-close"
          data-testid={`reservation-modal-button-close`}
          aria-label={"Close Reservation Modal"}
          onClick={() => setShowAirportFilter(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <Card additionalClassNames="list-view-card" identifier={identifier}>
        <table className="list-view-table" data-testid="list-view-table">
          <thead>
            <tr data-testid="list-view-column-header">
              <th className="list-view-table-header  list-view-table-padding-left list-view-table-fixed">
                <p className="list-view-header-text">Airport</p>
              </th>
              <th className="list-view-table-header  list-view-table-padding-left list-view-table-fixed-second">
                <p className="list-view-header-text">Call Sign</p>
              </th>
              <th className="list-view-table-header  list-view-table-padding-left">
                <p className="list-view-header-text">Request</p>
              </th>
              <th className="list-view-table-header list-view-table-padding-right  list-view-table-padding-left">
                <p className="list-view-header-text">Date</p>
              </th>
              <th className="list-view-table-header list-view-table-padding-right  list-view-table-padding-left">
                <p className="list-view-header-text">Time</p>
              </th>
              <th className="list-view-table-header list-view-table-padding-right  list-view-table-padding-left">
                <p className="list-view-header-text">Aircraft Type</p>
              </th>
              <th className="list-view-table-header  list-view-table-padding-left">
                <p className="list-view-header-text">Reservation Number</p>
              </th>
              <th className="list-view-table-header list-view-table-scope-padding  list-view-table-padding-left">
                <p className="list-view-header-text">Name</p>
              </th>
              <th className="list-view-table-header list-view-table-scope-padding  list-view-table-padding-left">
                <p className="list-view-header-text"></p>
              </th>
              <th
                colSpan={2}
                className="list-view-table-header list-view-table-scope-padding  list-view-table-padding-left"
              >
                <p className="list-view-header-search">
                  <input
                    ref={searchBox}
                    className="list-view-table-search-field"
                    type="text"
                    placeholder="Keyword Search"
                    onKeyDown={startSearch}
                  />
                  <FontAwesomeIcon className="search-icon" icon={faSearch} title="Keyword Search" />
                </p>
              </th>
            </tr>
          </thead>
          <tbody data-testid="data-table-body">
            {tableRows.length > 0 ? (
              tableRows
            ) : (
              <tr className="list-view-table-row ">
                <td colSpan={9} className="list-view-table-airport-text">
                  <p className="empty-state" data-testid="empty-state">
                    No reservations found.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Paginator
          pageChangeHandler={onPagingChange}
          maxReservationIndex={pagingParams.maxReservationIndex}
          reservatonsPerPage={reservatonsPerPage}
          toResetPaging={pagingParams.toResetPaging}
        />
      </Card>
    </div>
  );
};

export default DataTable;
