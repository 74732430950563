import React from "react";
import "./cancelWarningModal.scss";
import Modal from "react-modal";
import { ActionType, ReservationDTO, ReservationEvent } from "../../../types";
import ConfirmationModal from "../confirmationmodal/ConfirmationModal";
import { API } from "../../../helpers/restHelpers";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface ReservationProps {
  reservationEvent: ReservationDTO;
  userEvent?: ReservationEvent;
  isAdmin?: Boolean;
}

const CancelWarningModal: React.FC<ReservationProps> = ({ reservationEvent, userEvent, isAdmin }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setErrorMessage("");
    setIsOpen(true);
  }

  const handleCancel = async () => {
    var error = Boolean();

    await fetch(API.CancelReservation + "?reservNumb=" + reservationEvent.reservNumb, { method: "DELETE" })
      .then(async (response) => {
        if (response.status === 400) {
          error = Boolean(true);
          setErrorMessage("Sorry, there was an error processing your reservation. Please try again.");
        } else if (response.status !== 200) {
          error = Boolean(true);
          setErrorMessage("No response from the server. Please try again.");
        }
      })
      .catch((err) => ({
        throw: err,
      }));

    if (error === true) {
      return false;
    } else {
      setConfirmCancel(true);
    }
  };

  return (
    <div>
      {isAdmin === true ? (
        <button
          className="admin-cancel-reservation-button"
          data-testid="admin-cancel-button"
          aria-label={"Cancel Reservation"}
          onClick={openModal}
        >
          <FontAwesomeIcon icon={faTimes} className="admin-cancel-icon" size="2xl" />
          Cancel
        </button>
      ) : (
        <button
          className="cancel-reservation-button"
          data-testid="cancel-button"
          aria-label={"Cancel Reservation " + reservationEvent?.airportId + "-" + reservationEvent?.requestType + "-" + reservationEvent?.callSign}
          onClick={openModal}
        >
          Cancel
        </button>
      )}
      {!confirmCancel && (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="confirmation-modal"
            overlayClassName="confirmation-modal-overlay"
            contentLabel="Confirmation Modal"
            ariaHideApp={false}
          >
            {isAdmin === true ? (
              <>
                <button
                  className="confirmation-modal-close"
                  data-testid={`confirmation-modal-button-close`}
                  aria-label={"Close Confirmation Reservation Modal"}
                  onClick={closeModal}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                  <h3 className="admin-cancel-confirm-title">Are you sure you want to cancel?</h3>
                  <div className="cancel-form-fields" data-testid="cancel-airport-id">
                    <label className="cancel-form-label-text"> Airport: {userEvent?.reservationDTO.airportId} </label>
                  </div>
                  <div className="cancel-form-fields" data-testid="cancel-callsign">
                    <label className="cancel-form-label-text"> Call Sign: {userEvent?.reservationDTO.callSign}</label>
                  </div>
                  <div className="cancel-form-fields" data-testid="cancel-request-type">
                    <label className="cancel-form-label-text">
                      {" "}
                      Reservation type:{" "}
                      {userEvent?.reservationDTO.requestType.charAt(0)! +
                        userEvent?.reservationDTO.requestType.substring(1).toLowerCase()}{" "}
                    </label>
                  </div>
                  <div className="cancel-form-fields" data-testid="cancel-date">
                    <label className="cancel-form-label-text">
                      {" "}
                      Date: {dayjs(userEvent?.reservationDTO.reservDate).format("MMMM D, YYYY")}
                    </label>
                  </div>
                  <div className="cancel-form-fields" data-testid="cancel-time">
                    <label className="cancel-form-label-text"> Time: {userEvent?.reservationDTO.reservTime}Z </label>
                  </div>
                  <div className="cancel-form-fields" data-testid="cancel-type">
                    <label className="cancel-form-label-text">
                      {" "}
                      Aircraft Type: {userEvent?.reservationDTO.aircraftType}{" "}
                    </label>
                  </div>
                  <div className="cancel-form-fields" data-testid="cancel-request-type">
                    <label className="cancel-form-label-text">
                      {" "}
                      Name: {userEvent?.reservationDTO.requestorName?.replace(/[,]+/g, " ")}
                    </label>
                  </div>
                </div>
              </>
            ) : (
              <h5>
                Are you sure you want to cancel your {reservationEvent.requestType.toLowerCase()} at{" "}
                {reservationEvent.airportId} on {reservationEvent.reservDate} {reservationEvent.reservTime}Z?
              </h5>
            )}

            <div className="cancel-modal-buttons">
              <button
                className="cancel-modal-close"
                data-testid={`cancel-modal-button-close`}
                aria-label={"Close Confirmation Reservation Modal"}
                onClick={closeModal}
              >
                No
              </button>
              <button
                className="cancel-modal-confirm"
                data-testid={`cancel-modal-button-yes`}
                aria-label={"Close Confirmation Reservation Modal"}
                onClick={handleCancel}
              >
                Yes
              </button>
            </div>
            <div id="error" className="error-message">
              {errorMessage && <p className="error-message"> {errorMessage}</p>}
            </div>
          </Modal>
        </>
      )}
      {confirmCancel && isAdmin && (
        <ConfirmationModal
          actionType={ActionType.CANCEL}
          isAdmin={isAdmin}
          reservationEvent={userEvent?.reservationDTO}
        />
      )}
      {confirmCancel && isAdmin !== true && (
        <ConfirmationModal actionType={ActionType.CANCEL} reservationEvent={reservationEvent} isAdmin={isAdmin} />
      )}
    </div>
  );
};

export default CancelWarningModal;
