import React, { useEffect } from "react";
import "./confirmationDialog.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DialogAnswer } from "../../../types";

export interface ConfirmationDialogProps {
  isOpen: boolean;
  title?:  string;
  description?: JSX.Element;
  question?: JSX.Element;
  yesButtonText?: string;
  yesButtonClass?: string;
  noButtonText?: string;
  noButtonClass?: string;
  cancelButtonText?: string;
  cancelButtonClass?: string;
  closeHandle?: any;
  passthroughObj?: any;
  autoDismissInMs?: number;
  defaultComment?: string;
  dialogWidth?: string;
}

const maxComment = 400;

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ 
      isOpen, title,
      description, question, 
      yesButtonText, yesButtonClass,
      noButtonText, noButtonClass,
      cancelButtonText, cancelButtonClass,
      closeHandle, passthroughObj,
      autoDismissInMs, defaultComment,
      dialogWidth,
     }) => {

  const getDefaultDialogState = () => {
    return {
      isDialogOpen: isOpen,
      closeBy: DialogAnswer.cancel,
      comment: undefined as string | undefined,
    };
  };
  const [dialogState, setDialogState] = React.useState(getDefaultDialogState())

  useEffect(() => {
    setDialogState(getDefaultDialogState());
    if (isOpen && autoDismissInMs) {
      setTimeout(() => {
        setDialogState({
          ...dialogState,
          isDialogOpen: false,
        });
      }, autoDismissInMs);
    }

  // eslint-disable-next-line
  }, [isOpen]);  

  useEffect(() => {
    document.body.style.overflow = dialogState.isDialogOpen ? 'hidden' : "unset";
    if(!dialogState.isDialogOpen && closeHandle) {
      closeHandle(dialogState.closeBy, passthroughObj, dialogState.comment);
    }
  // eslint-disable-next-line
  }, [dialogState.isDialogOpen]);  

  const closeModal = (by: number) => {
    setDialogState({
      isDialogOpen: false,
      closeBy: by,
      comment: dialogState.comment,
    });
  }

  const changeComment = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDialogState({
      ...dialogState,
      comment: ev.target.value,
    })
  };

  return (
    <Modal
    isOpen={dialogState.isDialogOpen}
    className="confirmation-dialog"
    overlayClassName="confirmation-dialog-overlay"
    contentLabel="Confirmation Dialog"
    ariaHideApp={false}
    shouldCloseOnEsc={true}
    style={{content: {width: dialogWidth}}}
    >
      <div className={title ? "dialog-header-underline" : "dialog-header"}>
        {title && (<div className="dialog-title">{title}</div>)}
      <div>&nbsp;</div>
      <button
          className="dialog-xout"
          data-testid={`confirmation-modal-button-close`}
          aria-label={"Close Button"}
          onClick={() => closeModal(DialogAnswer.cancel)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      { description &&
      (<div className="dialog-description-band">
        {description}
      </div>)
      }
      {question && (<div className="dialog-question-band">
        {question}
      </div>)
      }
      {(yesButtonText || noButtonText || cancelButtonText) &&
      (<div className="dialog-button-band">
        {noButtonText && (<button
          className={yesButtonClass ? "dialog-no" : `dialog-no ${noButtonClass}`}
          aria-label={"No Button"}
          onClick={() => closeModal(DialogAnswer.no)}
        >
          {noButtonText}
        </button>)}
        {yesButtonText && (<button
                className={yesButtonClass ? "dialog-yes" : `dialog-yes ${yesButtonClass}`}
                aria-label={"Yes Button"}
                onClick={() => closeModal(DialogAnswer.yes)}
                >
                {yesButtonText}
              </button>)}
        {cancelButtonText && (<button
          className={yesButtonClass ? "dialog-cancel" : `dialog-cancel ${cancelButtonClass}`}
          aria-label={"Cancel Button"}
          onClick={() => closeModal(DialogAnswer.cancel)}
          >
          {cancelButtonText}
        </button>)}
      </div>)
      }
      {defaultComment !== undefined &&(
        <div className="comment-band">
          <div className="comment-label">Comment:</div>
          <textarea className="comment-text" aria-label="Action Comment" maxLength={maxComment}
            value={dialogState.comment} onChange={changeComment}></textarea>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationDialog;
