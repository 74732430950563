import React, { useEffect } from "react";
import restHelpers, { API } from "../helpers/restHelpers";


const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
const inactivMinutes = 15;
const inactiveMS = inactivMinutes * 60 * 1000;

export interface InactiveLogoutProps {
  children: any;
}

const InactiveLogout: React.FC<InactiveLogoutProps> = ({ children }) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logout();
    }, inactiveMS);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {

  //   const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
  //     ev.preventDefault();
  //     if (ev.type === "") {
  //       // Call your function only when the window is closed
  //       restHelpers.get(`${API.LogLogOut}`);
  //     }
  // };
  //   window.addEventListener('beforeunload',handleBeforeUnload);


    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    }); // eslint-disable-next-line
  }, []);

  // const yourFunctionToCallOnWindowClose = () => {
  //   // The function you want to call when the window is closed
  //   restHelpers.get(`${API.LogLogOut}`);
  // };

  // useEffect(() => {
  //   const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
  //     // Check if the event's type is an empty string (indicating window close)
  //     if (ev.type === "") {
  //       // Call your function only when the window is closed
  //       yourFunctionToCallOnWindowClose();
  //     }
  //     // Note: Returning a value here is optional and won't affect modern browsers,
  //     // but some older browsers may need a non-empty return value to display a custom message.
  //     // If you don't need a custom message, you can omit this line.
  //     ev.returnValue = "Are you sure you want to leave?";
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const logout = () => {
    const isLoggedin = localStorage.getItem("loggedin");
    if (isLoggedin) {
      restHelpers.get(`${API.LogLogOut}`); //
      localStorage.clear();
      var form = document.createElement("form");
      form.action = "/logout";
      form.method = "post";
      var sbmt = document.createElement("input");
      sbmt.name = "logout-submit";
      form.appendChild(sbmt);
      document.body.appendChild(form);
      form.submit();
    }
    else {
      window.location.replace("/");
    }
  };

  return children;
};

export default InactiveLogout;
