import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import "./eventWindow.scss";
import { Rule } from "../../../types";
import { getDaysInRange } from "../../../helpers/dateHelper";
import RuleHelper from "../../../helpers/ruleHelper";

interface EventWindowProp {
  type: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  rules?: Rule[];
}

dayjs.extend(utc);


const EventWindow: React.FC<EventWindowProp> = React.memo(({ type, startDate, endDate, rules, startTime, endTime }) => {

  const getWindowDays = (midDate?: string) => {
    const eventDays = getDaysInRange(startDate, endDate, midDate, ["Date and Time"]);
    const rangeStart = eventDays[1];
    const rangeEnd = eventDays.slice(-1)[0];
    const rangeStartDayjs = dayjs(rangeStart);
    const rangeEndDayjs = dayjs(rangeEnd);
    const startDayjs = dayjs(startDate);
    const endDayjs = endDate !== "" ? dayjs(endDate) : rangeEndDayjs.add(1, "month");
    const isFullRange = rangeStartDayjs.isSame(startDayjs) && rangeEndDayjs.isSame(endDayjs);
    const toDayjs = dayjs();

    let scrollToDate;
    if (midDate) {
      scrollToDate = midDate;
    }
    else {
      if (toDayjs.isBefore(rangeStartDayjs || toDayjs.isAfter(rangeEndDayjs))) {
        scrollToDate = rangeStart;
      }
      else {
        scrollToDate = toDayjs.format("MM/DD/YY");
      }
    }

    return {
      eventDays: eventDays,
      isFullRange: isFullRange,
      scrollToDate: scrollToDate,
      rangeStart: rangeStart,
      rangeEnd: rangeEnd,
      rangeStartDayjs: rangeStartDayjs,
      rangeEndDayjs: rangeEndDayjs,
      startDayjs: startDayjs,
      endDayjs: endDayjs,
    }
  }

  const isHDAWindow = type.includes("/");
  const reqType = isHDAWindow ? "ARRIVAL" : type.toUpperCase();

  const [windowDays, setWindowDays] = useState(getWindowDays());

  const scrollTarget = useRef<null | HTMLTableCellElement>(null);
  const rangeStartRef = useRef<null | HTMLTableCellElement>(null);
  const rangeEndRef = useRef<null | HTMLTableCellElement>(null);
  const windowBodyRef = useRef<null | HTMLDivElement>(null);

  const timeArray: string[] = [];
  for (let i = 0; i < 24; i++) {
    let hour = i.toString();
    hour = (hour.length < 2 ? "0" + hour : hour) + "00Z";
    timeArray.push(hour);
  }

  useEffect(() => {

    if (scrollTarget && scrollTarget.current) {
      scrollTarget.current.scrollIntoView({inline: "center"});
    }

    const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.innerHTML === windowDays.rangeStart && windowDays.startDayjs.isBefore(windowDays.rangeStartDayjs)) {
              // reached starting end
              setTimeout(() => {
                setWindowDays(getWindowDays(windowDays.rangeStart));
              }, 700);
            }
            if (entry.target.innerHTML === windowDays.rangeEnd && windowDays.endDayjs.isAfter(windowDays.rangeEndDayjs)) {
              // reached closing end
              setTimeout(() => {
                setWindowDays(getWindowDays(windowDays.rangeEnd));                  
              }, 700);
            }
          }
//        observer.unobserve(entry.target); //<-- call unobserve here
        });
  });

    if (!windowDays.isFullRange) {
      observer.observe(rangeStartRef.current!);
      observer.observe(rangeEndRef.current!);
    }
    return () => {
        observer.disconnect();        
      };
  });

  const getHeadeCellRef = (day: string) => {
    // if all the days are in the window, no need for sroll on demand
    if (windowDays.isFullRange) {
      return undefined;
    }

    switch(day) {
      case windowDays.rangeStart:
        return rangeStartRef;
      case windowDays.rangeEnd:
        return rangeEndRef;
      case windowDays.scrollToDate:
        return scrollTarget;
      default:
        return undefined;
    }
  }

  return (
    <div className="event-window">
      <div className="event-window-title">{`${type} Window`}</div>
      <div className="event-window-body" ref={windowBodyRef}>
        <table className="event-window-table">
          <thead>
            <tr>
              {windowDays.eventDays.map((day, i) => {
                return (
                  <th ref={getHeadeCellRef(day)} key={`ew_day_header_${i}`} className="event-window-day">
                    {day}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {timeArray.map((hour, j) => {
              return (
                <tr key={`ew_row_${j}`}>
                  <td key={`ew_hour_header_${j}`} className="event-window-hour">
                    {hour}
                  </td>
                  {windowDays.eventDays.map((day, i) => {
                    if (i > 0) {
                      let slotSpec = rules 
                        ? RuleHelper.applyRules(day, hour, 
                          rules?.filter((rule) => rule.requestType.toUpperCase() === reqType),
                          startTime ? startDate + " " + startTime: undefined,
                          endTime? endDate + " " + endTime : undefined) 
                        : "-";
                      if (slotSpec !== "-" && isHDAWindow) {
                        slotSpec =  slotSpec.slice(0, slotSpec.indexOf("/"));
                      }
                      return (
                        <td
                          key={`ew_entry_${j}_${i}`}
                          className={slotSpec === "-" ? undefined : "defined-time-slot"}
                        >
                          {slotSpec}
                        </td>
                      );
                    }
                    return "";
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default EventWindow;
