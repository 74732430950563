import React, { useContext, useRef, useState } from "react";
import { ReactComponent as Logo } from "../../static/FAA-logo.svg";
import "./header.scss";
import BetaBanner from "./BetaBanner";
import { DotLinks } from "./DotLinks";
import ExternalLink from "../ExternalLink";
import TickingClock from "./TickingClock";
import { UserInfoContext } from "../../config/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import helpers from "../../helpers/helper";
import restHelpers, { API } from "../../helpers/restHelpers";
import { ReactComponent as Menu } from "../../static/menu.svg";
import FlyoutMenu from "./FlyoutMenu";

export interface HeaderProps {
  currentView?: string;
  setView?: React.Dispatch<React.SetStateAction<string>>;
}

export const Header: React.FC<HeaderProps> = (prop) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { loggedUser: user } = useContext(UserInfoContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <header ref={headerRef}>
      <DotLinks />
      <div className="app-header" data-testid="app-header">
        <div>
          <ExternalLink
            href={"/"}
            metricLog={"Link FAA Logo"}
            testId={"logo-link"}
            ariaLabel={"Federal Aviation Administration Logo Clickable Link"}
            openInNewTab={false}
            className={"faa-home-link"}
          >
            <div className="logo-container">
              <Logo data-testid="logo" />
              <span className="faa-name">Federal Aviation Administration</span>
            </div>
          </ExternalLink>
        </div>

        <div className="app-title-container">
          <h1 className="app-title" data-testid="app-title">
            Fly Reservation
          </h1>
          <div className="vertical-time-block" data-testid="vertical-time-block">
            <TickingClock />
          </div>
        </div>
        <FlyoutMenu
          showMenu={showMenu}
          user={user}
          setView={prop.setView}
          currentView={prop.currentView}
          handleClose={() => {
            setShowMenu(false);
            document.body.style.overflow = "scroll";
          }}
        />
        <div>
          <div className="menu-container" data-testid="menu-container">
            <Menu
              data-testid="menu-icon"
              onClick={() => {
                setShowMenu(true);
                document.body.style.overflow = "hidden";
              }}
            />
          </div>
        </div>
        <div className="time-block" data-testid="time-block">
          <TickingClock />
          <div className="user-first-name" data-testid="user-first-name">
            {user && user.firstName && "Hi, " + user.firstName}
            {["ADMIN", "PLANNER", "SUPER ADMIN"].indexOf(user.role) >= 0 && (
              <small>
                <i> ({helpers.toTitleCase(user.role)})</i>
              </small>
            )}
            {["FAA"].indexOf(user.role) >= 0 && (
              <small>
                <i> ({user.role.toUpperCase()})</i>
              </small>
            )}
          </div>
          <div className="button-row">
            {user.role === "SUPER ADMIN" && user.inFaaNetwork === true &&
              ["reservations", "controlCenter", "programSetup", "reports"].indexOf(prop.currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    aria-label="accounts"
                    onClick={() => prop.setView!("accounts")}
                  >
                    Accounts
                  </button>
                </div>
              )}
            {((user.role === "PLANNER" && user.inFaaNetwork === true) || (user.role === "SUPER ADMIN" && user.inFaaNetwork === true)) &&
              ["reservations", "programSetup", "accounts", "reports"].indexOf(prop.currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    aria-label="control-center"
                    onClick={() => prop.setView!("controlCenter")}
                  >
                    Control Center
                  </button>
                </div>
              )}
            {((user.role === "ADMIN" && user.inFaaNetwork === true) || (user.role === "PLANNER" && user.inFaaNetwork === true) || (user.role === "SUPER ADMIN" && user.inFaaNetwork === true) || (user.role === "FAA" && user.inFaaNetwork === true)) &&
              ["controlCenter", "programSetup", "accounts", "reservations"].indexOf(prop.currentView!) >= 0 && (
                <div className="link-button">
                  <button
                    type="button"
                    className="logout-button"
                    aria-label="reports"
                    title="Reports"
                    onClick={() => prop.setView!("reports")}
                  >
                    Reports
                  </button>
                </div>
              )}
            {(user.role === "PLANNER" || user.role === "SUPER ADMIN" || user.role === "ADMIN" || user.role === "FAA") &&
              ["controlCenter", "programSetup", "accounts", "reports"].indexOf(prop.currentView!) >= 0 && (
                <div className="link-button">
                  <FontAwesomeIcon className="plane-icon" icon={faPlane} />
                  <button
                    type="button"
                    className="logout-button"
                    aria-label="reservations"
                    title="Reservations"
                    onClick={() => prop.setView!("reservations")}
                  >
                    Reservations
                  </button>
                </div>
              )}
            {user && user.firstName ? (
              <form
                action="/logout"
                method="POST"
                data-testid="logout-form"
                onSubmit={(event) => {
                  const isLoggedin = localStorage.getItem("loggedin");
                  if (isLoggedin === "true") {
                    restHelpers.get(`${API.LogLogOut}`);
                    localStorage.clear();
                    return true;
                  } else {
                    event.preventDefault();
                    window.location.replace("/");
                    return false;
                  }
                }}
              >
                <input type="hidden" />
                <div className="link-button" data-testid="logout">
                  <button className="logout-button" aria-label="sign-out">
                    Sign Out
                  </button>
                </div>
              </form>
            ) : (
              <div className="link-button" data-testid="login">
                <button
                  className="logout-button"
                  aria-label="Sign In"
                  onClick={() => window.location.replace("/signin")}
                >
                  Sign In
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <BetaBanner />
    </header>
  );
};
