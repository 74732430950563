import { PropsWithChildren } from "react";
import { tableColumnSpec } from "../../../types";
import "./genericTable.scss";
import { faCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { faCirclePause, faCirclePlay, faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TableProps<T> {
  identifier: string;
  rowList: T[];
  columnList: tableColumnSpec<T>[];
  actionHandlers?: any[];
}

interface RowProps<T> {
  rowId: string;
  rowObject: T;
  columnList: tableColumnSpec<T>[];
  actionHandlers?: any[];
}

function Row<T>({ rowId, rowObject, columnList, actionHandlers }: RowProps<T>) {

  const callActionHandler = (handler: number) => {
    if (actionHandlers && actionHandlers![handler]) {
      actionHandlers![handler]!(rowObject);
    }
  }

  return (
    <tr className="list-view-table-row">
      {columnList.map((col) => (
        <td
          className={`${col.cellClassName} list-view-table-airport-text-generic-table`}
          key={rowId + "-col-" + String(col.key)}
          id={rowId + "-col-" + String(col.key)}
        >
          {col.cellClassName === "list-view-table-col-status" ? (
            <FontAwesomeIcon
              icon={faCircle}
              className={`${String(rowObject[col.key]).toLowerCase().replace(" ", "")}-status-icon`}
              data-testid={`${String(rowObject[col.key]).toLowerCase().replace(" ", "")}-status-icon`}
            />
          ) : (
            ""
          )}
          {!rowObject[col.key] ? "" : String(rowObject[col.key])}
        </td>
      ))}

      <td className="list-view-table-airport-text-generic-table last-one">
        <p>
         {String(rowObject["eventStatus" as keyof T]).toUpperCase() !== "IN PROGRESS" && (
            <button className="planner-activate-button" onClick={() => callActionHandler(2)} aria-label="Comments Button">
              <FontAwesomeIcon icon={faCommentAlt} className="icon-play-circle" />
              Comments
            </button>
          )}
          {String(rowObject["eventStatus" as keyof T]).toUpperCase() !== "EXPIRED" && (
            <button className="planner-modify-button" onClick={() => callActionHandler(0)} aria-label={"modifyButton-" + String(rowObject["eventName" as keyof T])}>
              <FontAwesomeIcon icon={faPen} className="planner-modify-icon" />
              Modify
            </button>
          )}
          {(function () {
            if (String(rowObject["eventStatus" as keyof T]).toUpperCase() === "ACTIVE") {
              return (
                <button className="planner-activate-button" onClick={() => callActionHandler(1)} aria-label={"disableButton-" + String(rowObject["eventName" as keyof T])}>
                  <FontAwesomeIcon icon={faCirclePause} className="icon-play-circle" />
                  Disable&nbsp;
                </button>
              );
            } else if (
              String(rowObject["eventStatus" as keyof T]) === "In Progress" ||
              String(rowObject["eventStatus" as keyof T]) === "Disabled"
            ) {
              return (
                <button className="planner-activate-button" onClick={() => callActionHandler(1)} name="activateButton" aria-label={"activateButton-" + String(rowObject["eventName" as keyof T])}>
                  <FontAwesomeIcon icon={faCirclePlay} className="icon-play-circle" />
                  Activate
                </button>
              );
            } else {
              return null;
            }
          })()}
        </p>
      </td>
    </tr>
  );
}

function GenericTable<T>({
  identifier,
  rowList,
  columnList,
  actionHandlers,
}: PropsWithChildren<TableProps<T>>) {
  return (
    <table className="list-view-table" data-testid="list-view-table">
      <thead>
        <tr data-testid="list-view-column-header">
          {columnList.map((col) => (
            <th
              key={identifier + "-header-" + String(col.key)}
              className="generic-table-header  list-view-table-padding-left list-view-table-fixed"
            >
              {col.key !== "statusButton" && <p className="list-view-header-text">{col.title}</p>}
            </th>
          ))}
          <th className="generic-table-header list-view-table-padding-left list-view-table-fixed">
            <p className="list-view-header-text"></p>
          </th>
        </tr>
      </thead>
      <tbody>
        {rowList.map((object, index) => (
          <Row
            key={`${identifier}-row-${index}`}
            rowId={`${identifier}-row-${index}`}
            rowObject={object}
            columnList={columnList}
            actionHandlers={actionHandlers}
          />
        ))}
      </tbody>
    </table>
  );
}

export default GenericTable;
