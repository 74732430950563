import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";

export interface PaginatorProps {
  firstRowIndex: number;
  lastRowIndex: number;
  maxRowIndex: number;
  rowsPerPage: number;
  setFirstRowIndex: React.Dispatch<React.SetStateAction<number>>;
  setLastRowIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Paginator = (prop: PaginatorProps): ReactElement => {
  function prevPage() {
    prop.setFirstRowIndex(prop.firstRowIndex - prop.rowsPerPage);
    prop.setLastRowIndex(prop.lastRowIndex - prop.rowsPerPage);
  }
  function nextPage() {
    prop.setFirstRowIndex(prop.firstRowIndex + prop.rowsPerPage);
    prop.setLastRowIndex(prop.lastRowIndex + prop.rowsPerPage);
  }

  const totalPages = Math.floor(prop.maxRowIndex / prop.rowsPerPage) + 1;
  const currentPage = Math.floor(prop.firstRowIndex / prop.rowsPerPage) + 1;

  return (
    <>
      {prop.maxRowIndex > prop.rowsPerPage && (
        <div className="table-button-container">
          <button
            className={prop.firstRowIndex === 0 ? "change-page-button-disabled" : "change-page-button"}
            disabled={prop.firstRowIndex === 0 ? true : false}
            onClick={prevPage}
            data-testid="change-page-button-left"
            aria-label="change-page-button-left"
          >
            <FontAwesomeIcon className="change-page-icon" icon={faChevronCircleLeft} />
          </button>
          <div className="page-label">
            Page {currentPage} of {totalPages}
          </div>
          <button
            className={prop.lastRowIndex > prop.maxRowIndex ? "change-page-button-disabled" : "change-page-button"}
            disabled={prop.lastRowIndex > prop.maxRowIndex ? true : false}
            onClick={nextPage}
            data-testid="change-page-button-right"
            aria-label="change-page-button-right"
          >
            <FontAwesomeIcon className="change-page-icon" icon={faChevronCircleRight} />
          </button>
        </div>
      )}
    </>
  );
};

export default Paginator;
