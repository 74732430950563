import React, { useEffect, useState } from "react";
import "./availabilityModal.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as GraphLogo } from "../../../static/Availability-Graph-Icon.svg";
import { Rule } from "../../../types";
import AvailabilityGraph from "./AvailabilityGraph";

export interface AvailabilityProps {
  eventName: string;
  rules: Rule[];
  eventType: string;
  eventStartTime: Date;
}

const AvailabilityModal: React.FC<AvailabilityProps> = ({ eventName, rules, eventType, eventStartTime }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedAirport, setSelectedAirport] = useState("");
  const [requestType, setRequestType] = useState("");
  const [uniqueEventAirport, setUniqueEventAirport] = React.useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target.name;
    if (target === "airportName") {
      setSelectedAirport(event.target.value);
    } else {
      setRequestType(event.target.value);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setRequestType("");
    setSelectedAirport("");
  }

  useEffect(() => {
    let uniqueEvents = rules!
      .map((item) => item.eventName)
      .filter((value, index, self) => self.indexOf(value) === index);
    const uniqueTags: string[] = [];
    const tempEvents: string[] = [];
    rules!.map((rule) => {
      if (uniqueTags.indexOf(rule.airportId + "|" + rule.eventName + "-" + rule.airportName) === -1) {
        // SE
        uniqueTags.push(rule.airportId + "|" + rule.eventName + "-" + rule.airportName);
      }
      return null;
    });
    // eslint-disable-next-line
    uniqueEvents = tempEvents.sort().concat(uniqueEvents.sort());
    setUniqueEventAirport(uniqueTags.sort());

    // eslint-disable-next-line
  }, [modalIsOpen]);

  return (
    <>
      <button className="availability-button" data-testid={`${eventName}-availability-button`} onClick={openModal}>
        <GraphLogo className="graph-logo" />
        Availability
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="availability-modal"
        overlayClassName="availability-modal-overlay"
        contentLabel="Availability Modal"
        ariaHideApp={false}
      >
        <button
          className="availability-modal-close"
          data-testid={`availability-modal-button-close`}
          aria-label={"Close Availability Modal"}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h1>{eventName} Availability</h1>
        {eventType === "HDA" ? (
          <AvailabilityGraph
            selectedAirport={eventName}
            requestType={"Arrival-Departure"}
            eventName={eventName}
            rules={rules}
            eventStartTime={eventStartTime}
          />
        ) : (
          <div className="availability-fields-container">
            <div className="availability-fields">
              <label className="availability-label-text"> Airport:</label>
              <div onChange={handleChange}>
                <select
                  className="availability-dropdown"
                  name="airportName"
                  data-testid="airportName"
                  aria-label="Airport Name"
                  value={selectedAirport}
                >
                  <option disabled key="default-option"></option>
                  {uniqueEventAirport.length > 0 ? (
                    uniqueEventAirport.map((airport: string) =>
                      eventName === airport.substring(4, airport.indexOf("-")) ? (
                        <option key={`airport-option-${airport.slice(0, 3)}`} value={airport.slice(0, 3)}>
                          {airport.substring(airport.indexOf("-") + 1, airport.length)} ({airport.slice(0, 3)})
                        </option>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
            <div className="availability-fields">
              <label className="availability-label-text"> Type:</label>
              <div onChange={handleChange}>
                <select
                  className="availability-request-type"
                  name="requestType"
                  data-testid="requestType"
                  aria-label="Request Type"
                  value={requestType}
                >
                  <option disabled key="default-option"></option>
                  <option key={`Arrival`} value={"Arrival"}>
                    Arrival
                  </option>
                  <option key={`Departure`} value={"Departure"}>
                    Departure
                  </option>
                </select>
              </div>
            </div>
          </div>
        )}
        {selectedAirport !== "" && requestType !== "" ? (
          <AvailabilityGraph
            selectedAirport={selectedAirport}
            requestType={requestType}
            eventName={eventName}
            rules={rules}
            eventStartTime={eventStartTime}
          />
        ) : (
          <div>&nbsp;</div>
        )}
      </Modal>
    </>
  );
};

export default AvailabilityModal;
