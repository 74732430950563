import React, { useEffect } from "react";
import Select, { SingleValue, ActionMeta } from 'react-select';
import "./airportRules.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faTimes, faClock } from "@fortawesome/free-solid-svg-icons";
import { Airport, Rule } from "../../../types";
import restHelpers, { API } from "../../../helpers/restHelpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DatePicker from "../datepicker/DatePicker";
import IntervalPickerComponent from "../intervalpickercomponent/IntervalPickerComponent";
import EventWindow from "../eventwindow/eventWindow";
import { getAndApplyLogic } from "../../../helpers/stateHelpers";
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export interface AirportSelectOption {
  label: string,
  value: string,
}
export interface AirportRulesProps {
  closeAirportRules?: any;
  eventName: string;
  airportId?: string;
  eventStartDate: string;
  eventEndDate: string;
  eventStartTime?: string;
  eventEndTime?: string;
  airportOptions: AirportSelectOption[];
}

const AirportRules: React.FC<AirportRulesProps> = ({
  closeAirportRules,
  eventName,
  airportId,
  eventStartDate,
  eventEndDate,
  airportOptions,
  eventStartTime,
  eventEndTime,
}) => {
  const [calendarIsOpen, setCalendarIsOpen] = React.useState(false);
  const [timePickerIsOpen, setTimePickerIsOpen] = React.useState(false);
  const [endByCalendarIsOpen, setEndByCalendar] = React.useState(false);
  const emptyInterval = () => {
    return {
      timeIntervals: [] as string[],
      timeIntervalString: "",
      textareaHeight: 1,
    };
  };

  const getAirport = (airportId: string): Airport => {
    const airportOpt = airportOptions.find((ap) => ap.value === airportId);
    const airport: Airport = {
      airportId: airportOpt!.value,
      airportName: airportOpt!.label.slice(0, -6),
    };
    return airport;
  }

  const todayjs = dayjs(dayjs().utc().format("MMMM DD, YYYY HHmm"), "MMMM DD, YYYY HHmm", true);
  const startDayjs = dayjs.utc(eventStartDate + " " + eventStartTime, "MMMM DD, YYYY HHmm[Z]", true)

  const [timeSpecs, setTimeSpecs] = React.useState(emptyInterval());
  const [rules, setRules] = React.useState<Rule[]>([]);
  const [fixedAirport, setFixedAirport] = React.useState(airportId ? getAirport(airportId) : undefined);
  const [refreshRules, setRefreshRules] = React.useState(0);
  const startDateInput = React.useRef<HTMLInputElement>(null);
  const endDateInput = React.useRef<HTMLInputElement>(null);
  const initialFormValues = {
    programType: eventName === airportId ? "HDA" : "SE",
    airportId: airportId,
    airportName: fixedAirport?.airportName,
    startDate: "",
    endDate: "",
    requestType: eventName === airportId ? "ARRIVAL" : "",
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    repeatWithoutEndDate: "",
  };
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [isRepeatChecked, setIsRepeatChecked] = React.useState(false);
  const [dailyChecked, setDailyChecked] = React.useState(false);

  const weekdayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<number[]>([]);

  const handleWeekdayCheckboxChange = (weekday: number) => {
    if(selectedCheckboxes.length===1 && selectedCheckboxes.includes(weekday))
    {
      setDailyChecked(true);
    }
    else{
      setDailyChecked(false);
    }
    if (selectedCheckboxes.includes(weekday)) {
      setSelectedCheckboxes(selectedCheckboxes.filter(day => day !== weekday));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, weekday]);
    }
  }

  function handleDailyChange(event: any) {
    if(selectedCheckboxes.length!==0)
    {
      setDailyChecked(event.target.checked);
      if (event.target.checked) {
        setSelectedCheckboxes([]);
        const checkboxes = document.querySelectorAll('.weekday-checkbox');
        checkboxes.forEach((checkbox) => {
          (checkbox as HTMLInputElement).checked = false;
        });
      }
    }
  }

  function handleRepeatCheckboxChange(event: any) {
    const checked = event.target.checked;
    setIsRepeatChecked(checked);
    setDailyChecked(checked);

    if (!checked) {
      setSelectedCheckboxes([]);
      const checkboxes = document.querySelectorAll('.weekday-checkbox');
      checkboxes.forEach((checkbox) => {
        (checkbox as HTMLInputElement).checked = false;
      });
    }

    setFormValues(prevValues => ({...prevValues, endDate: checked ? eventEndDate : ""}));
    setFormValues(prevValues => ({...prevValues, repeatWithoutEndDate: checked && isHDAWithoutEndDate() ? "true" : ""}));

  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    if(event.target.name === "startDate"){
      clearIntervals();
      // if (formValues.endDate && dayjs(formValues.startDate).isAfter(dayjs(formValues.endDate))) {
      //   setFormValues(prevValues => ({...prevValues, endDate: ""}));
      // }
    }

    if(event.target.name === "repeatWithoutEndDate" && event.target.value === "true") {
      setFormValues(prevValues => ({...prevValues, endDate: ""}));
    }
  };

  const setNativeInput = (inputRef: any, value: string) => {
    const setter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set;
    setter!.call(inputRef, value);
    const inputEvent = new Event("input", { bubbles: true });
    inputRef.dispatchEvent(inputEvent);
  };

  const setStartDate = (date: string) => setNativeInput(startDateInput.current!, dayjs(date).format("MMMM DD, YYYY"));
  const setEndDate = (date: string) => setNativeInput(endDateInput.current!, dayjs(date).format("MMMM DD, YYYY"));

  const clearIntervals = () => {
    setTimeSpecs(emptyInterval());
}

  const resetRuleForm = () => {
    clearIntervals();
    setFormValues({
      ...initialFormValues,
      airportId: fixedAirport?.airportId,
      airportName: fixedAirport?.airportName,
    });
    setRefreshRules(refreshRules + 1);
    if(isRepeatChecked)
    {
      document.getElementById("repeat-button")?.click();
    }
  };

  const canSaveRule = (): boolean => {
    let canSave = formValues.airportId !== "" && formValues.startDate !== "" && 
      formValues.requestType !== "" && timeSpecs.timeIntervals.length > 0;
      if (formValues.programType === "SE") {      
        canSave &&= (formValues.q1 !== "" && formValues.q2 !== "" && formValues.q3 !== "" && formValues.q4 !== "");    
      }
      else {
        canSave &&= (formValues.q1 !== "");
      }

      canSave &&= (isRuleEndDateDisabled() || formValues.endDate !== "");
    return canSave;
  };

  const saveRule = async (event: any) => {
    event.preventDefault();

    var isRepeat = "N";
    if(isRepeatChecked){
      isRepeat="Y";
    }

    var occurrence = "";
    if(selectedCheckboxes.length>0)
    {
      occurrence = selectedCheckboxes.sort().join().toString();
    }
    else if (dailyChecked){
      occurrence = "0,1,2,3,4,5,6";
    } 

    const ruleData: Rule = {
      eventName: eventName,
      airportId: fixedAirport ? fixedAirport.airportId : formValues.airportId!,
      airportName: fixedAirport ? fixedAirport.airportName : formValues.airportName!,
      requestType: formValues.requestType,
      startDate: new Date(formValues.startDate),
      startTime: "00:00:00",
      endDate: formValues.endDate ? new Date(formValues.endDate) : undefined,
      endTime: formValues.endDate ? "23:00:00" : undefined,
      q1: +formValues.q1,
      q2: formValues.q2 ? +formValues.q2 : 0,
      q3: formValues.q3 ? +formValues.q3 : 0,
      q4: formValues.q4 ? +formValues.q4 : 0,
      isRepeat:  isRepeat,
      occurrence: occurrence,
    };
    const requestTimeIntervals = timeSpecs.timeIntervals.map((interval) =>
      (interval.substring(0, 2) + ":" + interval.substring(2)).replace("Z", ":00")
    );
    const url = `${API.CreateRule}?times=${requestTimeIntervals}`;
    await restHelpers.post(url, ruleData).catch(() => {
      console.log("error creating event");
    });

    if (!fixedAirport) {
      setFixedAirport({
        airportId: formValues.airportId!,
        airportName: formValues.airportName!,
      });
    }

    resetRuleForm();
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    closeAirportRules();
  };

  function openDatePicker(whichDate: string) {
    if (whichDate === "startDate") {
      if (formValues.startDate === "") {
        setFormValues(prevValues => ({...prevValues, startDate: getMinStartDate()}));
      }
      setCalendarIsOpen(true);
    }
    else if (whichDate === "endDate") {
      if (formValues.endDate === "") {
        setFormValues(prevValues => ({...prevValues, endDate: eventEndDate}));
      }
      setEndByCalendar(true);
    }
    // setCalendarIsOpen(true);
  }

  function openTimePicker() {
    setTimePickerIsOpen(true);
  }

  const setTimeIntervals = (intervals: string[]) => {
    setTimeSpecs({
      timeIntervals: intervals,
      textareaHeight: intervals.length > 0 ? Math.ceil(intervals.length / 4) : 1,
      timeIntervalString: intervals.join(", "),
    });
  };

  const handleAirportChange = (
    newValue: SingleValue<any>,
    action: ActionMeta<string>
  ) => {
    if (action.action === 'select-option') {
      setFormValues(prevValues => ({
        ...prevValues, 
        airportId: newValue!.value,
        airportName: newValue!.label.slice(0, -6),
      }));
    }
  };

  const getMinStartDate = () => {
    let minDate = todayjs.format("MMMM DD YYYY");
    if (todayjs.isBefore(startDayjs)) {
      minDate = startDayjs.format("MMMM DD YYYY");
    }
    return minDate;
  };

  const getMinEndDate = () => {
    if (formValues.startDate) {
      return formValues.startDate;
    }
    return getMinStartDate();
  };

  const getMaxEndDate = () => 
  formValues.endDate ? formValues.endDate : eventEndDate;


  const getMinStartTime = () => {
    let startTime = undefined as string | undefined;

    // if rule start date is the same as event start date
    // rule time cannot be earlier than start time
    if (startDayjs.isSame(dayjs.utc(formValues.startDate), "day")) {
      startTime = eventStartTime!.slice(0, 2) + "00Z";
    }

    // if rule start date is the same as today
    // rule time cannot be earlier current time
    if (todayjs.isSame(dayjs.utc(formValues.startDate), "day")) {
      const currentTime = todayjs.format("HHmm").slice(0, 2) + "00Z";

      if (!startTime || currentTime > startTime) {
        startTime = currentTime;
      }
    } 

    return startTime;
  }

  const getMaxEndTime = () => {
    let endTime = undefined;
    if (eventEndTime && eventEndDate && dayjs.utc(eventEndDate).isSame(dayjs.utc(formValues.startDate, "day"))) {
      // if rule start date is the same as event end date
      endTime = eventEndTime!.slice(0, 2) + "00Z";;
    }
    return endTime;
  }

  const isIntervalDisabled = () => 
    formValues.startDate === "" || formValues.startDate === undefined;
  
  const isRuleEndDateDisabled = () =>
    !isRepeatChecked  || formValues.repeatWithoutEndDate === "true";

  const isHDAWithoutEndDate = () =>
    formValues.programType === "HDA" && !eventEndDate;

  useEffect(() => {
    if (fixedAirport) {
      const ruleUrl = encodeURI(`${API.EventAirportRules}?eventName=${eventName}&airportId=${fixedAirport.airportId}`);
      getAndApplyLogic(ruleUrl, (jsonRules: Rule[]) => {
        const airportRules = jsonRules.map((rule) => rule as Rule);
        setRules(airportRules);
      });
    }
    // eslint-disable-next-line
  }, [refreshRules]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal
        isOpen={true}
        className="airport-spec-modal"
        overlayClassName="reservation-modal-overlay"
        contentLabel="Reservation Modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
      >
        <div className="airport-spec-limit">
          <div className="reservation-limits-label">Define Reservation Limits: (Add as many as necessary)</div>
          <div className="modal-body">
            <form className="airport-rules-form-container" data-testid="reservation-form-container">
              <h3 className="airport-rules-modal-title">Airport Specifications</h3>
              <ul className="airport-rules-items-list">
                <li>
                  <div className="airport-form-fields">
                    <label className="airport-form-label-text">Airport:&nbsp;&nbsp;&nbsp;</label>
                    {fixedAirport
                    ? (<div className="airport-name-fixed">{`${fixedAirport.airportName} (${fixedAirport.airportId})`}</div>) 
                    : <Select
                      className="airport-name-dropdown"
                      defaultValue={airportOptions[0] as any}
                      isSearchable={true}
                      name="Airport"
                      aria-label="Airport Select"
                      options={airportOptions as any}
                      onChange={handleAirportChange}
                      isClearable={true}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: '#707070',
                          borderWidth: 2,
                          backgroundColor: '#f0f0f0',
                        }),
                      }}
                    />
                  }
                  </div>
                </li>
                <div className="reservation-limits-input-container">
                  { formValues.programType === "SE" &&
                    (<li>
                      <div className="airport-form-fields-boxed airport-form-first">
                        <label className="airport-form-label-text"> Reservation Type:</label>
                        <div className="request-type-container">
                          <label className="airport-radio-button">
                            <input
                              type="radio"
                              name="requestType"
                              aria-label="arrivalRequestType"
                              data-testid="arrivalRequestType"
                              value="ARRIVAL"
                              checked={formValues.requestType === "ARRIVAL"}
                              onChange={handleChange}
                            />{" "}
                            Arrival
                          </label>
                          <label className="airport-radio-button">
                            <input
                              type="radio"
                              name="requestType"
                              aria-label="departureRequestType"
                              data-testid="departureRequestType"
                              value="DEPARTURE"
                              checked={formValues.requestType === "DEPARTURE"}
                              onChange={handleChange}
                            />{" "}
                            Departure
                          </label>
                        </div>
                      </div>
                    </li>)
                  }
                  <li>
                    <div className={"airport-form-fields-boxed" + (formValues.programType === "HDA" ? " airport-form-first" : "")}>
                      <label className="airport-form-label-text">Date:</label>
                      <input
                        ref={startDateInput}
                        type="text"
                        className="airport-spec-date-pointer airport-form-input"
                        onClick={() => openDatePicker("startDate")}
                        name="startDate"
                        data-testid="date"
                        aria-label="airportStartDate"
                        value={formValues.startDate}
                        autoComplete="off"
                        onChange={handleChange}
                      />
                      <button
                        className="open-calendar-airport"
                        aria-label={"startDateButton"}
                        name="startDateButton"
                        data-testid="open-calendar"
                        onClick={() => openDatePicker("startDate")}
                        type="button"
                      >
                        <FontAwesomeIcon icon={faCalendarDays} />
                      </button>
                    </div>
                  </li>
                  <li style={{ height: `${timeSpecs.textareaHeight}rem` }}>
                    <div className="airport-form-fields-boxed">
                      <label className="airport-form-label-text"> Reservation Times:</label>
                      <textarea
                        style={{ height: `${timeSpecs.textareaHeight}rem` }}
                        className={isIntervalDisabled() ? "program-setup-times-disabled airport-form-input" : "program-setup-times-pointer airport-form-input"}
                        onClick={openTimePicker}
                        value={timeSpecs.timeIntervalString}
                        onChange={() => {}}
                        name="times"
                        data-testid="times"
                        aria-label="times"
                        autoComplete="off"
                        id="times"
                        disabled={isIntervalDisabled()}
                      />
                      <button
                        className={isIntervalDisabled() ? "calendar-airport-disabled" : "open-calendar-airport"}
                        aria-label={"Open Time Interval"}
                        data-testid="open-time-interval"
                        onClick={openTimePicker}
                        type="button"
                        disabled={isIntervalDisabled()}
                      >
                        <FontAwesomeIcon icon={faClock} />
                      </button>
                    </div>
                  </li>
                  <li className="reservation-per-quarter-list">
                    <div className="airport-form-fields-boxed">
                      <label className="airport-form-label-text"> Number of Reservations:</label>
                      <table className="reservation-per-quarter-table">
                        {/* <caption>Table Title</caption> */}
                        <thead>
                          <tr>
                            <th colSpan={4} className="quarterly-bucket-table-header">
                              {formValues.programType === "SE" ? "# of reservations per quarter" : "# of reservations per hour"}
                            </th>
                          </tr>
                          {formValues.programType === "SE" &&
                          (<tr>
                            <th className="quarterly-bucket">00 min</th>
                            <th className="quarterly-bucket">15 min</th>
                            <th className="quarterly-bucket">30 min</th>
                            <th className="quarterly-bucket">45 min</th>
                          </tr>)
                          }
                        </thead>
                        <tbody>
                          <tr>
                            {formValues.programType === "HDA"
                            ?(<><td colSpan={4} align="center">
                              <input
                                type="number"
                                className="table-input-text"
                                value={formValues.q1}
                                name="q1"
                                aria-label="q1"
                                onChange={handleChange}
                                min="0"
                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                autoComplete="off"
                              />
                            </td></>)
                            : (<><td>
                              <input
                                type="number"
                                className="table-input-text"
                                value={formValues.q1}
                                name="q1"
                                aria-label="q1"
                                onChange={handleChange}
                                min="0"
                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="table-input-text"
                                value={formValues.q2}
                                name="q2"
                                aria-label="q2"
                                onChange={handleChange}
                                min="0"
                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="table-input-text"
                                value={formValues.q3}
                                name="q3"
                                aria-label="q3"
                                onChange={handleChange}
                                min="0"
                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="table-input-text"
                                value={formValues.q4}
                                name="q4"
                                aria-label="q4"
                                onChange={handleChange}
                                min="0"
                                onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                autoComplete="off"
                              />
                            </td></>)
                          }
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li className="occurrence-container">
                    <div className="repeat-button-container">
                      Occurrence:
                      <div>
                        <input
                          type="checkbox"
                          value="repeat"
                          onChange={handleRepeatCheckboxChange}
                          id="repeat-button"
                        >
                        </input>
                        <label className="repeat-label" htmlFor="repeat-button">&nbsp;Repeat</label>
                      </div>
                    </div>
                    <div className="days-of-week-repeat">
                      <div className="days-of-week-repeat-top">
                        <div className="days-of-week-repeat-top-daily">
                          <input
                            type="checkbox"
                            id="daily-button"
                            checked={dailyChecked}
                            onChange={handleDailyChange}
                            disabled={isRepeatChecked === true ? false : true}
                          />
                          <label className={isRepeatChecked === true ? "daily-label" : "daily-label grayed-out"} htmlFor="daily-button">&nbsp;Daily</label>
                        </div>
                        <div className="days-of-week-repeat-top-days">
                          {weekdayLabels.map((day, index) => (
                              <div key={day}>
                                <input
                                  type="checkbox"
                                  className="weekday-checkbox"
                                  onChange={() => handleWeekdayCheckboxChange(index)}
                                  id={day}
                                  disabled={isRepeatChecked === true ? false : true}
                                  />
                                <label className={isRepeatChecked === true ? "weekday-labels" : "weekday-labels grayed-out"} htmlFor={day}>&nbsp;{day}</label>
                              </div>
                          ))}  
                        </div>   
                      </div>
                        <div className="days-of-week-repeat-bottom">
                        {isHDAWithoutEndDate()
                         ? (<div onChange={handleChange}>
                            <label className={isRepeatChecked ? "repeat-range-label-small" : "repeat-range-label-small grayed-out"}>Range:&nbsp;&nbsp;</label>
                            <input type="radio"
                                  value="true" 
                                  disabled={!isRepeatChecked}
                                  checked={formValues.repeatWithoutEndDate === "true"}
                                  name="repeatWithoutEndDate" />
                            <label className={isRepeatChecked ? "repeat-range-label" : "repeat-range-label grayed-out"}>&nbsp;No End Date</label>
                            <input type="radio" 
                                  disabled={!isRepeatChecked}
                                  value="false" 
                                  checked={formValues.repeatWithoutEndDate === "false"}
                                  name="repeatWithoutEndDate" />
                            <label className={isRepeatChecked ? "repeat-range-label-small" : "repeat-range-label-small grayed-out"}>&nbsp;End by:</label>
                          </div>) 
                         : (<><label className={isRepeatChecked ? "airport-form-label-text" : "airport-form-label-text grayed-out"}>End by:</label>
                         </>)                         
                        }
                        <input
                          ref={endDateInput}
                          type="text"
                          className={isRuleEndDateDisabled() ? "airport-spec-date-pointer-grayed-out airport-form-input-narrow" : "airport-spec-date-pointer airport-form-input-narrow"}
                          onClick={() => openDatePicker("endDate")}
                          name="endDate"
                          data-testid="endDate"
                          aria-label="endDate"
                          value={isRepeatChecked === true ? formValues.endDate : ""}
                          autoComplete="off"
                          disabled={isRuleEndDateDisabled()}
                          onChange={handleChange}
                        />
                        <button
                          className={isRuleEndDateDisabled() ? "open-calendar-airport grayed-out" : "open-calendar-airport"}
                          aria-label={"endDateButton"}
                          data-testid="open-calendar"
                          onClick={() => openDatePicker("endDate")}
                          type="button"
                          disabled={isRuleEndDateDisabled()}
                        >
                          <FontAwesomeIcon icon={faCalendarDays} />
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <button
                      className={canSaveRule() ? "save-program-button" : "save-program-button-disabled"}
                      aria-label={"saveRule"}
                      data-testid="save-rule"
                      onClick={saveRule}
                      disabled={canSaveRule() ? false : true}
                    >
                      Save
                    </button>
                  </li>
                </div>
              </ul>
            </form>
          </div>
          <div className="close-button">
            <button
              className="reservation-modal-close"
              data-testid={`reservation-modal-button-close`}
              aria-label={"closeModal"}
              onClick={closeModal}
              name="closeModal"
            >
              <FontAwesomeIcon icon={faTimes} title="close" />
            </button>
          </div>
        </div>
        <hr className="horizontal-spacer" />
        <div className="depart-arive-windows">
          {formValues.programType === "HDA" 
          ? (<EventWindow type="Arrival/Departure" startDate={eventStartDate} endDate={eventEndDate} rules={rules} startTime={eventStartTime} endTime={eventEndTime} />)
          : (<><EventWindow type="Arrival" startDate={eventStartDate} endDate={eventEndDate} rules={rules} startTime={eventStartTime} endTime={eventEndTime} />
            <EventWindow type="Departure" startDate={eventStartDate} endDate={eventEndDate} rules={rules} startTime={eventStartTime} endTime={eventEndTime} />
            </>)}
        </div>

        {calendarIsOpen === true && (
          <div className="calendar-container-airport-rules">
            <DatePicker
              setDate={setStartDate}
              setDatePickerIsOpen={setCalendarIsOpen}
              currentSelectedDate={formValues.startDate ? formValues.startDate : getMinStartDate()}
              startDate={getMinStartDate()}
              endDate={getMaxEndDate()}
            />
          </div>
        )}
        {endByCalendarIsOpen === true && (
          <div className="calendar-container-airport-rules">
            <DatePicker
              setDate={setEndDate}
              setDatePickerIsOpen={setEndByCalendar}
              currentSelectedDate={formValues.endDate ? formValues.endDate : (eventEndDate ? eventEndDate : getMinEndDate())}
              startDate={getMinEndDate()}
              endDate={eventEndDate}
            />
          </div>
        )}
        {timePickerIsOpen === true && (
          <div className="interval-container">
            <IntervalPickerComponent
              selectedTimes={timeSpecs.timeIntervals}
              setIntervals={setTimeIntervals}
              setIntervalPickerIsOpen={setTimePickerIsOpen}
              minInterval={getMinStartTime()}
              maxInterval={getMaxEndTime()}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AirportRules;
