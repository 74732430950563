import React, { useEffect, useState } from "react";
import "./publicView.scss";
import SectionHeader from "../../components/SectionHeader";
import ReservationCard from "../../components/cards/reservationcards/ReservationCard";
import restHelpers, { API } from "../../helpers/restHelpers";
import { getAndApplyLogic } from "../../helpers/stateHelpers";
import { ReservationDTO, Event, Rule } from "../../types";
import { faFileArrowDown, faFilter, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/shared/paginator/paginator";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import GroupAccessComponent from "../../components/shared/groupaccesscomponent/GroupAccessComponent";

export interface PublicPageProps {
  events?: Event[];
  rules?: Rule[];
}

const PublicView: React.FC<PublicPageProps> = ({ events, rules }) => {
  const reservatonsPerPage = 25;
  const [userReservations, setUserReservations] = useState<ReservationDTO[]>([]);
  const [pagingParams, setPagingParams] = useState({
    firstReservationIndex: 0,
    lastReservationIndex: reservatonsPerPage,
    maxReservationIndex: 0,
    toResetPaging: false,
  });
  const searchBox = React.useRef<HTMLInputElement>(null);
  const [searchWord, setSearchWord] = React.useState<string>("");
  const [groupAccess, setGroupAccess] = React.useState<boolean>(false);
  const [company, setCompany] = React.useState<string>("");
  const [arrivalChecked, setArrivalChecked] = React.useState<boolean>(false);
  const [departureChecked, setDepartureChecked] = React.useState<boolean>(false);
  const [showAirportFilter, setShowAirportFilter] = React.useState<boolean>(false);
  const [selectedAirports, setSelectedAirports] = React.useState<Set<string>>(new Set());

  const startSearch = (keyEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyEvent.key === "Enter") {
      keyEvent.preventDefault();
      const searchStr = searchBox.current?.value;
      setSearchWord(searchStr ? searchStr.toLowerCase().trim() : "");
    }
  };

  const applyFilter = (reservation: ReservationDTO): boolean => {
    const matchesSearch =
      searchWord === "" ||
      reservation.reservNumb.toLowerCase().indexOf(searchWord) >= 0 ||
      reservation.callSign.toLowerCase().indexOf(searchWord) >= 0 ||
      reservation.requestType.toLowerCase().indexOf(searchWord) >= 0 ||
      reservation.airportId.toLowerCase().indexOf(searchWord) >= 0 ||
      reservation.eventName!.toLowerCase().indexOf(searchWord) >= 0 ||
      reservation.aircraftType.toLowerCase().indexOf(searchWord) >= 0;

    const matchesFilterType =
      (!arrivalChecked && !departureChecked) ||
      (arrivalChecked && reservation.requestType.toLowerCase() === "arrival") ||
      (departureChecked && reservation.requestType.toLowerCase() === "departure");

    const matchesAirport =
      selectedAirports.size === 0 || selectedAirports.has(reservation.airportId);

    return matchesSearch && matchesFilterType && matchesAirport;
  };

  const resetPaging = () => {
    const displayedRsv = userReservations.filter((reserv) => applyFilter(reserv));
    setPagingParams({
      firstReservationIndex: 0,
      lastReservationIndex: reservatonsPerPage,
      maxReservationIndex: displayedRsv.length,
      toResetPaging: !pagingParams.toResetPaging,
    });
  };

  const getCsvData = () => {
    return [
      [
        "Airport",
        "Callsign",
        "Request",
        "Date",
        "Time",
        "Aircraft Type",
        "Reservation Number",
        "Requestor Name",
        "Users Email",
      ],
      ...userReservations.map(
        ({
          airportId,
          callSign,
          requestType,
          reservDate,
          reservTime,
          aircraftType,
          reservNumb,
          requestorName,
          requestorEmail,
        }) => [
            airportId,
            callSign,
            requestType,
            reservDate,
            reservTime,
            aircraftType,
            reservNumb,
            requestorName,
            requestorEmail,
          ]
      ),
    ];
  };

  const getSubsequentEventInfo = () => {
    getAndApplyLogic(`${API.UserReservations}`, (rEvents: ReservationDTO[]) => {
      setUserReservations(() => {
        return rEvents.map((myEvent) => {
          const event = myEvent as ReservationDTO;
          const rule = rules?.find((x) => x.airportId === myEvent.airportId);
          event.eventName = rule ? rule.eventName : "-";
          return event;
        });
      });
    });
  };

  const fetchUserGroupAccess = async () => {
    const response = await restHelpers.getResult(`${API.GetUserGroupAccess}`);
    response.json().then((userInfo) => {
      console.log(userInfo);
      setGroupAccess(userInfo.group);
      setCompany(userInfo.company);
    });
  };

  useEffect(() => {
    getSubsequentEventInfo();
    // eslint-disable-next-line
  }, [events, rules]);

  useEffect(() => {
    resetPaging();
    // eslint-disable-next-line
  }, [userReservations, searchWord, arrivalChecked, departureChecked, selectedAirports]);

  useEffect(() => {
    fetchUserGroupAccess();
    // eslint-disable-next-line
  }, [groupAccess]);

  function toggle(value: any) {
    return !value;
  }

  function onPagingChange(firstIndex: number, lastIndex: number) {
    setPagingParams({
      firstReservationIndex: firstIndex,
      lastReservationIndex: lastIndex,
      maxReservationIndex: pagingParams.maxReservationIndex,
      toResetPaging: pagingParams.toResetPaging,
    });
  }

  const capitalizeFirstLetter = (sentence: any) => {
    let words = sentence.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
  };

  const reservations = userReservations.filter((reserv) => applyFilter(reserv));

  const uniqueAirports = Array.from(new Set(userReservations.map(r => r.airportId)));

  const handleAirportCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const airportId = event.target.value;
    setSelectedAirports(prev => {
      const newSet = new Set(prev);
      if (newSet.has(airportId)) {
        newSet.delete(airportId);
      } else {
        newSet.add(airportId);
      }
      return newSet;
    });
  };

  return (
    <>
      <section className="event-blocks" data-testid="public-view">
        <SectionHeader rules={rules} events={events}>
          {groupAccess === true ? (
            <div>{capitalizeFirstLetter(company)} Reservations</div>
          ) : (
            <div>My Reservations</div>
          )}
          {company !== "" && (
            <GroupAccessComponent setGroupAccess={setGroupAccess} groupAccess={groupAccess} />
          )}
          {userReservations.length > 0 && (
            <p className="search-container">
              <input
                ref={searchBox}
                className="input-search-field"
                type="text"
                placeholder="Keyword Search"
                onKeyDown={startSearch}
              />
              <FontAwesomeIcon className="public-view-search-icon" icon={faSearch} title="Keyword Search" />
            </p>
          )}
        </SectionHeader>
        {/* {userReservations.length > 0 && ( */}
        <div className="public-button-div">
          <CSVLink
            className="public-export-button"
            data-testid="public-export-button"
            filename={"FLYR_reservations_" + dayjs.utc().format() + ".csv"}
            data={getCsvData()}
          >
            <FontAwesomeIcon className="export-icon" icon={faFileArrowDown} />
            Export
          </CSVLink>
        </div>
        {/* )} */}
        <div className="public-filter-and-table">
          {userReservations.length > 0 && (
            <>
              <div className="filter-icon-label" onClick={() => setShowAirportFilter(toggle)}>
                <FontAwesomeIcon icon={faFilter} className="filter-icon"></FontAwesomeIcon>
                <h2 className="filter-icon-label">Filter</h2>
              </div>
              <div className={showAirportFilter === true ? "filter-container filter-icon-clicked" : "filter-container"}>
                <h3 className="filter-header">Filter By:</h3>
                {uniqueAirports.length > 1 && (
                  <>
                    <strong className="filter-subheader">Airports:</strong>
                    <div className="airport-filter-container">
                      {uniqueAirports.map((airport) => (
                        <label key={airport} className="airport-filter">
                          <input
                            type="checkbox"
                            value={airport}
                            checked={selectedAirports.has(airport)}
                            onChange={handleAirportCheckboxChange}
                          />
                          &nbsp;{airport}
                        </label>
                      ))}
                    </div>
                  </>
                )}
                <strong className="filter-subheader">Request:</strong>
                <label className="filter-label">
                  <input
                    type="checkbox"
                    onChange={(e) => setArrivalChecked(e.target.checked)}
                    value="ARRIVAL"
                    aria-label="ARRIVAL"
                    checked={arrivalChecked}
                  />
                  &nbsp; Arrival
                </label>
                <label className="filter-label">
                  <input
                    type="checkbox"
                    onChange={(e) => setDepartureChecked(e.target.checked)}
                    value="DEPARTURE"
                    aria-label="DEPARTURE"
                    checked={departureChecked}
                  />
                  &nbsp; Departure
                </label>
                <button
                  className="filter-close"
                  data-testid={`reservation-modal-button-close`}
                  aria-label={"Close Filter"}
                  onClick={() => setShowAirportFilter(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </>
          )}
          <div className="card-list airport-events">
            {reservations.length > 0 ? (
              reservations
                .sort((a, b) => a.reservDate.localeCompare(b.reservDate) || a.reservTime.localeCompare(b.reservTime))
                .map((event, index) => {
                  return (
                    pagingParams.firstReservationIndex <= index &&
                    index < pagingParams.lastReservationIndex && (
                      <ReservationCard
                        key={event.reservNumb}
                        reservationEvent={event}
                        events={events}
                        rules={rules}
                      />
                    )
                  );
                })
            ) : (
              <p className="empty-state public-empty-state" data-testid="empty-state">
                No Active Reservations.
              </p>
            )}
          </div>
        </div>
        <Paginator
          pageChangeHandler={onPagingChange}
          maxReservationIndex={pagingParams.maxReservationIndex}
          reservatonsPerPage={reservatonsPerPage}
          toResetPaging={pagingParams.toResetPaging}
        />
      </section>
    </>
  );
};

export default PublicView;
