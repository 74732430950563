import React, { useContext } from "react";
import "./confirmationModal.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ActionType, ReservationDTO } from "../../../types";
import { UserInfoContext } from "../../../config/AppContext";

export interface ReservationProps {
  actionType?: string;
  reservationEvent?: ReservationDTO;
  isAdmin?: Boolean;
}

const ConfirmationModal: React.FC<ReservationProps> = ({ actionType, reservationEvent, isAdmin }) => {
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [successEmail] = React.useState(true);

  const { loggedUser: user } = useContext(UserInfoContext);

  function closeModal() {
    setIsOpen(false);
    window.location.reload();
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
        contentLabel="Confirmation Modal"
        ariaHideApp={false}
      >
        <button
          className="confirmation-modal-close"
          data-testid={`confirmation-modal-button-close`}
          aria-label={"Close Confirmation Reservation Modal"}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {actionType === ActionType.NEW ? (
          <div className="confirmation-header">
            <FontAwesomeIcon icon={faCircleCheck} className="green-check" data-testid="new-confirmation-modal-icon" />
            <h3 className="confirmation-header-text">Reservation Created!</h3>
          </div>
        ) : actionType === ActionType.MODIFY ? (
          <div className="confirmation-header">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="modify-icon"
              data-testid="modify-confirmation-modal-icon"
            />
            <h3 className="confirmation-header-text">Reservation Modified!</h3>
          </div>
        ) : (
          <div className="confirmation-header">
            <FontAwesomeIcon icon={faTrashCan} className="trashcan-icon" data-testid="cancel-confirmation-modal-icon" />
            <h3 className="confirmation-header-text">Reservation Canceled!</h3>
          </div>
        )}

        <div className="confirmation-numb">
          <label className="confirmation-reserve-num"> Reservation Number: </label>
          <h6>{reservationEvent?.reservNumb}</h6>
        </div>
        <div className="modal-field">
          <h2 className="confirmation-details-header">Reservation Details</h2>
          <div className="confirmation-form-fields" data-testid="confirmation-airport-id">
            <label className="confirmation-form-label-text"> Airport: {reservationEvent?.airportId}</label>
          </div>
          <div className="confirmation-form-fields" data-testid="confirmation-callsign">
            <label className="confirmation-form-label-text"> Aircraft Call Sign: {reservationEvent?.callSign}</label>
          </div>
          <div className="confirmation-form-fields" data-testid="confirmation-request-type">
            <label className="confirmation-form-label-text">
              {" "}
              Reservation Type:{" "}
              {reservationEvent?.requestType.charAt(0) + reservationEvent?.requestType.substring(1).toLowerCase()!}
            </label>
          </div>
          <div className="confirmation-form-fields" data-testid="confirmation-date">
            <label className="confirmation-form-label-text"> Date: {reservationEvent?.reservDate}</label>
          </div>
          <div className="confirmation-form-fields" data-testid="confirmation-time">
            <label className="confirmation-form-label-text"> Time: {reservationEvent?.reservTime}Z</label>
          </div>
          <div className="confirmation-form-fields" data-testid="confirmation-aircraft">
            <label className="confirmation-form-label-text"> Aircraft Type: {reservationEvent?.aircraftType}</label>
          </div>
          {isAdmin && (
            <div className="confirmation-form-fields">
              <label className="confirmation-form-label-text">
                {" "}
                Name: {reservationEvent?.requestorName?.replace(/[,]+/g, " ")}
              </label>
            </div>
          )}
        </div>
        <div className="confirmation-ending">
          {successEmail === false ? (
            <div id="error" className="email-error-message">
              <p>Your reservation was created but we are unable to send a confirmation email at this time</p>
            </div>
          ) : (
            <div className="confirmation-email-check" data-testid="confirmation-email-check">
              <FontAwesomeIcon icon={faEnvelope} className="envelope-icon" data-testid="envelope-icon" />
              <div className="confirmation-email-text">
                <h5>Confirmation has been sent to</h5>
                {isAdmin === true ? <h5>{reservationEvent?.requestorEmail}</h5> : <h5>{user.email}</h5>}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
