import React, { ReactElement, useContext, useEffect } from "react";
import { ColumnDefinitionType, User } from "../../../types";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { UserInfoContext } from "../../../config/AppContext";
import helpers from "../../../helpers/helper";
import DetailsModal from "../detailsmodal/DetailsModal";
import ConfirmGroupModal from "../confirmGroupModal/ConfirmGroupModal";
import GroupModal from "../groupmodal/GroupModal";

export interface TableRowsProps {
  columns: ColumnDefinitionType[];
  data: any[];
  setData: any;
  firstRowIndex: number;
  lastRowIndex: number;
  context: string;
  search: string;
  setSearch: any;
}

const TableRows = ({ data, setData, columns, firstRowIndex, lastRowIndex, context, search, setSearch }: TableRowsProps): ReactElement => {
  const [inputData, setInputData] = React.useState<any[]>(data);
  const [nonFilteredInputData, setNonFilteredInputData] = React.useState<any[]>([]);

  const { loggedUser } = useContext(UserInfoContext);

  function getTableRowContent(column: ColumnDefinitionType, index2: number, row: any, index: number): JSX.Element {
    if (column.header === "") {
      return (
        <td className={column.style?.td} data-testid={`cell-${index2}-${index}`} key={`cell-${index2}`}>
          <DetailsModal userEvent={row} />
        </td>
      );
    }

    if (context === "Domains") {
      switch (column.field) {
        case 'New Code':
          return (
            <td className={column.style?.td} data-testid={`cell-${index2}-${index}`} key={`cell-${index2}`}>
                <ConfirmGroupModal context={"New Code"} rowData={row} inputData={inputData} setInputData={setInputData}/>
            </td>
          )
        case 'Modify':
          return (
            <td className={column.style?.td} data-testid={`cell-${index2}-${index}`} key={`cell-${index2}`}>
              <GroupModal context={"Modify"} existingGroup={row} inputData={inputData} setInputData={setInputData}/>
            </td>
          )
        case 'Remove':
          return (
            <td className={column.style?.td} data-testid={`cell-${index2}-${index}`} key={`cell-${index2}`}>
              <ConfirmGroupModal context={"Remove"} rowData={row} inputData={inputData} setInputData={setInputData}/>
            </td>
          )
      }
    }
    
    if (column.dropdown !== undefined) {
      return (
        <td className={column.style?.td} data-testid={`cell-${index2}-${index}`} key={`cell-${index2}`}>
          {rolesDropDownContent(row, column)}
        </td>
      );
    }
    return (
      <td className={column.style?.td} data-testid={`cell-${index2}-${index}`} key={`cell-${index2}`}>
        {row[column.field]}
      </td>
    );
  }

  const rolesDropDownContent = (row: any, col: ColumnDefinitionType) => {
    const isFaaUser = row["email"].split("@")[1].toLowerCase() === "faa.gov" ? true : false;

    if (loggedUser.email === row["email"]) {
      return (
        <select className="select-dropdown">
          <option>{helpers.toTitleCase(row[col.field])}</option>
        </select>
      );
    }

    if (isFaaUser) {
      return (
        <select
          className="select-dropdown"
          name={col.field}
          value={row[col.field]}
          onChange={changeSelectOptionHandler(row)}
        >
          <option value="SUPER ADMIN">Super Admin</option>
          <option value="PLANNER">Planner</option>
          <option value="ADMIN">Admin</option>
          <option value="FAA">FAA</option>
          <option value="PUBLIC">Public</option>
          <option value="INACTIVE">Inactive</option>
        </select>
      );
    }

    return (
      <select
        className="select-dropdown"
        name={col.field}
        value={row[col.field]}
        onChange={changeSelectOptionHandler(row)}
      >
        <option value="PUBLIC">Public</option>
        <option value="INACTIVE">Inactive</option>
      </select>
    );
  };
  useEffect(() => {
    if((context=== "Roles" || context === "Domains") && search === "false"){
      setData(inputData);
    }
  // eslint-disable-next-line
  }, [inputData]);
  
  const changeSelectOptionHandler =
    (updatedRow: User) => async (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let updatedUserRow = { ...updatedRow, [event.target.name]: event.target.value };
      let updatedDataRows = inputData.map((obj) => (obj.oktaId === updatedUserRow.oktaId ? updatedUserRow : obj));
      setInputData(updatedDataRows);
      if(search==="false"){
        setNonFilteredInputData(updatedDataRows);
      }

      await restHelpers
        .post(API.ModifyUser, updatedUserRow)
        .then((json: any) => {
          // console.log("updated userRole");
        })
        .catch(() => {
          console.log("Sorry, there was an error processing your request. Please try again.");
        });
    };

  const getRows = () =>
    inputData.map((row, index) => {
      return (
        firstRowIndex <= index &&
        index < lastRowIndex && (
          <tr className="list-view-table-row" data-testid={`row-${index}`} key={`row-${index}`}>
            {columns.map((column, index2) => {
              let tableRowContents = getTableRowContent(column, index2, row, index);
              return tableRowContents;
            })}
          </tr>
        )
      );
    });

  useEffect(() => {
    if (data.length !== inputData.length && context === "Roles") {
      setInputData(data);
    } else if (context === "Report Center") {
      setInputData(data);
    }
    else if (search !== "false" && data.length === inputData.length) {
      if(nonFilteredInputData.length === data.length){
        setSearch("false");
        setInputData(data);
      } else {
        const dataString = JSON.stringify(data);
        const inputDataString = JSON.stringify(inputData);
        if(dataString!==inputDataString){
          console.log("aya");
          setInputData(data);
        }
      }
    }
    getRows();
    // eslint-disable-next-line
  }, [inputData, data]);

  useEffect(() => {
    setNonFilteredInputData(data);
  // eslint-disable-next-line
  }, []);

  return <tbody>{getRows()}</tbody>;
};

export default TableRows;
