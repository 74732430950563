import { Dispatch, SetStateAction } from "react";
import restHelpers from "./restHelpers";

export const getAndUpdateState = (url: string, updateState: Dispatch<SetStateAction<any>>) => {
  restHelpers
    .get(url)
    .then((json: any) => {
      updateState(json);
    })
    .catch((json: any) => ({}));
};

export const getAndApplyLogic = (url: string, callback: (json: any) => void) => {
  restHelpers
    .get(url)
    .then((json: any) => {
      callback(json);
    })
    .catch((json: any) => ({}));
};

export const getResultsAndApplyLogic = (url: string, callback: (json: any) => void) => {
  restHelpers
    .getResult(url)
    .then((result: Response) => {
      callback(result);
    })
    .catch((json: any) => ({}));
};
