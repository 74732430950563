import React, { ReactElement } from "react";
import { ColumnDefinitionType } from "../../../types";

export interface TableHeaderProps {
  columns: ColumnDefinitionType[];
}

const TableHeader = ({ columns }: TableHeaderProps): ReactElement => {
  const headers = columns.map((column, index) => {
    return (
      <th className={column.style?.th} data-testid={`headCell-${index}`} key={`headCell-${index}`}>
        <p className={column.style?.p}>{column.header}</p>
      </th>
    );
  });

  return (
    <thead>
      <tr>{headers}</tr>
    </thead>
  );
};

export default TableHeader;
