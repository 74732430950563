import React from "react";
import { ReservApiResponse } from "../../../types";
import InfoTable, { InfoTableProps } from "../../../components/InfoTable";

export interface AcceptanceProps {
  apiIsUp: string;
  database: string;
  reservations: ReservApiResponse[];
}

const Acceptance: React.FC<AcceptanceProps> = ({ apiIsUp, database, reservations }: AcceptanceProps) => {
  const infoTableProps: InfoTableProps[] = [
    {
      className: "reservations-table",
      tableTitle: "Reservations Table",
      tableValues: reservations,
      displayXmlWithFormatting: true,
    },
  ];

  return (
    <div>
      <h2>App Status</h2>
      <div>Api Connection: {apiIsUp}</div>
      <div>Database: {database}</div>
      <ul className="available-tables" aria-label="Available Tables">
        <h2>Available Tables</h2>
        {infoTableProps.map((tableProps, index) => (
          <li key={index}>{tableProps.tableTitle}</li>
        ))}
      </ul>
      <div aria-label="Data Info">
        <h2>Data Info</h2>
        {infoTableProps.map((tableProps, index) => (
          <InfoTable key={index} {...tableProps} />
        ))}
      </div>
    </div>
  );
};

export default Acceptance;
