import React, { useEffect } from "react";
import Dropdown from "rsuite/Dropdown";
import { Card } from "../../cards/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faCalendarDays, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FormValues, Event as EVENT } from "../../../types";
import EventsAirportDropDown from "./EventsAirportDropDown";
import DatePicker from "../datepicker/DatePicker";
import "./eventsFormDropDownCard.scss";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getAndApplyLogic } from "../../../helpers/stateHelpers";
import { API } from "../../../helpers/restHelpers";
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export interface EventsAirportDropDownCardProps {
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  reportType: string;
  setReportType: React.Dispatch<React.SetStateAction<string>>;
}

const EventsFormDropDownCard: React.FC<EventsAirportDropDownCardProps> = ({
  formValues,
  setFormValues,
  setIsSubmit,
  reportType,
  setReportType,
}) => {
  const [allEvents, setAllEvents] = React.useState<EVENT[]>([]);
  const [calendarIsOpen, setCalendarIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [searchIsOpen, setSearchIsOpen] = React.useState(true);

  const startDateInput = React.useRef<HTMLInputElement>(null);
  const endDateInput = React.useRef<HTMLInputElement>(null);
  const setStartDate = (date: string) => setNativeInput(startDateInput.current!, dayjs(date).format("MMMM DD, YYYY"));
  const setEndDate = (date: string) => setNativeInput(endDateInput.current!, dayjs(date).format("MMMM DD, YYYY"));

  // const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsSubmit(true);
  //   const target = event.target.name;
  //   setFormValues({ ...formValues, [target]: event.target.value });
  //  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log("event: ", event);
    const target = event.target.name;
    setFormValues({ ...formValues, [target]: event.target.value });
    setIsSubmit(false);
  };

  const handleRequestTypeSelect = (reqType: any) => {
    console.log(reqType);
    setFormValues((prevValues) => ({ ...prevValues, requestType: reqType }));
    setIsSubmit(false);
  };

  const handleReportSelect = (reqType: any) => {
    setReportType(reqType);
    setIsSubmit(false);
  };

  const setNativeInput = (inputRef: any, value: string) => {
    const setter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set;
    setter!.call(inputRef, value);
    const inputEvent = new Event("input", { bubbles: true });
    inputRef.dispatchEvent(inputEvent);
  };

  const openDatePicker = () => {
    setCalendarIsOpen(true);
  };

  const getEventType = () => {
    if (formValues.airportId !== "" && formValues.airportId !== "Airports (All)| All Events") {
      let selectedEvent = allEvents?.filter((event) => event?.eventName === formValues?.airportId.split("|")[1].trim());
      let eventType = selectedEvent[0].eventType;
      return eventType;
    }
    return "";
  }

  const getEventStartTime = () => {
    if (formValues?.airportId.split("|")[1].trim() === "All Events") {
      return "";
    }
    let selectedEvent = allEvents?.filter((event) => event?.eventName === formValues?.airportId.split("|")[1].trim());
    let eventStartTime = dayjs.utc(selectedEvent[0]?.startTime).format("MM/DD/YYYY");
    return eventStartTime;
  };

  const getEventEndTime = () => {
    if (formValues?.airportId.split("|")[1].trim() === "All Events") {
      return "";
    }
    let selectedEvent = allEvents?.filter((event) => event?.eventName === formValues?.airportId.split("|")[1].trim());
    let eventEndTime = dayjs.utc(selectedEvent[0]?.endTime).format("MM/DD/YYYY");
    return eventEndTime;
  };

  const toggleExpandCollapse = () => {
    setSearchIsOpen(!searchIsOpen);
  };

  useEffect(() => {
    getAndApplyLogic(`${API.Events}`, (json: EVENT[]) => {
      setAllEvents(() => {
        return json.map((event) => {
          return event as EVENT;
        });
      });
    });
    // eslint-disable-next-line
  }, [formValues.requestType]);

  useEffect(() => {
    if (reportType === "Reservation Graph") {
      const updateFormValues = { ...formValues };
      updateFormValues.endDate = "";
      updateFormValues.requestType = "";
      updateFormValues.startDate = "";
      setFormValues(updateFormValues);
      if (getEventType() === "HDA") {
        setFormValues((prevValues) => ({ ...prevValues, requestType: "All" }));
      }
    }
    // eslint-disable-next-line
  }, [formValues.airportId]);

  useEffect(() => {
    if (reportType === "Reservation Graph") {
      const updateFormValues = { ...formValues };
      updateFormValues.airportId = "";
      updateFormValues.endDate = "";
      updateFormValues.requestType = "";
      updateFormValues.startDate = "";
      setFormValues(updateFormValues);
    }
    // eslint-disable-next-line
  }, [reportType]);
  //useEffect that resets in entries on report change, airport change?

  // useEffect(() => {
  //   if (formValues.endDate) {
  //     setFormValues((prevValues) => ({ ...prevValues, formValues }));
  //   }
  //   // eslint-disable-next-line
  // }, [formValues.endDate]);

  return (
    <Card additionalClassNames="drop-down-card" identifier={"accounts"}>
      <div className={searchIsOpen ? "collapse-caret" : "expand-caret"} onClick={toggleExpandCollapse}>
        <FontAwesomeIcon className={searchIsOpen ? "collapse-icon" : "expand-icon"} data-testid="" icon={faAngleUp} />
      </div>
      <div className="filter-search-contrainer">
        <FontAwesomeIcon icon={faFilter} className="filter-icon" onClick={toggleExpandCollapse}></FontAwesomeIcon>
        <h2 className="filter-icon-label" onClick={toggleExpandCollapse}>Filter</h2> 
      </div>
      {/* {searchIsOpen && */}
        <div className={searchIsOpen ? "drop-down-card-container" : "drop-down-card-container mobile-hidden-container"}>
          <div className="form-fields report-form-field">
            <label className="form-label-text"> Report:</label>
            <div className={"type-drop-down-button"}>
              <Dropdown
                title={reportType}
                placement="bottomStart"
                aria-label="report"
                activeKey={reportType}
              >
                <React.Fragment key={`fragment-${reportType}`}>
                  {["Reservation Table", "Reservation Graph"].map((type: string) => {
                    return (
                      <Dropdown.Item onSelect={handleReportSelect} eventKey={type} id={type} aria-label={type}>
                        {type}
                      </Dropdown.Item>
                    );
                  })}
                </React.Fragment>
              </Dropdown>
            </div>
          </div>
          <div className="form-fields airport-form-field">
            <label className="form-label-text"> Airport:</label>
            <div className={reportType === "" ? "disabled airport-drop-down-button" : "airport-drop-down-button"}>
              <EventsAirportDropDown
                formValues={formValues}
                setFormValues={setFormValues}
                setIsSubmit={setIsSubmit}
                events={allEvents}
                reportType={reportType}
              />
            </div>
          </div>
          <div className="form-fields type-form-field">
            <label className="form-label-text"> Type:</label>
            <div className={formValues.airportId === "" ? "disabled type-drop-down-button" : "type-drop-down-button"}>
              <Dropdown
                disabled={formValues.airportId === "" || (reportType === "Reservation Graph" && getEventType() === "HDA")}
                title={formValues.requestType}
                placement="bottomStart"
                aria-label="requestType"
                activeKey={reportType === "Reservation Graph" && getEventType!() === "HDA" ? "All" : formValues.requestType}

                value={"All"}
                defaultValue={"test"}
              >
                <React.Fragment key={`fragment-${formValues.requestType}`}>
                  {reportType === "Reservation Graph" && getEventType() === "HDA" ? (
                    // make it default to all and unchangeable 
                    <Dropdown.Item onSelect={handleRequestTypeSelect} eventKey={"All"} id={"All"} aria-label={"All"} defaultValue={"test"}>
                      All
                    </Dropdown.Item>
                  ) : reportType === "Reservation Graph" && getEventType() === "SE" ? (
                    <div>
                      {["Arrival", "Departure"].map((type: string) => {
                        return (
                          <Dropdown.Item onSelect={handleRequestTypeSelect} eventKey={type} id={type} aria-label={type}>
                            {type}
                          </Dropdown.Item>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      {["All", "Arrival", "Departure"].map((type: string) => {
                        return (
                          <Dropdown.Item onSelect={handleRequestTypeSelect} eventKey={type} id={type} aria-label={type}>
                            {type}
                          </Dropdown.Item>
                        );
                      })}
                    </div>
                  )}
                </React.Fragment>
              </Dropdown>
            </div>
          </div>
          <div className="form-fields date-form-field">
            <label className="form-label-text"> Start:</label>
            <div className=" datetime-input-button datetime-report-dropdown">
              <>
                <input
                  className={formValues.requestType === "" ? "disabled-date-pointer" : "date-pointer"}
                  disabled={formValues.requestType === ""}
                  ref={startDateInput}
                  type="text"
                  onClick={function () {
                    openDatePicker();
                    setSelected("start");
                  }}
                  name="startDate"
                  data-testid="startDate"
                  aria-label="startDate"
                  value={formValues.startDate}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </>
              <button
                className={formValues.requestType === "" ? "disabled-calendar" : "open-calendar-program-setup"}
                disabled={formValues.requestType === ""}
                aria-label={"Open Calendar"}
                data-testid="open-calendar"
                onClick={function () {
                  openDatePicker();
                  setSelected("start");
                }}
                type="button"
              >
                <FontAwesomeIcon icon={faCalendarDays} />
              </button>
            </div>
          </div>
          <div className="form-fields date-form-field">
            <label className="form-label-text"> End:</label>
            <div className="datetime-input-button datetime-report-dropdown">
              <>
                <input
                  className={formValues.startDate === "" ? "disabled-date-pointer" : "date-pointer"}
                  disabled={formValues.startDate === ""}
                  ref={endDateInput}
                  type="text"
                  onClick={function () {
                    openDatePicker();
                    setSelected("end");
                  }}
                  name="endDate"
                  data-testid="endDate"
                  aria-label="endDate"
                  value={formValues.endDate}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </>
              <button
                className={formValues.startDate === "" ? "disabled-calendar" : "open-calendar-program-setup"}
                disabled={formValues.startDate === ""}
                aria-label={"Open Calendar"}
                data-testid="open-calendar"
                onClick={function () {
                  openDatePicker();
                  setSelected("end");
                }}
                type="button"
              >
                <FontAwesomeIcon icon={faCalendarDays} />
              </button>
            </div>
          </div>
          {/* <div className="form-fields"> */}
          <div className="reports-search-button">
            <input
              className={
                reportType === "" ||
                  formValues.airportId === "" ||
                  formValues.requestType === "" ||
                  formValues.startDate === "" ||
                  formValues.endDate === ""
                  ? "disabled-submit-button events-dropdown-submit"
                  : "submit-button events-dropdown-submit"
              }
              type="submit"
              data-testid="submit-button"
              value="Search"
              disabled={
                formValues.airportId === "" ||
                  formValues.requestType === "" ||
                  formValues.startDate === "" ||
                  formValues.endDate === ""
                  ? true
                  : false
              }
              onClick={() => setIsSubmit(true)}
            />
          </div>
          {/* </div> */}
        </div>
      
      {calendarIsOpen && selected === "start" ? (
        <div className="calendar-container reports-calendar">
          <DatePicker
            setDate={setStartDate}
            setDatePickerIsOpen={setCalendarIsOpen}
            currentSelectedDate={getEventStartTime()}
            endDate={formValues.endDate}
            startDate={getEventStartTime()}
          />
        </div>
      ) : calendarIsOpen && selected === "end" ? (
        <div className="calendar-container reports-calendar">
          <DatePicker
            setDate={setEndDate}
            setDatePickerIsOpen={setCalendarIsOpen}
            currentSelectedDate={formValues.endDate}
            startDate={formValues.startDate}
            endDate={getEventEndTime()}
          />
        </div>
      ) : null}
    </Card>
  );
};

export default EventsFormDropDownCard;
