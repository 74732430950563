import "./paginator.scss";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export interface PaginatorProps {
  pageChangeHandler: any,
  reservatonsPerPage?: number,
  maxReservationIndex: number,
  toResetPaging?: boolean,
}

const Paginator: React.FC<PaginatorProps> = ({
  pageChangeHandler,
  reservatonsPerPage = 25,
  maxReservationIndex,
  toResetPaging = false,
}) => {

  const [pagingParams, setPagingParams] = useState({
    firstReservationIndex: 0,
    lastReservationIndex: reservatonsPerPage,
  })

  function prevPage() {
    const firstIndex = pagingParams.firstReservationIndex - reservatonsPerPage;
    const lastIndex = pagingParams.lastReservationIndex - reservatonsPerPage;
    pageChangeHandler(firstIndex, lastIndex);
    setPagingParams({
      firstReservationIndex: firstIndex,
      lastReservationIndex: lastIndex,
    });
  }
  function nextPage() {
    const firstIndex = pagingParams.firstReservationIndex + reservatonsPerPage;
    const lastIndex = pagingParams.lastReservationIndex + reservatonsPerPage;
    pageChangeHandler(firstIndex, lastIndex);
    setPagingParams({
      firstReservationIndex: firstIndex,
      lastReservationIndex: lastIndex,
    });
  }

  useEffect(() => {
    setPagingParams({
      firstReservationIndex: 0,
      lastReservationIndex: reservatonsPerPage,  
    });
    // eslint-disable-next-line
  }, [toResetPaging]);

  const totalPages = Math.floor(maxReservationIndex / reservatonsPerPage) + 1;
  const currentPage = Math.floor(pagingParams.firstReservationIndex / reservatonsPerPage) + 1;

  return <>
    {maxReservationIndex > reservatonsPerPage && (
      <div className="table-button-container">
        <button
          className={pagingParams.firstReservationIndex === 0 ? "change-page-button-disabled" : "change-page-button"}
          disabled={pagingParams.firstReservationIndex === 0 ? true : false}
          onClick={prevPage}
          data-testid="change-page-button-left"
          aria-label="change-page-button-left"
        >
          <FontAwesomeIcon className="change-page-icon" icon={faChevronCircleLeft} />
        </button>
        <div className="page-label">Page {currentPage} of {totalPages}</div>
        <button
          className={
            pagingParams.lastReservationIndex > maxReservationIndex ? "change-page-button-disabled" : "change-page-button"
          }
          disabled={pagingParams.lastReservationIndex > maxReservationIndex ? true : false}
          onClick={nextPage}
          data-testid="change-page-button-right"
          aria-label="change-page-button-right"
        >
          <FontAwesomeIcon className="change-page-icon" icon={faChevronCircleRight} />
        </button>
      </div>
    )}
  </>

}

export default Paginator;