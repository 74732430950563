import React, { useContext, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import PublicView from "../publicview/PublicView";
import AdminView from "../adminview/AdminView";
import "./home.scss";
import { API } from "../../helpers/restHelpers";
import { Airport, Event, Rule } from "../../types";
import { getAndApplyLogic } from "../../helpers/stateHelpers";
import { UserInfoContext } from "../../config/AppContext";
import ControlCenter from "../controlcenter/ControlCenter";
import ProgramSetup from "../programsetup/ProgramSetup";
import Accounts from "../accounts/Accounts";
import Reports from "../reports/Reports";
import { AirportSelectOption } from "../../components/shared/airportrules/AirportRules";

const Home: React.FC = () => {
  const { loggedUser: user } = useContext(UserInfoContext);
  const [events, setEvents] = React.useState<Event[]>([]);
  const [rules, setRules] = React.useState<Rule[]>([]);
  const [currentView, setCurrentView] = React.useState<string>("reservations");
  const [eventToSetup, setEventToSetup] = React.useState<Event | undefined>();
  const [eventTypeToSetup, setEventTypeToSetup] = React.useState("SE");
  const [airportOptions, setAirportOptions] = React.useState<AirportSelectOption[]>([]);

  const getSubsequentEventInfo = () => {
    let getEventsUrl = `${API.Events}`;

    let today = new Date();
    let threeDaysAway = 3 * 86400000;
    let threeDaysAfter = new Date(new Date().setTime(today.getTime() + threeDaysAway));

    getAndApplyLogic(getEventsUrl, (events: Event[]) => {
      const filterEventsAfterLiveTime = events.filter((event) => new Date(event.liveDate!).getTime() < today.getTime());

      const filterEventsAfterStartTime = filterEventsAfterLiveTime.filter(
        (event) => new Date(event.startTime).getTime() < threeDaysAfter.getTime()
      );
      const filteredEventsBeforeEndTime = filterEventsAfterStartTime.filter(
        (event) =>
          event.eventType === "HDA" ||
          (event.eventType === "SE" && new Date(event.endTime!).getTime() > new Date(Date.now() - 60000).getTime())
      );
      const activeEvents = filteredEventsBeforeEndTime.filter((event) => event.eventStatus === "Active");

      setEvents(activeEvents);
    });
    getAndApplyLogic(`${API.ActiveRules}`, (json: Rule[]) => {
      setRules(() => {
        return json.map((rule) => {
          return rule as Rule;
        });
      });
    });
  };

  const setViewToProgramSetup = (eventToModify?: Event, programType?: string) => {
    setCurrentView("programSetup");
    setEventToSetup(eventToModify);
    setEventTypeToSetup(programType || "SE");
  };

  useEffect(() => {
    const airportUrl = encodeURI(`${API.AllAirports}`);
    getAndApplyLogic(airportUrl, (jsonObj: Airport[]) => {
      const options = jsonObj.map((ap: Airport) => {
        return {
          label: ap.airportName + " (" + ap.airportId + ")",
          value: ap.airportId,
        };
      });
      options.unshift({ label: "", value: "" });
      setAirportOptions(options);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentView === "reservations") {
      getSubsequentEventInfo();
    }
    // eslint-disable-next-line
  }, [currentView]);

  return (
    <>
      <div className="flex-wrapper" data-testid="home-container">
        <Header setView={setCurrentView} currentView={currentView} />
        <main className="home">
          {(function () {
            switch (user.role) {
              case "PUBLIC":
                return <PublicView events={events} rules={rules} />;
              case "FAA":
                if (currentView === "reports" && user.inFaaNetwork === true) {
                  return <Reports />;
                }
                return <PublicView events={events} rules={rules} />;
              case "ADMIN":
                if (user.inFaaNetwork === true) {
                  if (currentView === "reports") {
                    return <Reports />;
                  }
                  if (events.length >= 1 && rules.length >= 1) {
                    return <AdminView events={events} rules={rules} />;
                  }
                  return <AdminView />;
                }
                return <PublicView events={events} rules={rules} />;
              case "SUPER ADMIN":
                if (user.inFaaNetwork === true) {
                  if (currentView === "reservations" && events.length >= 1 && rules.length >= 1) {
                    return <AdminView events={events} rules={rules} />;
                  }
                  if (currentView === "controlCenter") {
                    return <ControlCenter setPlannerView={setViewToProgramSetup} airportOptions={airportOptions} />;
                  }
                  if (currentView === "programSetup") {
                    return (
                      <ProgramSetup
                        eventToModify={eventToSetup}
                        programType={eventTypeToSetup}
                        airportOptions={airportOptions}
                      />
                    );
                  }
                  if (currentView === "accounts") {
                    return <Accounts />;
                  }
                  if (currentView === "reports") {
                    return <Reports />;
                  }
                  return <AdminView />;
                }
                return <PublicView events={events} rules={rules} />;
              case "PLANNER":
                if (user.inFaaNetwork === true) {
                  if (currentView === "reservations" && events.length >= 1 && rules.length >= 1) {
                    return <AdminView events={events} rules={rules} />;
                  }
                  if (currentView === "controlCenter") {
                    return <ControlCenter setPlannerView={setViewToProgramSetup} airportOptions={airportOptions} />;
                  }
                  if (currentView === "programSetup") {
                    return (
                      <ProgramSetup
                        eventToModify={eventToSetup}
                        programType={eventTypeToSetup}
                        airportOptions={airportOptions}
                      />
                    );
                  }
                  if (currentView === "reports") {
                    return <Reports />;
                  }
                  return <AdminView />;
                }
                return <PublicView events={events} rules={rules} />;
              case "INACTIVE":
                return (
                  <p className="empty-state" data-testid="empty-state">
                    Account has been disabled. Contact System Administrator for support.
                  </p>
                );
              default:
                return <></>;
            }
          })()}
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Home;
