import { ReactElement, ReactNode } from "react";
import { ActionType, Rule, Event } from "../types";
import "./sectionHeader.scss";
import ReservationModal from "./shared/newreservationmodal/ReservationModal";
import SegmentHeader from "./shared/segmentHeader/SegmentHeader";

interface SectionHeaderProps {
  children: ReactNode;
  isAdmin?: boolean;
  events?: Event[],
  rules?: Rule[];
}

const SectionHeader = ({ children, isAdmin, events, rules }: SectionHeaderProps): ReactElement => {
  return (
    <SegmentHeader>
      {children}
      {rules && rules.length>0 &&
        <ReservationModal actionType={ActionType.NEW} isAdmin={isAdmin} rules={rules} events={events} />
      }
    </SegmentHeader>
  );
};

export default SectionHeader;
