import React, { useContext, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import "./landing.scss";
import { API } from "../../helpers/restHelpers";
import { Event, Rule } from "../../types";
import { getAndApplyLogic } from "../../helpers/stateHelpers";
import { UserInfoContext } from "../../config/AppContext";
import SegmentHeader from "../../components/shared/segmentHeader/SegmentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  formatDateString,
  roundEndDatePlusThreeDays,
  formatReservationOpenDateString,
  roundDate,
} from "../../helpers/dateHelper";
import AvailabilityModal from "../../components/shared/availabilitycomponent/AvailabilityModal";
import ExternalLink from "../../components/ExternalLink";
import { ReactComponent as DomesticNoticeLink } from "../../static/Domestic-Notice-Icon.svg";

const Landing: React.FC = () => {
  const { loggedUser: user } = useContext(UserInfoContext);
  const [events, setEvents] = React.useState<Event[]>([]);
  const [rules, setRules] = React.useState<Rule[]>([]);
  const [view, setView] = React.useState<string>("landing");
  const navigate = useNavigate();
  const domesticNoticeUrl = "https://www.faa.gov/air_traffic/publications/domesticnotices/";

  const getSubsequentEventInfo = () => {
    let today = new Date();

    getAndApplyLogic(`${API.Events}`, (events: Event[]) => {
      const activeEvents = events.filter((event) => new Date(event.liveDate!).getTime() <= today.getTime());

      getAndApplyLogic(`${API.ActiveRules}`, (json: Rule[]) => {
        setRules(() => {
          return json.map((rule) => {
            return rule as Rule;
          });
        });
      });

      setEvents(activeEvents);
    });
  };

  const checkWithinSeventyTwoHours = (eventStartTime: Date) => {
    let today = new Date();
    let threeDays = 3 * 86400000;
    let startTime = new Date(eventStartTime);
    let timeDifference = startTime.getTime() - today.getTime();

    if (timeDifference <= threeDays) {
      return true;
    } else {
      return false;
    }
  };

  const getReservationTimeDisplayText = (eventStartTime: Date, eventEndTime: Date, eventType: string): string => {
    let currentTime = new Date();
    let threeDays = 3 * 86400000;
    let startTime = new Date(eventStartTime);
    let endTime = new Date(eventEndTime);
    let endTimeDifference = endTime.getTime() - currentTime.getTime();
    let minutesToRound = 15;

    if (eventType === "HDA") {
      minutesToRound = 60;
    }

    //startTime reached and eventEndTime is more than 72 hours from current time, should display currentime to 72 hours after current time. 
    if (startTime < currentTime && endTimeDifference > threeDays) {
      return ` ${roundDate(minutesToRound, currentTime)}Z to ${roundEndDatePlusThreeDays(
        minutesToRound,
        currentTime,
        eventType
      )}Z`;
    }
    //startTime reached and eventEndTime is less than or equal to 72 hours from current time, should display current time to eventEndtime.
    else if (startTime < currentTime && endTimeDifference <= threeDays) {
      return ` ${roundDate(minutesToRound, currentTime)}Z to ${formatDateString(endTime)}Z`;
    }
    //startTime not reached and eventEndTime more than 72 hours from current time, should display startTime to 72 hours from the current time.
    else if (startTime > currentTime && endTimeDifference > threeDays) {
      return ` ${formatDateString(startTime)}Z to ${roundEndDatePlusThreeDays(
        minutesToRound,
        currentTime,
        eventType
      )}Z`;
    }
    //startTime not reached and eventEndTime is less than or equal to 72 hours from current time, should display startTime to eventEndTime
    else if (startTime > currentTime && endTimeDifference <= threeDays) {
      return ` ${formatDateString(startTime)}Z to ${formatDateString(endTime)}Z`;
    }

    return "";
  };

  const getReservationTimeDisplayTextForHDAWithNoEndTime = (eventStartTime: Date, eventType: string): string => {
    let currentTime = new Date();
    let startTime = new Date(eventStartTime);

    //startTime reached and there is no eventEndTime, should display current time to 72 hours from the current time.
    if (startTime < currentTime) {
      return ` ${roundDate(60, currentTime)}Z to ${roundEndDatePlusThreeDays(60, currentTime, eventType)}Z`;
    }
    //startTime not reached and there is no eventEndTime, should display startTime to 72 hours from the current time.
    else if (startTime > currentTime) {
      return ` ${formatDateString(startTime)}Z to ${roundEndDatePlusThreeDays(60, currentTime, eventType)}Z`;
    }

    return "";
  };

  useEffect(() => {
    if (events.length === 0) {
      getSubsequentEventInfo();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex-wrapper" data-testid="landing-container">
        <Header setView={setView} currentView={view} />
        {user.role === "INACTIVE" ? (
          <p className="empty-state" data-testid="empty-state">
            Account has been disabled. Contact System Administrator for support.
          </p>
        ) : (
          <main className="home">
            <SegmentHeader>
              Planning Center
              {user.firstName && (
                <div className="link-button">
                  <FontAwesomeIcon className="plane-icon" icon={faPlane} />
                  <button
                    type="button"
                    className="reservations-button"
                    title="Reservations"
                    onClick={() => navigate("/reservations")}
                  >
                    Reservations
                  </button>
                </div>
              )}
            </SegmentHeader>
            <div className="event-container">
              {events.findIndex((event) => event.eventStatus === "Active") >= 0 ? (
                events
                  .sort((a, b) => a.eventName.localeCompare(b.eventName))
                  .map((theEvent: Event, index: number) => {
                    if (theEvent.eventStatus === "Active") {
                      const withinSeventyTwoHours = checkWithinSeventyTwoHours(theEvent.startTime);
                      return (
                        <div
                          data-testid={`${theEvent.eventName}_event_card`}
                          key={`event_card_${index}`}
                          className="event-card"
                        >
                          <div className="event-card-header">
                            <p className="card-title" aria-label="card-title">
                              {theEvent.eventName}
                            </p>
                            <p className="event-card-time">
                              <span>Start: {formatDateString(theEvent.startTime)}Z </span>
                              {theEvent.endTime && <span>End: {formatDateString(theEvent.endTime)}Z </span>}
                            </p>
                          </div>
                          <div className="card-content-block">
                            <pre className="card-description">{theEvent.eventDesc}</pre>
                            <div className="reservation-time">
                              {withinSeventyTwoHours ? (
                                <p>
                                  Now accepting reservations for
                                  {theEvent.endTime
                                    ? `${getReservationTimeDisplayText(
                                        theEvent.startTime,
                                        theEvent.endTime,
                                        theEvent.eventType
                                      )}`
                                    : `${getReservationTimeDisplayTextForHDAWithNoEndTime(
                                        theEvent.startTime,
                                        theEvent.eventType
                                      )}`}
                                  .
                                </p>
                              ) : (
                                <p>Reservations open {formatReservationOpenDateString(theEvent.startTime)} </p>
                              )}
                            </div>
                          </div>
                          <div className="card-links-container">
                            {theEvent.eventType === "SE" && (
                              <div>
                                <ExternalLink
                                  href={domesticNoticeUrl}
                                  className="domestic-notice-link"
                                  ariaLabel="domestic notice link"
                                >
                                  <DomesticNoticeLink className="domestic-notice-icon" />
                                  Domestic Notice
                                </ExternalLink>
                              </div>
                            )}
                            {withinSeventyTwoHours && (
                              <AvailabilityModal
                                eventName={theEvent.eventName}
                                rules={rules}
                                eventType={theEvent.eventType}
                                eventStartTime={theEvent.startTime}
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
              ) : (
                <p className="empty-state" data-testid="empty-state">
                  No reservations required at this time.
                </p>
              )}
            </div>
          </main>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Landing;
