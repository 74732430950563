import React from "react";
import "./forceReservationModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export interface ReservationProps {
  setForceReservationOpen: any;
  time: any;
  setForceReservation: any;
  overLimit: any;
}

const ForceReservationModal: React.FC<ReservationProps> = ({
  setForceReservationOpen,
  setForceReservation,
  time,
  overLimit,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function closeModal() {
    setForceReservationOpen(false);
    setIsOpen(false);
  }

  const handleForceReservation = async () => {
    setForceReservation(true);
    closeModal();
  };

  return (
    <div>
      {modalIsOpen && (
        <div className="force-reservation-container">
          <button
            className="confirmation-modal-close"
            data-testid={`confirmation-modal-button-close`}
            aria-label={"Close Force Reservation Modal"}
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="force-reservation-div">
            <FontAwesomeIcon icon={faTriangleExclamation} className="force-reservation-warning-icon" />
            <h5 className="force-reservation-warning">
              No available reservations for {time}Z! Ensure appropriate coordination before proceeding.
            </h5>
          </div>
          <div className="force-reservation-question">Would you still like to add a reservation?</div>
          <div className="force-reservation-modal-buttons">
            <button
              className="force-reservation-modal-close"
              data-testid={`force-reservation-modal-button-close`}
              aria-label={"No Force Reservation"}
              onClick={closeModal}
            >
              No
            </button>
            <button
              className="force-reservation-modal-confirm"
              data-testid={`force-reservation-modal-button-yes`}
              aria-label={"Yes Force Reservation"}
              onClick={handleForceReservation}
            >
              Yes, Add Reservation
            </button>
          </div>
          <div className="force-reservation-number-over">
            {overLimit > 1 ? (
              <p>There are currently {overLimit} reservations above the maximum limit.</p>
            ) : overLimit === 1 ? (
              <p>There is currently {overLimit} reservation above the maximum limit.</p>
            ) : (
              <p>Reservations are currently at the maximum limit.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForceReservationModal;
