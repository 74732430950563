import React, { useEffect, useState, useRef } from "react";
import "./reports.scss";
import restHelpers, { API } from "../../helpers/restHelpers";
import { ReservationEvent, Rule } from "../../types";
import NewDataTable from "../../components/shared/newtablecomponent/NewDataTable";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import EventsFormDropDownCard from "../../components/shared/dropdown/EventsFormDropDownCard";
import AvailabilityGraph from "../../components/shared/availabilitycomponent/AvailabilityGraph";
import { getAndApplyLogic } from "../../helpers/stateHelpers";
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const reportsDataTableSettings: any = {
  segmentHeader: "Report Center",
  sortColumn: "",
  sortOrder: "",
  columns: [
    {
      field: "airportId",
      header: "Airport",
      style: {
        th: "list-view-table-header  list-view-table-padding-left list-view-table-fixed",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "callSign",
      header: "Call Sign",
      style: {
        th: "list-view-table-header list-view-table-padding-right  list-view-table-padding-left list-view-table-fixed-second",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "requestType",
      header: "Request",
      style: {
        th: "list-view-table-header list-view-table-padding-right  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "reservDate",
      header: "Date",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "reservTime",
      header: "Time",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "aircraftType",
      header: "Aircraft Type",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "reservNumb",
      header: "Reservation Number",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "requestorName",
      header: "Name",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "details",
      header: "",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
  ],
};

const Reports: React.FC = () => {
  const eventName = useRef<string>("");
  const [reports, setReports] = useState<ReservationEvent[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [reportType, setReportType] = React.useState<string>("");
  const [formValues, setFormValues] = React.useState({
    airportId: "",
    requestType: "",
    startDate: "",
    endDate: "",
  });
  const [rules, setRules] = React.useState<Rule[]>([]);
  // const [uniqueEventAirport, setUniqueEventAirport] = React.useState<string[]>([]);


  const getReportsData = () => {
    let getByJson = {
      airportId: formValues.airportId,
      requestType: formValues.requestType,
      startDate: dayjs(formValues.startDate, "MMMM DD, YYYY").format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: dayjs(formValues.endDate, "MMMM DD, YYYY").format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };

    restHelpers
      .get(API.GetReports + "?" + new URLSearchParams(getByJson))
      .then((reportInfo: ReservationEvent[]) => {
        setReports(reportInfo);
      })
      .catch((error: any) => ({}));
  };

  useEffect(() => {
    getAndApplyLogic(`${API.ActiveRules}`, (json: Rule[]) => {
      setRules(() => {
        return json.map((rule) => {
          return rule as Rule;
        });
      });
    });
    if (isSubmit) {
      if (reportType === "Reservation Table") {
        getReportsData();
        eventName.current = formValues.airportId.split("|")[1]?.trim();
      }
    }
    // eslint-disable-next-line
  }, [isSubmit]);

  const mergeUsersAndReservations = () => {
    let arr: any[] = [];
    reports.forEach((item, index) => {
      let merged = { ...item.user, ...item.reservationDTO };
      arr.push(merged);
    });
    return arr;
  };

  return (
    <>
      <section className="event-blocks" data-testid="reports-page">
        <EventsFormDropDownCard formValues={formValues} setFormValues={setFormValues} setIsSubmit={setIsSubmit} reportType={reportType} setReportType={setReportType}/>
        {reportType !== "Reservation Graph" ? (
          // {reportType !== "Availabilty Graph" &&
          <div className="accounts-table">
              <NewDataTable settings={reportsDataTableSettings} data={mergeUsersAndReservations()} eventName={eventName} />
            </div>
        ) : reportType=== "Reservation Graph" && isSubmit ? (
          <div className="reservation-graph-container">
            <h1>
              {formValues?.airportId.split("|")[0].trim()} {formValues.requestType !== "All" ? formValues.requestType : ''} Reservations
            </h1>
            <AvailabilityGraph selectedAirport={formValues?.airportId.split("|")[0].trim().slice(-4, -1)} requestType={formValues.requestType === "All" ? "Arrival-Departure" : formValues.requestType} eventName={formValues?.airportId.split("|")[1].trim()} rules={rules} isSubmitted={isSubmit} context="reports"
             startDate={formValues.startDate} endDate={formValues.endDate}/>
          </div>
        ) : (
          null
        )}
      </section>
    </>
  );
};
export default Reports;
