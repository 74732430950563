import React, { ReactElement, useEffect } from "react";
import { DataTableSettings } from "../../../types";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";
import { Card } from "../../cards/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faInfoCircle, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import SegmentHeader from "../segmentHeader/SegmentHeader";
import Paginator from "./Paginator";
import "./newDataTable.scss";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import GroupModal from "../groupmodal/GroupModal";
dayjs.extend(utc);


export interface NewDataTableProps {
  settings: DataTableSettings;
  data: any[];
  setData?: any;
  eventName?: React.MutableRefObject<string>;
  rerenderTable?: any;
  setRerenderTable?: any;
}

const NewDataTable = ({ settings, data, setData, eventName, rerenderTable, setRerenderTable }: NewDataTableProps): ReactElement => {
  const rowsPerPage = 25;
  const [firstRowIndex, setFirstRowIndex] = React.useState(0);
  const [lastRowIndex, setLastRowIndex] = React.useState(rowsPerPage);
  const searchBox = React.useRef<HTMLInputElement>(null);
  const [searchWord, setSearchWord] = React.useState<string>("");
  const [search, setSearch] = React.useState<string>("false");

  const startSearch = (keyEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyEvent.key === "Enter") {
      keyEvent.preventDefault();
      const searchStr = searchBox.current?.value;
      setSearchWord(searchStr ? searchStr.toLowerCase().trim() : "");
      resetPaging();
      setSearch(searchBox.current?.value || "false");
    }
  };

  const applyFilter = (input: any): boolean => {
    let toKeep = false;
    if (settings.segmentHeader === "Roles") {
      toKeep =
        searchWord === "" ||
        input.userRole.toLowerCase().indexOf(searchWord) >= 0 ||
        input.firstName.toLowerCase().indexOf(searchWord) >= 0 ||
        input.lastName.toLowerCase().indexOf(searchWord) >= 0 ||
        input.email.toLowerCase().indexOf(searchWord) >= 0;
      // setSearch(true);
    }

    if (settings.segmentHeader === "Report Center") {
      toKeep =
        searchWord === "" ||
        input.airportId.toLowerCase().indexOf(searchWord) >= 0 ||
        input.callSign.toLowerCase().indexOf(searchWord) >= 0 ||
        input.requestType.toLowerCase().indexOf(searchWord) >= 0 ||
        input.reservDate.indexOf(searchWord) >= 0 ||
        input.reservTime.indexOf(searchWord) >= 0 ||
        input.aircraftType.toLowerCase().indexOf(searchWord) >= 0 ||
        input.reservNumb.toLowerCase().indexOf(searchWord) >= 0 ||
        input.firstName.toLowerCase().indexOf(searchWord) >= 0 ||
        input.lastName.toLowerCase().indexOf(searchWord) >= 0 ||
        (input.firstName.toLowerCase() + " " + input.lastName.toLowerCase()).indexOf(searchWord) >= 0 ||
        (input.requestorEmail !== undefined && input.requestorEmail!.toLowerCase().indexOf(searchWord) >= 0) ||
        (input.requestorName !== undefined && input.requestorName!.replaceAll(" ", ',').toLowerCase().indexOf(searchWord.replaceAll(" ", ',')) >= 0);
    }

    if (settings.segmentHeader === "Domains") {
      toKeep =
        searchWord === "" ||
        input.company.toLowerCase().indexOf(searchWord) >= 0 ||
        input.domain.toLowerCase().indexOf(searchWord) >= 0 ||
        input.contactName.toLowerCase().indexOf(searchWord) >= 0 ||
        input.email.indexOf(searchWord) >= 0;
    }

    return toKeep;
  };

  const resetPaging = () => {
    setFirstRowIndex(0);
    setLastRowIndex(rowsPerPage);
  };

  useEffect(() => {
    if (searchBox.current) {
      searchBox.current.value = "";
    }
    setSearchWord("");
    resetPaging();
    // eslint-disable-next-line
  }, [data]);

  const filterAndSortData = (): any[] => {
    if (settings.segmentHeader === "Roles") {
      const sortOrder = ["SUPER ADMIN", "PLANNER", "ADMIN", "FAA", "PUBLIC", "INACTIVE"];
      return data
        .filter((usr) => applyFilter(usr))
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .sort((a, b) => sortOrder.indexOf(a.userRole) - sortOrder.indexOf(b.userRole));
    }

    if (settings.segmentHeader === "Report Center") {
      return data
        .filter((input) => applyFilter(input))
        .sort((a, b) => dayjs(b.reservTime).diff(a.reservTime))
        .sort((a, b) => dayjs(b.reservDate).diff(a.reservDate));
    }

    if (settings.segmentHeader === "Domains") {
      return data
        .filter((input) => applyFilter(input))
        .sort((a, b) => a.company.localeCompare(b.company))
    }

    return [null];
  };

  const getCsvData = () => {
    if (settings.segmentHeader === "Roles") {
      return [
        // settings.columns.map((col) => { return col['header']; }),
        ["First Name", "Last Name", "Email", "User Role", "Created", "Updated"],
        ...filterAndSortData().map(({ firstName, lastName, email, userRole, createdAt, updatedAt }) => [
          firstName,
          lastName,
          email,
          userRole,
          createdAt,
          updatedAt,
        ]),
      ];
    }

    if (settings.segmentHeader === "Report Center") {
      return [
        [
          "Airport",
          "Callsign",
          "Request",
          "Date",
          "Time",
          "Aircraft Type",
          "Reservation Number",
          "Requestor Name",
          "Users Email",
          "CreatedAt",
          "Creator's First Name",
          "Creator's Last Name",
        ],
        ...filterAndSortData().map(
          ({
            airportId,
            callSign,
            requestType,
            reservDate,
            reservTime,
            aircraftType,
            reservNumb,
            requestorName,
            requestorEmail,
            createdAt,
            firstName,
            lastName,
          }) => [
              airportId,
              callSign,
              requestType,
              reservDate,
              reservTime,
              aircraftType,
              reservNumb,
              requestorName,
              requestorEmail,
              createdAt,
              firstName,
              lastName,
            ]
        ),
      ];
    }

    if (settings.segmentHeader === "Domains") {
      return [
        ["Comapny", "Domain", "Contact Name", "Email", "Access Code", "Created", "Updated"],
        ...filterAndSortData().map(({ company, domain, contactName, email, accessCode, createdAt, updatedAt }) => [
          company,
          domain,
          contactName,
          email,
          accessCode,
          createdAt,
          updatedAt,
        ]),
      ];
    }

    return [];
  };

  const csvFileName = () => {
    if (settings.segmentHeader === "Roles") {
      return "FLYR_accounts_" + dayjs.utc().format() + ".csv";
    }
    if (settings.segmentHeader === "Report Center") {
      return eventName?.current + " Summary" + dayjs.utc().format() + ".csv";
    }
    if (settings.segmentHeader === "Domains") {
      return "FLYR_group_accounts_" + dayjs.utc().format() + ".csv";
    }
    return "FLYR" + dayjs.utc().format() + ".csv";
  };

  return (
    <div>
      <SegmentHeader>
        {settings.segmentHeader}({filterAndSortData().length}
        {settings.segmentHeader === "Roles" ? " Users" : ""})
        <p className={`search-container ${settings.segmentHeader.toLowerCase().replace(/\s+/g, "-")}-search` + (filterAndSortData().length === 0 ? " mobile-hidden-container" : "")}>
          <input
            ref={searchBox}
            className="new-input-search-field"
            type="text"
            placeholder="Keyword Search"
            onKeyDown={startSearch}
          />
          <FontAwesomeIcon className="search-icon" data-testid="search-icon" icon={faSearch} title="Keyword Search" />
        </p>
        {settings.segmentHeader === "Domains" &&
          <GroupModal rerenderTable={rerenderTable} setRerenderTable={setRerenderTable} context={"New"} />
        }
      </SegmentHeader>
      {filterAndSortData().length > 0 ? (
        <div className="table-info-container">
          <Card additionalClassNames="list-view-card" identifier={"accounts"}>
            <div className="table-export-container">
              {settings.segmentHeader === "Roles" && (
                <div className="info-icon-text">
                  <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
                  <p> All users must sign in to the application at least once before a role can be assigned</p>
                </div>
              )}
              {settings.segmentHeader === "Report Center" && (
                <p className="summary-text" data-testid="report-summary">
                  {eventName?.current}
                </p>
              )}
              <CSVLink className="export-button" data-testid="export-button" filename={csvFileName()} data={getCsvData()}>
                <FontAwesomeIcon className="export-icon" icon={faFileArrowDown} />
                Export
              </CSVLink>
            </div>
            <table className={settings.segmentHeader === "Report Center" ? "list-view-table report-table" : "list-view-table"}>
              <TableHeader columns={settings.columns} />
              <TableRows
                data={filterAndSortData()}
                setData={setData}
                columns={settings.columns}
                firstRowIndex={firstRowIndex}
                lastRowIndex={lastRowIndex}
                context={settings.segmentHeader}
                search={search}
                setSearch={setSearch}
              />
            </table>
            <Paginator
              firstRowIndex={firstRowIndex}
              lastRowIndex={lastRowIndex}
              maxRowIndex={filterAndSortData().length}
              rowsPerPage={rowsPerPage}
              setFirstRowIndex={setFirstRowIndex}
              setLastRowIndex={setLastRowIndex}
            />
          </Card>
          {settings.segmentHeader === "Roles" && (
            <Card additionalClassNames="accounts-info-card" identifier={"accounts-info"}>
              <div className="description-container" data-testid="description-container">
                <h4>Capabilities</h4>
                <p>
                  <strong>Public</strong> - View and update your own reservation
                </p>
                <p>
                  <strong>Admin</strong> - View/Modify/Create/Override all reservations
                </p>
                <p>
                  <strong>Planner</strong> - Same as Admin plus Create Slot Controlled Airports or Special Events
                </p>
                <p>
                  <strong>Super Admin</strong> - Same as Planner plus assign roles
                </p>
                <p>
                  <strong>Inactive</strong> - Account has been disabled
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : (
        <p className="empty-state" data-testid="empty-state">
          No Available Records.
        </p>
      )}
    </div>
  );
};

export default NewDataTable;
