import React, { useState } from "react";
import { LoggedUser } from "../types";

export const LocalTimeContext = React.createContext({
  isLocalTime: true,
  toggleLocalTime: () => {
    //do nothing.
  },
});

export const UserInfoContext = React.createContext({
  loggedUser: { firstName: "", email: "", role: "", inFaaNetwork: false},
  updateLoggedUser: (loggedUser: LoggedUser) => {},
});

interface AppContextProps {
  children: React.ReactNode;
  initialLocalTime?: boolean;
  loggedUser?: LoggedUser;
}

const AppContext: React.FC<AppContextProps> = ({
  children,
  initialLocalTime = true,
  loggedUser = { firstName: "", email: "", role: "", inFaaNetwork: false },
}) => {
  const [isLocalTime, setIsLocalTime] = useState(initialLocalTime);
  const [loggedUserInfo, setLoggedUserInfo] = useState(loggedUser);

  return (
    <LocalTimeContext.Provider
      value={{
        isLocalTime,
        toggleLocalTime: () => {
          setIsLocalTime(!isLocalTime);
        },
      }}
    >
      <UserInfoContext.Provider
        value={{
          loggedUser: loggedUserInfo,
          updateLoggedUser: (loggedUser: LoggedUser) => {
            setLoggedUserInfo(loggedUser);
          },
        }}
      >
        {children}
      </UserInfoContext.Provider>
    </LocalTimeContext.Provider>
  );
};

export default AppContext;
