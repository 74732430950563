async function get(url: string) {
  const result = await fetch(url);
  if (result.status >= 400) {
    return Promise.reject(result);
  }
  return await result.json();
}

async function getBy(url: string, data: any) {
  let jsonData = JSON.stringify(data);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: jsonData,
  });
  if (response.status >= 400) {
    return Promise.reject(response.json());
  }
  return response.json();
}

async function post(url: string, data: any) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.status === 409) {
    return response;
  }
  else if (response.status >= 400) {
    return Promise.reject(response.json());
  }
  return response.json();
}

async function del(url: string) {
  const result = await fetch(url, {
    method: "DELETE",
  });
  if (result.status === 204) {
    return null;
  }
  if (result.status >= 400) {
    return Promise.reject(result);
  }
  return await result.json();
}

async function getResult(url: string) {
  const result = await fetch(url);
  if (result.status >= 400) {
    return Promise.reject(result);
  }
  return result;
}

const RestHelpers = {
  get,
  getBy,
  post,
  del,
  getResult,
};

export default RestHelpers;

export enum API {
  Health = "/actuator/health",
  CreateEvent = "/api/create-event",
  CreateRule = "/api/create-rule",
  CancelRule = "/api/remove-rule",
  Role = "/api/role",
  UserReservations = "/api/get-user-reservations",
  AvailabilityGraphTimes = "/api/availabilityGraphTimes",
  ActiveReservations = "/api/get-all-active-reservations",
  CreateReservation = "/api/create-reservation",
  SendEmail = "/api/send-email",
  ModifyReservation = "/api/modify-reservation",
  CancelReservation = "/api/cancel-reservation",
  Reservations = "/api/reservations",
  RecentReservations = "/api/reservations/recent",
  OldestReservations = "/api/reservations/oldest",
  ActuatorInfo = "/actuator/info",
  AvailableTimes = "/api/availableTimes",
  CheckAvailableTimes = "/api/checkAvailableTimes",
  Events = "api/get-events",
  ActiveRules = "/api/getRules",
  AllRules = "/api/getAllRules",
  ActiveAndDisabledRules = "/api/getReportsRules",
  EventAirportRules = "/api/getEventAirportRules",
  EventAirports = "/api/getEventAirports",
  AllAirports = "/api/getAllAirports",
  Logout = "/logout",
  SetEventStatus = "/api/set-event-status",
  GetConfig = "/api/config",
  GetAllUsers = "/api/getAllUsers",
  ModifyUser = "/api/modify-user",
  GetAllGroups = "/api/get-all-groups",
  AllAircraftTypes = "/api/get-all-aircraft",
  LogLogIn = "/api/log-login",
  LogLogOut = "/api/log-logout",
  LogEventAction = "/api/log-event-action",
  EventLog = "/api/get-event-log",
  GetReports = "/api/get-requested-reservations",
  Validate30MinuteRule = "/api/thirtyMinuteRule",
  EnableGroupAccess = "/api/enable-group-access",
  DisableGroupAccess = "/api/disable-group-access",
  GetUserGroupAccess = "/api/get-user-group-access",
  CreateGroup = "/api/add-group-access",
  ModifyGroup = "/api/modify-group",
  NewAccessCode = "/api/generate-group-code",
  RemoveGroup = "/api/remove-group",
}
