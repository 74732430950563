import React, { useContext } from "react";
import "./groupAccessComponent.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Modal from "react-modal";
import { faTimes, faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { UserInfoContext } from "../../../config/AppContext";
dayjs.extend(utc);

export interface GroupAccessProps {
  setGroupAccess: React.Dispatch<React.SetStateAction<boolean>>;
  groupAccess: boolean;
}

const GroupAccessComponent: React.FC<GroupAccessProps> = ({ setGroupAccess, groupAccess }) => {
  const { loggedUser: user } = useContext(UserInfoContext);
  const [accessCode, setAccessCode] = React.useState("");
  const [groupAccessOpen, setGroupAccessOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  function openModal() {
    setGroupAccessOpen(true);
  }

  function closeModal() {
    setGroupAccessOpen(false);
    window.location.reload();
  }

  function goToPersonalReservations() {
    disableUserGroupAccess();
  }

  const disableUserGroupAccess = async () => {
    const response = await restHelpers.getResult(`${API.DisableGroupAccess}`);
    response.json().then((userInfo) => {
      setGroupAccess(userInfo.group as boolean);
    });
    window.location.reload();
  };

  const getGroupAccessButton = (access: boolean) => {
    if (access) {
      return (
        <button className="group-access-button" aria-label={"Group Access"} onClick={goToPersonalReservations}>
          <FontAwesomeIcon icon={faUser} />
          Individual
        </button>
      );
    } else {
      return (
        <button className="group-access-button" aria-label={"Group Access"} onClick={openModal}>
          <FontAwesomeIcon icon={faUserGroup} />
          Group
        </button>
      );
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement, MouseEvent>): Promise<void> => {
    const reqJson: any = {
      domain: user.email.split("@")[1],
      accessCode: accessCode,
    };

    await restHelpers
      .post(API.EnableGroupAccess, reqJson)
      .then((resp: any) => {
        console.log("resp" + resp.message);
        if (resp.message === "VALID") {
          setGroupAccess(true);
          closeModal();
        } else if (resp.message === "INVALID") {
          console.log("resp" + resp.message);
          setErrorMessage("Invalid Access Code. Please try again.");
          setGroupAccess(false);
        }
      })
      .catch((err) => {
        console.log("Error to EnableGroupAccess" + err);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAccessCode(event.target.value);
  };

  return (
    <div className="group-access-container">
      {groupAccessOpen && (
        <Modal
          isOpen={groupAccessOpen}
          onRequestClose={closeModal}
          className="reservation-modal"
          overlayClassName="confirmation-modal-overlay"
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
        >
          <div className="group-access-body">
            <h3 className="reservation-modal-title">Group Access</h3>
            <ul className="reservation-fields-list">
              <li>
                <div className="reservation-form-fields">
                  <label className="reservation-form-label-text"> Access Code: </label>
                  <input
                    type="text"
                    name="groupAccess"
                    data-testid="groupAccess"
                    aria-label="Group Access"
                    value={accessCode}
                    onChange={handleChange}
                    className="reservation-modal-input"
                  />
                </div>
              </li>
            </ul>
            <input
              className={accessCode === "" ? "disabled-submit-button" : "submit-button"}
              type="submit"
              data-testid="submit-button"
              value="Submit"
              onClick={handleSubmit}
              disabled={accessCode === "" ? true : false}
            />
            <div id="error" className="error-message">
              {errorMessage && <p className="error-message"> {errorMessage}</p>}
            </div>
          </div>
          <button
            className="reservation-modal-close"
            data-testid={`reservation-modal-button-close`}
            aria-label={"Close Reservation Modal"}
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal>
      )}
      {groupAccess}
      {getGroupAccessButton(groupAccess as boolean)}
    </div>
  );
};

export default GroupAccessComponent;
