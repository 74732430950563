import React, { useEffect } from "react";
import Acceptance from "./Acceptance";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { ReservApiResponse } from "../../../types";
import { getAndUpdateState } from "../../../helpers/stateHelpers";
import "./acceptance.scss";

const AcceptancePage: React.FC = () => {
  const [apiHasBeenCalled, setApiHasBeenCalled] = React.useState<boolean>(false);

  const [apiIsUp, setIsItUp] = React.useState<string>("");
  const [database, setDatabase] = React.useState<string>("");
  const [reservations, setReservations] = React.useState<ReservApiResponse[]>([]);

  const getMessages = (api: API) => {
    getAndUpdateState(api, setReservations);
  };

  useEffect(() => {
    if (!apiHasBeenCalled) {
      restHelpers
        .get(API.Health)
        .then((json: any) => {
          setIsItUp(json.status);
          const components = json.components || {};
          const dbJson = components.db || {};
          const dbDetails = dbJson.details || {};
          const db = dbDetails.database || "ERROR";
          setDatabase(db);
        })
        .catch(() => {
          setIsItUp("Down");
          setDatabase("ERROR");
        });
      getMessages(API.Reservations);

      setApiHasBeenCalled(true);
    }
  }, [apiHasBeenCalled]);

  return (
    <div className="acceptance">
      <header className="acceptance-header">FLYR</header>
      <button
        className="load-button"
        onClick={() => {
          getMessages(API.Reservations);
        }}
      >
        Load All
      </button>

      <button
        className="load-button"
        onClick={() => {
          getMessages(API.OldestReservations);
        }}
      >
        Load Oldest
      </button>

      <button
        className="load-button"
        onClick={() => {
          getMessages(API.Reservations);
        }}
      >
        Load Recent
      </button>
      <Acceptance apiIsUp={apiIsUp} database={database} reservations={reservations} />
    </div>
  );
};

export default AcceptancePage;
