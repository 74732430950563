import React, { useEffect } from "react";
import "./timePickerComponent.scss";

export interface TimePickerProps {
  times: string[];
  setTimeValue?: any;
  currentSelectedTime?: string;
  date?: string;
  isAdmin?: boolean;
  setOverLimit?: any;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({
  times,
  setTimeValue,
  currentSelectedTime,
  date,
  isAdmin,
  setOverLimit,
}) => {
  const [clickedButton, setClickedButton] = React.useState(currentSelectedTime);

  const onTimeChange = (event: any) => {
    setTimeValue(event.target.value);
    setClickedButton(event.target.id);
  };

  function checkLimit(hourlyInterval: any) {
    if (activeReservations[hourlyInterval] >= maxReservations[hourlyInterval]) {
      setOverLimit(parseInt(activeReservations[hourlyInterval]) - parseInt(maxReservations[hourlyInterval]));
    } else {
      setOverLimit(-1);
    }
  }

  useEffect(() => {
    setClickedButton(currentSelectedTime);
  }, [date, currentSelectedTime]);

  let timesLength = times.length / 3;
  const availableTimes = times.slice(0, timesLength);
  const activeReservations = times.slice(timesLength, timesLength * 2);
  const maxReservations = times.slice(timesLength * 2, timesLength * 3);

  return (
    <div className="available-times-container">
      <>
        <h3 className="available-times">Available Reservation Times</h3>
        <div className="available-times-list" id="scroll">
          {times.length > 0 
          ? (isAdmin
            ? availableTimes.map((time: any, hourlyInterval) => time && (
                <button
                  onClick={function (event) {
                    onTimeChange(event);
                    checkLimit(hourlyInterval);
                  }}
                  value={time}
                  className={time === clickedButton ? "button-color" : "available-time-button"}
                  id={time}
                  key={time}
                >
                  {time + "Z"}
                  <br />
                  <div
                    className={
                      maxReservations[hourlyInterval] !== "-" && parseInt(activeReservations[hourlyInterval]) >= parseInt(maxReservations[hourlyInterval])
                        ? "reserved-reservations-warning"
                        : "reserved-reservations"
                    }
                  >
                    {activeReservations[hourlyInterval] + 
                    (maxReservations[hourlyInterval] === "-" ? "" : " of " + maxReservations[hourlyInterval]) + 
                    " reserved"}
                  </div>
                </button>
              ))
            : times.map((time: any) => (
              <button
                onClick={onTimeChange}
                value={time}
                className={time === clickedButton ? "button-color" : "available-time-button"}
                id={time}
                key={time}
              >
                {time + "Z"}
              </button>
            ))
            ) 
          : (<p className="no-times-available">There are no times available for this day.</p>
          )}
        </div>
      </>
    </div>
  );
};

export default TimePickerComponent;
