import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { ReservApiResponse } from "../types";
dayjs.extend(utc);

export interface InfoTableProps {
  tableTitle: string;
  tableValues: ReservApiResponse[];
  className?: string;
  displayXmlWithFormatting?: boolean;
}

const InfoTable: React.FC<InfoTableProps> = ({
  tableTitle,
  tableValues,
  className,
  displayXmlWithFormatting,
}: InfoTableProps) => {
  return (
    <div className={className}>
      <h2>{tableTitle}</h2>
      <table aria-label={tableTitle}>
        <tbody>
          <tr>
            <th>No.</th>
            <th>JSON</th>
          </tr>
          {tableValues
            .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt))
            .map((value, index) => {
              return (
                <tr key={value.id} aria-label={`${tableTitle} - ${index}`}>
                  <td key={`no-${index}`}>{index}</td>
                  <td key={`json-${value.id}`}>
                    {displayXmlWithFormatting ? <pre>{JSON.stringify(value)}</pre> : <>{JSON.stringify(value)}</>}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default InfoTable;
