import "./programSetup.scss";
import SegmentHeader from "../../components/shared/segmentHeader/SegmentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faCheck, faClose, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import Select, { SingleValue, ActionMeta } from 'react-select';
import DatePicker from "../../components/shared/datepicker/DatePicker";
import restHelpers, { API } from "../../helpers/restHelpers";
import { Airport, Event as FlyrEvent } from "../../types";
import AirportRules, { AirportSelectOption } from "../../components/shared/airportrules/AirportRules";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getAndApplyLogic } from "../../helpers/stateHelpers";
import ConfirmationDialog from "../../components/shared/confirmationdialog/confirmationDialog";
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export interface ProgramSetupProps {
  eventToModify?: FlyrEvent;
  programType: string;
  airportOptions: AirportSelectOption[];
}

const ProgramSetup: React.FC<ProgramSetupProps> = ({ eventToModify, programType, airportOptions }) => {

  const isNoEndDate = () => programType === "HDA" ? (eventToModify ? (eventToModify.endTime ? false : true) : true) : false;

  const getAirportName = (airportId: string): string => {
    const airportOpt = airportOptions.find((ap) => ap.value === airportId);
    const airportName = airportOpt!.label;
    return airportName;
  }

  const [formValues, setFormValues] = React.useState({
    eventName: eventToModify ? eventToModify.eventName : "",
    startDate: eventToModify ? dayjs(eventToModify.startTime).utc().format("MMMM DD, YYYY") : "",
    startTime: eventToModify ? dayjs(eventToModify.startTime).utc().format("HHmm[Z]") : "0000Z",
    endDate: eventToModify && eventToModify.endTime ? dayjs(eventToModify.endTime).utc().format("MMMM DD, YYYY") : "",
    endTime: isNoEndDate() ? "" : (eventToModify && eventToModify.endTime ? dayjs(eventToModify.endTime).utc().format("HHmm[Z]") : "2359Z"),
    goLive: eventToModify && eventToModify.liveDate ? dayjs(eventToModify.liveDate).utc().format("MMMM DD, YYYY") : "",
    goLiveTime:
      eventToModify && eventToModify.liveDate ? dayjs(eventToModify.liveDate).utc().format("HHmm[Z]") : "0000Z",
    description: eventToModify ? eventToModify.eventDesc : "",
    noEndDate: isNoEndDate(),
    //    pickedAirportOption: {label: "", value: "",} as AirportSelectOption,
    aiportName: programType === "HDA" && eventToModify ? getAirportName(eventToModify.eventName) : "",
  });
  const [myEvent, setMyEvent] = React.useState<FlyrEvent | undefined>(eventToModify ? { ...eventToModify } : undefined);
  const [calendarIsOpen, setCalendarIsOpen] = React.useState(false);
  const hiddenDialogState = {
    isOpen: false,
    content: <></>,
    autoDismiss: undefined as number | undefined,
  };
  const [openSaveConfirmation, setOpenSaveConfirmation] = React.useState(hiddenDialogState);
  const [openAirportRules, setOpenAirportRules] = React.useState({
    airportRulesOpen: false,
    airportIdToEdit: "",
  });
  const [selected, setSelected] = React.useState("");
  const [isFocused, setIsFocused] = React.useState("");
  const [refreshAirportList, setrefreshAirportList] = React.useState(0.1);
  const [airportList, setAirportList] = React.useState<Airport[]>([]);
  //  const [airportOptions, setAirportOptions] = React.useState<AirportSelectOption[]>([]);
  const startDateInput = React.useRef<HTMLInputElement>(null);
  const endDateInput = React.useRef<HTMLInputElement>(null);
  const goLiveInput = React.useRef<HTMLInputElement>(null);

  const canSaveProgram = (): boolean => {
    let canSave = formValues.eventName !== "" && formValues.startDate !== "";
    if (programType === "SE") {
      canSave &&= formValues.endDate !== "";
    }
    if (programType === "HDA") {
      canSave &&= (formValues.noEndDate || formValues.endDate !== "");
    }

    return canSave;
  };

  const canAddAirport = (): boolean => {
    let canAdd = myEvent !== undefined && myEvent.id !== undefined;
    return canAdd;
  };

  const setStartDate = (date: string) => setNativeInput(startDateInput.current!, dayjs(date).format("MMMM DD, YYYY"));

  const setEndDate = (date: string) => setNativeInput(endDateInput.current!, dayjs(date).format("MMMM DD, YYYY"));

  const setGoLive = (date: string) => setNativeInput(goLiveInput.current!, dayjs(date).format("MMMM DD, YYYY"));

  // Force onchange event of the date picker fields to fire;
  // This is not pretty but will make test writing much easier.
  const setNativeInput = (inputRef: any, value: string) => {
    const setter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set;
    setter!.call(inputRef, value);
    const inputEvent = new Event("input", { bubbles: true });
    inputRef.dispatchEvent(inputEvent);
  };

  const handleFocus = (event: { target: { name: React.SetStateAction<string> } }) => {
    setIsFocused(event?.target.name);
  };

  const handleBlur = () => {
    setIsFocused("");
  };

  const closeDialog = () => {
    setOpenSaveConfirmation(hiddenDialogState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    setIsFocused("");
    document.getElementById(event.target.id)?.blur();
  };

  const handleDescriptionChange = (event: { target: { name: any; value: any; id: string } }) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleKeyDown = (e: any) => {
    setTimeout(() => {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
      let buttonMargin = e.target.scrollHeight - 62;
      document.getElementById("save-button")!.style.marginTop = `${buttonMargin}px`;
    }, 0);
  };

  const handleSubmit = async () => {
    const eventData: FlyrEvent = {
      id: myEvent?.id,
      eventType: myEvent?.eventType || programType,
      eventName: formValues.eventName.trim(),
      startTime: dayjs.utc(formValues.startDate + " " + formValues.startTime, "MMMM DD, YYYY HHmm[Z]", true).toDate(),
      endTime: dayjs.utc(formValues.endDate + " " + formValues.endTime, "MMMM DD, YYYY HHmm[Z]", true).toDate(),
      liveDate:
        formValues.goLive !== ""
          ? dayjs.utc(formValues.goLive + " " + formValues.goLiveTime, "MMMM DD, YYYY HHmm[Z]", true).toDate()
          : null,
      eventStatus: myEvent?.eventStatus || "In Progress",
      eventDesc: formValues.description,
      comments: myEvent?.comments ?? "",
    };

    await restHelpers
      .post(API.CreateEvent, eventData)
      .then((newEvent) => {
        setMyEvent({ ...newEvent });
        setOpenSaveConfirmation({
          isOpen: true,
          content: (
            <>
              <div className="dialog-short-message"><FontAwesomeIcon icon={faCheck} className="content-lead-icon success-color"></FontAwesomeIcon></div>
              <div className="body-text-large success-color">&nbsp;&nbsp;&nbsp;Successfully Saved</div>
            </>
          ),
          autoDismiss: 750,
        });
      })
      .catch((error) => {
        setOpenSaveConfirmation({
          isOpen: true,
          content: (
            <>
              <div className="dialog-short-message"><FontAwesomeIcon icon={faClose} className="content-lead-icon error-color"></FontAwesomeIcon></div>
              <div className="body-text-large error-color">&nbsp;&nbsp;&nbsp;Save Failed</div>
            </>
          ),
          autoDismiss: undefined,
        });
      });
  };

  function addAirportRules() {
    setOpenAirportRules({
      airportRulesOpen: true,
      airportIdToEdit: programType === "SE" ? "" : myEvent!.eventName,
    });
  }

  function closeAirportRules() {
    setOpenAirportRules({
      airportRulesOpen: false,
      airportIdToEdit: "",
    });
    setrefreshAirportList(Math.random());
  }

  function openDatePicker() {
    setCalendarIsOpen(true);
  }

  const removeRule = async (event: any) => {
    const trimmedStr = event.target.value.trim();
    const airportName = trimmedStr.length > 3 ? trimmedStr.slice(-4, -1) : trimmedStr;
    const delUrl = encodeURI(`${API.CancelRule}?eventName=${myEvent?.eventName}&airportId=${airportName}`);

    await restHelpers
      .del(delUrl)
      .then((apList) => {
        setAirportList(apList);
      })
      .catch((err) => ({
        throw: err,
      }));
  };

  const modifyRule = (ap: Airport) => {
    setOpenAirportRules({
      airportRulesOpen: true,
      airportIdToEdit: ap.airportId,
    });
  };

  useEffect(() => {
    if (!myEvent) {
      return;
    }

    document.getElementById("descriptionTextArea")?.click();
    const ruleUrl = encodeURI(`${API.EventAirports}?eventName=${myEvent!.eventName}`);
    getAndApplyLogic(ruleUrl, (jsonObj: Airport[]) => {
      const airports = jsonObj.map((ap) => ap as Airport);
      setAirportList(airports);
    });
    // eslint-disable-next-line
  }, [refreshAirportList]);

  const endMinuteIntervals: JSX.Element[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 14; minute < 60; minute += 15) {
      const timeString = `${hour.toString().padStart(2, "0")}${(minute).toString().padStart(2, "0")}Z`;
      endMinuteIntervals.push(
        <option key={timeString} value={timeString}>
          {timeString}
        </option>
      );
    }
  }
  const noEndMinuteIntervals: JSX.Element[] = [
    <option key="no_end_time" value={""}>
    </option>
  ];


  const minuteIntervals: JSX.Element[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const timeString = `${hour.toString().padStart(2, "0")}${minute.toString().padStart(2, "0")}Z`;
      minuteIntervals.push(
        <option key={timeString} value={timeString}>
          {timeString}
        </option>
      );
    }
  }

  const handleAirportChange = (
    newValue: SingleValue<any>,
    action: ActionMeta<string>
  ) => {
    if (action.action === 'select-option') {
      setFormValues(prevValues => ({
        ...prevValues,
        eventName: newValue!.value,
        aiportName: newValue!.label,
      }));
    }
    else if (action.action === 'clear') {
      setFormValues(prevValues => ({
        ...prevValues,
        eventName: "",
        aiportName: "",
      }));
    }
  };

  const handleNoEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setFormValues({
      ...formValues,
      [event.target.name]: checked,
      endDate: checked ? "" : formValues.endDate,
      endTime: checked ? "" : "2359Z",
    });

  };

  return (
    <>
      <SegmentHeader>Program Setup</SegmentHeader>
      <div className="program-setup-container">
        <h3 className="program-setup-header">
          {!programType || programType === "SE" ? "Special Event" : "Slot Controlled Airport"}
        </h3>
        <form className="program-setup-form-container" data-testid="program-setup-form-container">
          <ul>
            <li> {programType === "SE"
              ? (<div className="program-setup-form-fields">
                <label className="program-setup-form-label-text">Name:</label>
                <input
                  type="text"
                  name="eventName"
                  className="event-name-field"
                  data-testid="eventName"
                  aria-label="eventName"
                  autoComplete="off"
                  value={formValues.eventName.replace(/[^a-zA-Z0-9 ]/g, "")}
                  onChange={handleChange}
                />
              </div>)
              : (<div className="program-setup-form-fields">
                <label className="program-setup-form-label-text">Airport:</label>
                {canAddAirport()
                  ? <div>{formValues.aiportName}</div>
                  : <Select
                    className="airport-name-dropdown"
                    defaultValue={airportOptions[0] as any}
                    isSearchable={true}
                    isDisabled={canAddAirport()}
                    name="Airport"
                    aria-label="Airport Select"
                    options={airportOptions as any}
                    onChange={handleAirportChange}
                    isClearable={true}

                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: '#707070',
                        borderWidth: 2,
                        backgroundColor: '#f0f0f0',
                      }),
                    }}
                  />
                }
              </div>)}
            </li>
            <li>
              <div className="program-setup-form-fields">
                <label className="program-setup-form-label-text"> Start:</label>
                <div className="program-setup-datetime-input-button">
                  <>
                    <input
                      ref={startDateInput}
                      className="program-setup-date-pointer"
                      type="text"
                      onClick={function () {
                        openDatePicker();
                        setSelected("start");
                      }}
                      name="startDate"
                      data-testid="startDate"
                      aria-label="startDate"
                      value={formValues.startDate}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </>
                  <button
                    className="open-calendar-program-setup"
                    aria-label={"Open Calendar"}
                    data-testid="open-calendar"
                    onClick={function () {
                      openDatePicker();
                      setSelected("start");
                    }}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </button>
                </div>
                <select
                  className={
                    isFocused === "startTime" ? "program-setup-select-time-focused" : "program-setup-select-time"
                  }
                  id="startTime"
                  name="startTime"
                  aria-label="startTime"
                  // onFocus={handleFocus}
                  // onBlur={handleBlur}
                  size={isFocused === "startTime" ? 10 : 1}
                  value={formValues.startTime}
                  onChange={handleChange}
                >
                  {minuteIntervals}
                </select>
              </div>
            </li>
            <li>
              <div className="program-setup-form-fields">
                <label className="program-setup-form-label-text"> End:</label>
                <div className="program-setup-datetime-input-button">
                  <>
                    <input
                      ref={endDateInput}
                      type="text"
                      className={formValues.noEndDate ? "program-setup-date-disabled" : "program-setup-date-pointer"}
                      onClick={function () {
                        openDatePicker();
                        setSelected("end");
                      }}
                      name="endDate"
                      data-testid="endDate"
                      aria-label="endDate"
                      value={formValues.endDate}
                      autoComplete="off"
                      onChange={handleChange}
                      disabled={formValues.noEndDate}
                    />
                  </>
                  <button
                    className={formValues.noEndDate ? "calendar-program-setup-disabled" : "open-calendar-program-setup"}
                    aria-label={"Open Calendar"}
                    data-testid="open-calendar"
                    onClick={function () {
                      openDatePicker();
                      setSelected("end");
                    }}
                    type="button"
                    disabled={formValues.noEndDate}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </button>
                </div>
                <select
                  className={
                    formValues.noEndDate
                      ? "program-setup-select-time-disabled"
                      : (isFocused === "endTime" ? "program-setup-select-time-focused" : "program-setup-select-time")
                  }
                  id="endTime"
                  name="endTime"
                  aria-label="endTime"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  size={isFocused === "endTime" ? 10 : 1}
                  value={formValues.endTime}
                  onChange={handleChange}
                  disabled={formValues.noEndDate}
                >
                  {formValues.noEndDate ? noEndMinuteIntervals : endMinuteIntervals}
                </select>
                {programType === "HDA" && (<div className="no-enddate-container">
                  <input type="checkbox"
                    name="noEndDate"
                    className="no-end-date"
                    checked={formValues.noEndDate}
                    onChange={handleNoEndDateChange}
                    aria-label="No End Date"
                  />
                  <label className="no-enddate-label">No End Date</label>
                </div>)}
              </div>
            </li>
            <li>
              <div className="program-setup-form-fields">
                <label className="program-setup-form-label-text"> Go Live:</label>
                <div className="program-setup-datetime-input-button">
                  <>
                    <input
                      ref={goLiveInput}
                      type="text"
                      className="program-setup-date-pointer"
                      onClick={function () {
                        openDatePicker();
                        setSelected("golive");
                      }}
                      name="goLive"
                      data-testid="goLive"
                      aria-label="goLiveDate"
                      value={formValues.goLive}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </>
                  <button
                    className="open-calendar-program-setup"
                    aria-label={"Open Calendar"}
                    data-testid="open-calendar"
                    onClick={function () {
                      openDatePicker();
                      setSelected("golive");
                    }}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </button>
                </div>
                <select
                  className={
                    isFocused === "goLiveTime" ? "program-setup-select-time-focused" : "program-setup-select-time"
                  }
                  id="goLiveTime"
                  name="goLiveTime"
                  aria-label="goLiveTime"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  size={isFocused === "goLiveTime" ? 10 : 1}
                  value={formValues.goLiveTime}
                  onChange={handleChange}
                >
                  {minuteIntervals}
                </select>
              </div>
            </li>
            <li>
              <div className="program-setup-form-fields program-setup-description">
                <label className="program-setup-form-label-text"> Description:</label>
                <div className="setup-description-text-div">
                  <textarea
                    className="setup-description-text"
                    name="description"
                    data-testid="description"
                    id="descriptionTextArea"
                    aria-label="description"
                    autoComplete="off"
                    onKeyDown={handleKeyDown}
                    onPaste={handleKeyDown}
                    onClick={handleKeyDown}
                    maxLength={1000}
                    onChange={handleDescriptionChange}
                    value={formValues.description}
                  />
                </div>
              </div>
            </li>
          </ul>
          <button
            className={canSaveProgram() ? "save-program-button" : "save-program-button-disabled"}
            onClick={handleSubmit}
            type="button"
            id="save-button"
            data-testid="save-program"
            aria-label="save-program"
            name="save-program"
            disabled={canSaveProgram() ? false : true}
          >
            Save
          </button>
        </form>
        {programType === "SE"
          ? (
            <div className="airport-selection-container">
              Airport:
              <div className="airports-container">
                <div className="airports-table">
                  {airportList.length !== 0 ? (
                    // {airportList}
                    airportList.map((airport: Airport, index: number) => {
                      const airportFullName = airport.airportName
                        ? airport.airportName + " (" + airport.airportId + ")"
                        : airport.airportId;
                      return (
                        <div
                          className={
                            airportList.length === 1
                              ? "airport-single"
                              : airportList.length - 1 === index && index % 2 === 0
                                ? "airport-last-white"
                                : airportList.length - 1 === index && index % 2 !== 0
                                  ? "airport-last-gray"
                                  : index % 2 === 0
                                    ? "airport-odd"
                                    : "airport-even"
                          }
                          key={`airport_row_${index}`}
                        >
                          {airportFullName}
                          <div className="remove button container" key={`airport_buttons_${index}`}>
                            <button
                              onClick={() => modifyRule(airport)}
                              className="modify-rule"
                              id={`button_modify_${airport.airportId}_${index}`}
                              value={airport.airportId}
                              aria-label={`button_modify_${airport.airportId}`}
                              key={`button_modify_${airport.airportId}_${index}`}
                            >
                              <FontAwesomeIcon className="trashcan-icon-setup" icon={faPen} />
                              Modify
                            </button>
                            {(typeof (eventToModify) === 'undefined' || (eventToModify && eventToModify.eventStatus === "In Progress")) && (
                              <button
                                onClick={(event) => removeRule(event)}
                                className="remove-rule"
                                id={`button_remove_${airport.airportId}_${index}`}
                                value={airport.airportId}
                                aria-label={`button_remove_${airport.airportId}`}
                                key={`button_remove_${airport.airportId}_${index}`}
                              >
                                <FontAwesomeIcon className="trashcan-icon-setup" icon={faTrashCan} />
                                Remove
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <p className="airport-none">No airport has been added</p>
                    </div>
                  )}
                </div>
                <button
                  className={canAddAirport() ? "open-airport-rules-button" : "open-airport-rules-button-disabled"}
                  disabled={canAddAirport() ? false : true}
                  onClick={addAirportRules}
                  type="button"
                  data-testid="airport-rules"
                  aria-label="airport-rules"
                  name="airport-rules"
                >
                  Add Airport
                </button>
              </div>
            </div>)
          : canAddAirport() &&
          (<div className="airport-selection-container">
            Airport:
            <div className="airports-container">
              <div className="airports-table">
                {airportList.length > 0 ? (
                  // {airportList, just one}
                  airportList.map((airport: Airport, index: number) => {
                    const airportFullName = airport.airportName
                      ? airport.airportName + " (" + airport.airportId + ")"
                      : airport.airportId;
                    return (
                      <div
                        className="airport-single"
                        key="airport_row_01"
                      >
                        {airportFullName}
                        <div className="remove button container" key={`airport_buttons_${index}`}>
                          <button
                            onClick={() => modifyRule(airport)}
                            className="modify-rule"
                            id={`button_modify_${airport.airportId}_${index}`}
                            value={airport.airportId}
                            aria-label={`button_modify_${airport.airportId}`}
                            key={`button_modify_${airport.airportId}_${index}`}
                          >
                            <FontAwesomeIcon className="trashcan-icon-setup" icon={faPen} />
                            Modify
                          </button>
                          {(typeof (eventToModify) === 'undefined' || (eventToModify && eventToModify.eventStatus === "In Progress")) && (
                            <button
                              onClick={(event) => removeRule(event)}
                              className="remove-rule"
                              id={`button_remove_${airport.airportId}_${index}`}
                              value={airport.airportId}
                              aria-label={`button_remove_${airport.airportId}`}
                              key={`button_remove_${airport.airportId}_${index}`}
                            >
                              <FontAwesomeIcon className="trashcan-icon-setup" icon={faTrashCan} />
                              Remove
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <div
                      className="airport-single"
                      key="airport_row_01"
                    >
                      {formValues.aiportName}
                    </div>
                  </div>
                )}
              </div>
              {airportList.length < 1 &&
                (<button
                  className={canAddAirport() ? "open-airport-rules-button" : "open-airport-rules-button-disabled"}
                  disabled={canAddAirport() ? false : true}
                  onClick={addAirportRules}
                  type="button"
                  data-testid="airport-rules"
                  aria-label="airport-rules"
                  name="airport-rules"
                >
                  Add Specifications
                </button>)
              }
            </div>
          </div>)
        }
      </div>
      {openAirportRules.airportRulesOpen && (
        <div>
          <AirportRules
            closeAirportRules={closeAirportRules}
            eventName={myEvent!.eventName}
            eventStartDate={dayjs(myEvent!.startTime).utc().format("MMMM DD, YYYY")}
            eventEndDate={myEvent!.endTime ? dayjs(myEvent!.endTime).utc().format("MMMM DD, YYYY") : ""}
            eventStartTime={myEvent!.startTime ? dayjs(myEvent!.startTime).utc().format("HHmm[Z]") : undefined}
            eventEndTime={myEvent!.endTime ? dayjs(myEvent!.endTime).utc().format("HHmm[Z]") : undefined}
            airportId={openAirportRules.airportIdToEdit}
            airportOptions={airportOptions}
          />
        </div>
      )}
      {calendarIsOpen && selected === "start" ? (
        <div className="calendar-container">
          <DatePicker
            setDate={setStartDate}
            setDatePickerIsOpen={setCalendarIsOpen}
            currentSelectedDate={formValues.startDate}
            endDate={formValues.endDate}
          />
        </div>
      ) : calendarIsOpen && selected === "end" ? (
        <div className="calendar-container">
          <DatePicker
            setDate={setEndDate}
            setDatePickerIsOpen={setCalendarIsOpen}
            currentSelectedDate={formValues.endDate}
            startDate={formValues.startDate}
          />
        </div>
      ) : calendarIsOpen && selected === "golive" ? (
        <div className="calendar-container">
          <DatePicker
            setDate={setGoLive}
            setDatePickerIsOpen={setCalendarIsOpen}
            currentSelectedDate={formValues.goLive}
          />
        </div>
      ) : null}
      {
        <ConfirmationDialog
          isOpen={openSaveConfirmation.isOpen}
          description={openSaveConfirmation.content}
          closeHandle={closeDialog}
          autoDismissInMs={openSaveConfirmation.autoDismiss}
        />
      }
    </>
  );
};

export default ProgramSetup;
