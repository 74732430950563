import React from "react";
import "./groupModal.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Modal from "react-modal";
import { faPen, faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { Group } from "../../../types";
dayjs.extend(utc);

export interface GroupModalProps {
  rerenderTable?: any;
  setRerenderTable?: any;
  context: string;
  existingGroup?: Group;
  inputData?: any;
  setInputData?: any;
}

const GroupModal: React.FC<GroupModalProps> = ({ rerenderTable, setRerenderTable, context, existingGroup, inputData, setInputData }) => {
  const [groupAccessOpen, setGroupAccessOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [validEmail, setValidEmail] = React.useState<boolean>(context === "Modify" ? true : false);
  const [validDomain, setValidDomain] = React.useState<boolean>(context === "Modify" ? true : false);

  const [formValues, setFormValues] = React.useState({
    company: existingGroup?.company || "",
    domain: existingGroup?.domain || "",
    contactName: existingGroup?.contactName || "",
    email: existingGroup?.email || "",
  });

  function openModal() {
    if (context === "New") {
      setFormValues({ company: "", domain: "", contactName: "", email: "" });
    }
    setGroupAccessOpen(true);
  }

  function closeModal() {
    setGroupAccessOpen(false);
  }

  const validateEmail = (event: any): void => {
    if (
      event.target.value === "" ||
      new RegExp("[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$").test(event.target.value)
    ) {
      setErrorMessage("");
      setValidEmail(true);
    } else {
      setErrorMessage("Please enter a valid email address (ex: johndoe@gmail.com)");
      setValidEmail(false);
    }
  };

  const validateDomain = (event: any): void => {
    if (
      event.target.value === "" ||
      new RegExp("([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$").test(event.target.value)
    ) {
      setErrorMessage("");
      setValidDomain(true);
    } else {
      setValidDomain(false);
      setErrorMessage("Please enter a valid domain (ex: faa.gov)");
    }
  };

  function rerenderGroupTable() {
    setRerenderTable(!rerenderTable);
    document.getElementById("Users")?.click();
    setTimeout(() => {
      document.getElementById("Groups")?.click();
    }, 150);
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLInputElement, MouseEvent>): Promise<void> => {
    let action;
    let error = false;
    if (validEmail === false) {
      setErrorMessage("Please enter a valid email address (ex: johndoe@gmail.com)");
      return;
    }
    if (validDomain === false) {
      setErrorMessage("Please enter a valid domain (ex: faa.gov)");
      return;
    }

    if (context === "Modify") {
      action = `${API.ModifyGroup}`;
    }
    else {
      action = `${API.CreateGroup}`;
    }
    await restHelpers
      .post(action, formValues)
      .then(async response => {
        if (response.status === 409) {
          error = true;
          setErrorMessage("Sorry, there is already a group with that domain. Please try again.");
        }
        await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust the delay time as needed
      })
      .then(() => {
        if (error === false && errorMessage === "" && context === "New") {
          rerenderGroupTable();
        }
      })      
      .catch((e) => {
        console.log(e);
        error = true;
        setErrorMessage("Sorry, there was an error creating the group. Please try again.")
      });

    if (context === "Modify") {
      let updatedDataRows = inputData.map((obj: { domain: string; }) => {
        if (obj.domain === formValues.domain) {
          return {
            ...obj,
            contactName: formValues.contactName,
            email: formValues.email
          };
        } else {
          return obj;
        }
      });
      if (error === false && errorMessage === "") {
        setInputData(updatedDataRows);
        closeModal();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const target = event.target.name;
    setFormValues({ ...formValues, [target]: event.target.value });
  };

  return (
    <div className="group-access-container">
      {context === "New" ? (
        <button
          className={"new-reservation-button"}
          onClick={openModal}
          id={"new-group-button"}
        // aria-label={actionType?.toLowerCase() + "-reservation-button-" + reservationEvent?.airportId + "-" + reservationEvent?.requestType + "-" + reservationEvent?.callSign}
        >
          <FontAwesomeIcon className="new-icon" data-testid="new-reservation-modal-icon" icon={faPlusCircle} />
          New Group
        </button>
      ) : context === "Modify" ? (
        <div
          onClick={openModal}
        >
          <FontAwesomeIcon className="modify-admin-icon" icon={faPen} />
          Modify
        </div>
      ) : (
        <></>
      )}
      {groupAccessOpen && (
        <Modal
          isOpen={groupAccessOpen}
          onRequestClose={closeModal}
          className="reservation-modal"
          overlayClassName="confirmation-modal-overlay"
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
        >
          <div className="group-access-body">
            <h3 className="reservation-modal-title" data-testid="group-modal-title">{context} Group</h3>
            <ul className="reservation-fields-list">
              <li>
                <div className="reservation-form-fields">
                  <label className="reservation-form-label-text"> Company: </label>
                  {context === "New" ? (
                    <input
                      type="text"
                      name="company"
                      data-testid="company"
                      aria-label="Company"
                      value={formValues.company}
                      onChange={handleChange}
                      className="reservation-modal-input"
                    />
                  ) : (
                    <h4>{formValues.company}</h4>
                  )}
                </div>
              </li>
              <li>
                <div className="reservation-form-fields">
                  <label className="reservation-form-label-text"> Domain: </label>
                  {context === "New" ? (
                    <input
                      type="text"
                      name="domain"
                      data-testid="domain"
                      aria-label="Domain"
                      value={formValues.domain}
                      onChange={handleChange}
                      onBlur={validateDomain}
                      className="reservation-modal-input"
                    />
                  ) : (
                    <h4>{formValues.domain}</h4>
                  )}
                </div>
              </li>
              <li>
                <div className="reservation-form-fields">
                  <label className="reservation-form-label-text"> Contact Name: </label>
                  <input
                    type="text"
                    name="contactName"
                    data-testid="contactName"
                    aria-label="Contact Name"
                    value={formValues.contactName}
                    onChange={handleChange}
                    className="reservation-modal-input"
                  />
                </div>
              </li>
              <li>
                <div className="reservation-form-fields">
                  <label className="reservation-form-label-text"> Contact Email: </label>
                  <input
                    type="text"
                    name="email"
                    data-testid="email"
                    aria-label="Contact Email"
                    value={formValues.email}
                    onChange={handleChange}
                    onBlur={validateEmail}
                    className="reservation-modal-input"
                  />
                </div>
              </li>
            </ul>
            <input
              className={Object.values(formValues).every(value => value !== "") ? "submit-button" : "disabled-submit-button"}
              type="submit"
              data-testid="submit-button"
              value="Submit"
              onClick={handleSubmit}
              disabled={Object.values(formValues).every(value => value !== "") ? false : true}
            />
            <div id="error" className="error-message">
              {errorMessage && <p className="error-message"> {errorMessage}</p>}
            </div>
          </div>
          <button
            className="reservation-modal-close"
            data-testid={`reservation-modal-button-close`}
            aria-label={"Close Reservation Modal"}
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal>
      )}
    </div>
  );
};

export default GroupModal;
