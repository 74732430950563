import dayjs, { Dayjs } from "dayjs";
import { Rule } from "../types";
import { isDayInRange } from "./dateHelper";
import restHelpers, { API } from "./restHelpers";
import utc from "dayjs/plugin/utc.js";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export interface AvailabilityGraphData {
  reservationTime: string;
  HDAReportArrivals?: number;
  availableReservation: number;
  reservedReservations: number;
  reservationLimit: number;
}

// ******************************************************************************************
// get the reservation limits for any hour
const applyRules = (
  day: string,
  hour: string,
  rules: Rule[],
  eventStartDateTime?: string,
  eventEndDateTime?: string
): string => {
  let ruleSpec = "-";
  hour = hour.slice(0, 2) + "00Z";

  // ----------------------------------------------------
  const qSpec = (quarter: number | undefined): string =>
    quarter === undefined || quarter === 0 ? "-" : quarter!.toString();

  // ----------------------------------------------------
  const rSpec = (spec: string): string => {
    // this complexity is for the case that rule starts or ends
    // on the first or last hour of the event but falls partly outside the event date and time range.
    // those outside quarters should be marked by "-". [FLYR-248]
    let newSpec = spec;

    if (spec === "-") {
      return spec;
    }

    const requestedHourjs = dayjs.utc(day + " " + hour, "MM/DD/YY HHmm[Z]");
    if (eventStartDateTime) {
      const eventStartDayjs = dayjs.utc(eventStartDateTime, "MMMM DD, YYYY HHmm[Z]");
      const eventStartHourjs = dayjs.utc(eventStartDayjs.format("MM/DD/YYYY HH[00]"), "MM/DD/YYYY HHmm");

      if (requestedHourjs.isBefore(eventStartHourjs)) {
        return "-";
      }

      if (requestedHourjs.isSame(eventStartHourjs)) {
        const quaters = newSpec.split("/");
        quaters.forEach((q, i) => {
          const requestedHourPlusjs = requestedHourjs.add(15 * i, "minute");
          if (requestedHourPlusjs.isBefore(eventStartDayjs)) {
            quaters[i] = "-";
          }
        });
        newSpec = `${quaters[0]}/${quaters[1]}/${quaters[2]}/${quaters[3]}`;
      }
    }

    if (eventEndDateTime) {
      const eventEndDayjs = dayjs.utc(eventEndDateTime, "MMMM DD, YYYY HHmm[Z]");
      const eventEndHourjs = dayjs.utc(eventEndDayjs.format("MM/DD/YYYY HH[00]"), "MM/DD/YYYY HHmm");

      if (requestedHourjs.isAfter(eventEndDayjs)) {
        return "-";
      }

      if (requestedHourjs.isSame(eventEndHourjs)) {
        const quaters = newSpec.split("/");
        quaters.forEach((q, i) => {
          const requestedHourPlusjs = requestedHourjs.add(15 * i, "minute");
          if (requestedHourPlusjs.isAfter(eventEndDayjs)) {
            quaters[i] = "-";
          }
        });
        newSpec = `${quaters[0]}/${quaters[1]}/${quaters[2]}/${quaters[3]}`;
      }
    }

    return newSpec;
  };

  // ----------------------------------------------------
  if (rules.length > 0) {
    for (let rule of rules) {
      const ruleHour = rule.startTime.replace(":", "").slice(0, 4) + "Z";
      if (hour !== ruleHour) {
        continue;
      }

      const ruleBeginDate = dayjs(rule.startDate).utc().format("MM/DD/YY");
      let foundDay = day === ruleBeginDate;

      if (rule.isRepeat === "Y") {
        const ruleEndDate = rule.endDate ? dayjs(rule.endDate).utc().format("MM/DD/YY") : "";
        foundDay = isDayInRange(ruleBeginDate, ruleEndDate, day, rule.occurrence.trim());
      }

      if (foundDay) {
        ruleSpec = `${qSpec(rule.q1)}/${qSpec(rule.q2)}/${qSpec(rule.q3)}/${qSpec(rule.q4)}`;
        if (ruleSpec === "-/-/-/-") {
          ruleSpec = "-";
        }

        ruleSpec = rSpec(ruleSpec);
      }
    }
  }

  return ruleSpec;
};

// ******************************************************************************************
// get reservation limit for any time
const getIntvalLimit = (day: string, time: string, rules: Rule[]): string => {
  const spec = applyRules(day, time, rules);
  const limits = spec.split("/");
  const quarter = +time.slice(2, 4) / 15;
  return limits.length === 1 ? limits[0] : limits[quarter];
};

// ******************************************************************************************
// get hourly reservation limit for any time
const getHourlyIntvalLimit = (day: string, time: string, rules: Rule[]): number => {
  const spec = applyRules(day, time, rules);
  if (spec === "-") {
    return 0;
  }

  const limits = spec.split("/");
  let totalHourlyLimit = 0;

  limits.forEach((limit) => {
    if (limit !== "-") {
      totalHourlyLimit += +limit;
    }
  });

  return totalHourlyLimit;
};

// ******************************************************************************************
// get quarterly reservation limit for any time
const getQuaterlyIntvalLimit = (day: string, time: string, rules: Rule[]): number => {
  const limit = getIntvalLimit(day, time, rules);
  const totalQuarterlyLimit = limit === "-" ? 0 : +limit;

  return totalQuarterlyLimit;
};

// ******************************************************************************************
const applyRulesToTimes = (
  requestDate: string,
  times: string[],
  rules: Rule[],
  isAdmin?: boolean,
  currentReservDate?: string,
  currentReservTime?: string
) => {
  // the three sections of times
  const timesLength = times.length / 3;
  const availableTimes = times.slice(0, timesLength);
  const activeReservations = times.slice(timesLength, timesLength * 2);
  const maxReservations = times.slice(timesLength * 2, timesLength * 3);
  const tempTimes = [...availableTimes];

  let actualAvailableTime = [] as string[];
  let isReservationDay = false;
  // if modify, current reservation date time should be available
  if (currentReservDate && currentReservTime) {
    isReservationDay = dayjs(requestDate).format("MM/DD/YYYY") === dayjs(currentReservDate).format("MM/DD/YYYY");
  }

  // fill the limit section using rules
  let totalDeleted = 0;
  for (let i = 0; i < tempTimes.length; i++) {
    const limit = getIntvalLimit(requestDate, tempTimes[i], rules);
    maxReservations[i] = limit;
    // the date and time of reservation being modified are always available;
    // "-" indicates unrestricted time slot and should be removed
    if (limit === "-" && !(isReservationDay && tempTimes[i] === currentReservTime)) {
      delete availableTimes[i];
      totalDeleted++;
    }
  }

  if (totalDeleted === tempTimes.length) {
    // return empty array if all times are gone
    return actualAvailableTime;
  }

  if (isAdmin) {
    actualAvailableTime = availableTimes.concat(activeReservations, maxReservations);
  } else {
    // return only the list of available times for PUBLIC users
    for (let i = 0; i < tempTimes.length; i++) {
      if (
        (isReservationDay && tempTimes[i] === currentReservTime) ||
        (maxReservations[i] !== "-" && parseInt(activeReservations[i]) < parseInt(maxReservations[i]))
      ) {
        actualAvailableTime.push(availableTimes[i]);
      }
    }
  }

  return actualAvailableTime;
};

// ******************************************************************************************
const applyRulesToTimesForAvailabiltyGraph = (times: string[], rules: Rule[], eventStartDate: Dayjs) => {
  // the three sections of times
  const timesLength = times.length / 3;
  const availableTimes = times.slice(0, timesLength);
  const activeReservations = times.slice(timesLength, timesLength * 2);
  const maxReservations = times.slice(timesLength * 2, timesLength * 3);

  let graphDataList = [] as AvailabilityGraphData[];

  let currentDay = dayjs(dayjs().utc().format("MM/DD/YYYY"));
  let eventStartDay = dayjs(eventStartDate.format("MM/DD/YYYY"));
  let day = eventStartDay >= currentDay ? eventStartDay : currentDay;

  availableTimes.forEach((time, index) => {

    const graphData: AvailabilityGraphData = {
      reservationTime: time,
      availableReservation: 0,
      reservedReservations: +activeReservations[index],
      reservationLimit: 0,
    };

    if (time === "0000" && index > 1) {
      day = day.add(1, "day");
    }

    if (maxReservations[index] !== "0") {
      let limit;

      if (availableTimes.length > 72) {
        limit = getQuaterlyIntvalLimit(day.format("MM/DD/YY"), time, rules);
      } else {
        limit = getHourlyIntvalLimit(day.format("MM/DD/YY"), time, rules);
      }

      maxReservations[index] = limit.toString();
      graphData.reservationLimit = limit;
      graphData.availableReservation = limit - graphData.reservedReservations;

      if (graphData.availableReservation < 0) {
        graphData.availableReservation = 0;
      }
    }

    graphDataList.push(graphData);
  });

  return graphDataList;
};

const applyRulesToTimesForAvailabiltyGraphSlotControlledReport = (times: string[], rules: Rule[], eventStartDate: Dayjs) => {
  // the three sections of times
  const timesLength = times.length / 3;
  const availableTimes = times.slice(0, timesLength);
  const activeReservations = times.slice(timesLength, timesLength * 2);
  const maxReservations = times.slice(timesLength * 2, timesLength * 3);

  let graphDataList = [] as AvailabilityGraphData[];

  let currentDay = dayjs(dayjs().utc().format("MM/DD/YYYY"));
  let eventStartDay = dayjs(eventStartDate.format("MM/DD/YYYY"));
  let day = eventStartDay >= currentDay ? eventStartDay : currentDay;

  let regex = /A(\d+)D(\d+)/;

  availableTimes.forEach((time, index) => {

    let match = activeReservations[index].match(regex);
    let arrivals;
    let departures = 0;
    if (match) {
      arrivals = parseInt(match[1]);
      departures = parseInt(match[2]);
      activeReservations[index] = String(arrivals + departures);
    }

    const graphData: AvailabilityGraphData = {
      reservationTime: time,
      HDAReportArrivals: arrivals,
      availableReservation: 0,
      reservedReservations: departures,
      reservationLimit: 0,
    };

    if (time === "0000" && index > 1) {
      day = day.add(1, "day");
    }

    if (maxReservations[index] !== "0") {
      let limit;

      if (availableTimes.length > 72) {
        limit = getQuaterlyIntvalLimit(day.format("MM/DD/YY"), time, rules);
      } else {
        limit = getHourlyIntvalLimit(day.format("MM/DD/YY"), time, rules);
      }

      maxReservations[index] = limit.toString();
      graphData.reservationLimit = limit;
      graphData.availableReservation = limit - (graphData.reservedReservations+graphData.HDAReportArrivals!);

      if (graphData.availableReservation < 0) {
        graphData.availableReservation = 0;
      }
    }

    graphDataList.push(graphData);
  });

  return graphDataList;
};

// ******************************************************************************************
// get a list of available times
const getAvailableTimes = async (
  eventName: string,
  selectedAirport: string,
  selectedType: string,
  requestDate: Date,
  currentSelectedDate?: string,
  currentSelectedTime?: string,
  isAdmin?: boolean
) => {
  const ruleUrl = encodeURI(`${API.EventAirportRules}?eventName=${eventName}&airportId=${selectedAirport}`);
  return await restHelpers
    .get(ruleUrl)
    .then(async (rules: Rule[]) => {
      var airportRules = rules;
      if (eventName !== selectedAirport) {
        airportRules = rules.filter((rule) => rule.requestType === selectedType);
      }
      const timesUrl = encodeURI(
        `${API.AvailableTimes}?airportId=${selectedAirport}&requestType=${selectedType}&date=${dayjs(
          requestDate
        ).format("MM/DD/YYYY")}&eventName=${eventName}`
      );

      return await restHelpers
        .get(timesUrl)
        .then((times: string[]) => {
          // apply rules to time slots
          const theTimes = applyRulesToTimes(
            dayjs(requestDate).format("MM/DD/YY"),
            times,
            airportRules,
            isAdmin,
            currentSelectedDate,
            currentSelectedTime
          );
          return theTimes;
        })
        .catch((timeError: any) => {
          return timeError.toString();
        });
    })
    .catch((ruleError: any) => {
      return ruleError.toString();
    });
};

const RuleHelper = {
  getAvailableTimes,
  applyRules,
  getIntvalLimit,
  applyRulesToTimes,
  applyRulesToTimesForAvailabiltyGraph,
  applyRulesToTimesForAvailabiltyGraphSlotControlledReport,
};

export default RuleHelper;
