import React from "react";
import "./datePicker.scss";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface DatePickerProps {
  setDate?: any;
  setDatePickerIsOpen?: any;
  currentSelectedDate?: any;
  startDate?: any;
  endDate?: any;
}

const DatePicker: React.FC<DatePickerProps> = ({
  setDate,
  setDatePickerIsOpen,
  currentSelectedDate,
  startDate,
  endDate,
}) => {
  const [dateValue, setDateValue] = React.useState(
    currentSelectedDate ? new Date(dayjs(currentSelectedDate).format("MM/DD/YYYY")) : new Date()
  );

  function onDateChange(nextValue: Date) {
    setDateValue(nextValue);
  }

  const selectDate = () => {
    setDatePickerIsOpen(false);
    setDate(dayjs(dateValue).format("MM/DD/YYYY"));
  };

  const closeCalendar = () => {
    setDatePickerIsOpen(false);
  };

  let today = new Date();

  const getMinDate = () => {
    if (startDate === "") {
      return undefined;
    } else if (startDate === undefined) {
      return today;
    } else {
      return new Date(startDate);
    }
  };

  const getMaxDate = () => {
    if (endDate === "") {
      return undefined;
    } else if (endDate === undefined) {
      return undefined;
    } else {
      return new Date(endDate);
    }
  };

  return (
    <div>
      <button
        className="reservation-modal-close"
        data-testid={`reservation-modal-button-close`}
        aria-label={"Close Reservation Modal"}
        onClick={closeCalendar}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <Calendar
        calendarType="US"
        onChange={onDateChange}
        value={dateValue}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        className="calendar-component-program-setup"
        minDetail="month"
        showNeighboringMonth={false}
        // next2Label=""
        // prev2Label=""
        formatShortWeekday={(_locale, date) => ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
        data-testid="calendar-component-program-setup"
      />
      <button
        className="select-calendar"
        aria-label={"Select Calendar"}
        data-testid="select-calendar"
        onClick={selectDate}
      >
        Select
      </button>
    </div>
  );
};

export default DatePicker;
