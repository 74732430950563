import React from "react";
import "./confirmGroupModal.scss";
import Modal from "react-modal";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faTimes, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export interface RemoveGroupModalProps {
  context: string;
  rowData: any;
  inputData: any;
  setInputData: any;
}

const RemoveGroupModal: React.FC<RemoveGroupModalProps> = ({ context, rowData, inputData, setInputData }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setErrorMessage("");
    setIsOpen(true);
  }

  const newAccessCode = async () => {
    var error = Boolean();
    await restHelpers
      .post(API.NewAccessCode, rowData)
      .then((json: any) => {
        let updatedDataRows = inputData.map((obj: { domain: String; }) => (obj.domain === json.domain ? json : obj));
        setInputData(updatedDataRows);
      })
      .catch((err) => {
        error = true;
        setErrorMessage("No response from the server. Please try again.");
        console.log(err);
      });

    if (error === true) {
      return false;
    } else {
      closeModal();
    }
  };

  const removeGroup = async () => {
    var error = Boolean();

    await restHelpers
      .del(`${API.RemoveGroup}?domain=${rowData.domain}`)
      .catch((err) => {
        error = true;
        setErrorMessage("No response from the server. Please try again.");
        console.log(err);
        });

    if (error === true) {
      return false;
    } else {
      let updatedDataRows = inputData.filter((obj: { domain: String; }) => obj.domain !== rowData.domain);
      setInputData(updatedDataRows);
      closeModal();
    }
  };

  return (
    <div>
      {context === "New Code" ? (
        <div onClick={openModal}>
          <FontAwesomeIcon icon={faArrowsRotate} className="new-code-icon" />
          New Code
        </div>
      ) : context === "Remove" ? (
        <div onClick={openModal}>
          <FontAwesomeIcon icon={faTimes} className="admin-cancel-icon" size="2xl" />
          Remove
        </div>
      ) : (
        <></>
      )
      }
      {modalIsOpen && (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="confirmation-modal group-modal"
            overlayClassName="confirmation-modal-overlay"
            contentLabel="Confirmation Modal"
            ariaHideApp={false}
          >
            <button
              className="confirmation-modal-close"
              data-testid={`confirmation-modal-button-close`}
              aria-label={"Close Confirmation Reservation Modal"}
              onClick={closeModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="force-reservation-div">
              <FontAwesomeIcon icon={faTriangleExclamation} className="warning-icon" />
              <div className="group-new-access-text">
                <h5 className="group-warning-message" aria-label="group-warning-message">
                  {context === "New Code" ? (
                    <span>You are about to change the group access code for {rowData.company}. This change will be effective immediately and will impact all {rowData.company} group users.</span>
                  ) : (
                    <span>You are about to remove the group access code for all {rowData.company} users. This change will be effective immediately.</span>
                  )}
                </h5>
                {context === "New Code" ? (

                <p className="group-email-notification" aria-label="group-email-notification">The new access code will be emailed to {rowData.email}.</p>
              ) : (
                <p className="confirmation-form-label-text">
                  Company: {rowData.company} <br/><br/>
                  Domain: {rowData.domain} <br/><br/>
                  Contact Name: {rowData.contactName} <br/><br/>
                  Contact Email: {rowData.email} <br/>
                </p>
              )}
                <div aria-label="new-access-question" className="group-question">
                {context === "New Code" ? (
                    <span>Are you sure you want to generate a new access code?</span>
                  ) : (
                    <span>Are you sure you want to remove this group's access?</span>
                  )}
                  </div>
              </div>
            </div>
            <div className="cancel-modal-buttons">
              <button
                className="cancel-modal-close"
                data-testid={`cancel-modal-button-close`}
                aria-label={"Close Confirmation Reservation Modal"}
                onClick={closeModal}
              >
                No
              </button>
              <button
                className="cancel-modal-confirm"
                data-testid={`cancel-modal-button-yes`}
                aria-label={"Close Confirmation Reservation Modal"}
                onClick={context ==="New Code" ? newAccessCode : removeGroup}
              >
                Yes
              </button>
            </div>
            <div id="error" className="error-message">
              {errorMessage && <p className="error-message"> {errorMessage}</p>}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default RemoveGroupModal;
