import React, { useEffect } from "react";
import "./intervalPickerComponent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface IntervalPickerProps {
  selectedTimes?: string[];
  setIntervalPickerIsOpen?: any;
  setIntervals?: any;
  minInterval?: string;
  maxInterval?: string;
}

const IntervalPickerComponent: React.FC<IntervalPickerProps> = ({
  selectedTimes,
  setIntervalPickerIsOpen,
  setIntervals,
  minInterval,
  maxInterval,
}) => {
  const [timeValues, setTimeValues] = React.useState<string[]>([]);

  const selectIntervals = () => {
    setIntervals(timeValues.sort());
    setIntervalPickerIsOpen(false);
  };

  const closeIntervalPicker = () => {
    setIntervalPickerIsOpen(false);
  };

  const onTimeChange = (event: any) => {
    if (document.getElementById(event.target.id)?.className === "available-interval-button button-color-interval") {
      document.getElementById(event.target.id)?.classList.remove("button-color-interval");

      setTimeValues(timeValues.filter((item) => item !== event.target.value));
    } else {
      document.getElementById(event.target.id)?.classList.add("button-color-interval");
      setTimeValues([...timeValues, event.target.value]);
    }
  };

  useEffect(() => {
    if (selectedTimes !== undefined) {
      setTimeValues(selectedTimes);
      selectedTimes.map((interval) => document.getElementById(interval)?.classList.add("button-color-interval"));
    }
    // eslint-disable-next-line
  }, [selectedTimes]);

  let timeIntervals: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    timeIntervals.push(`${hour.toString().padStart(2, "0").padEnd(4, "0").padEnd(5, "Z")}`);
  }

  const isAvailableInterval = (interval: string) =>
   ((!minInterval || interval >= minInterval) && (!maxInterval || interval <= maxInterval));


  return (
    <div className="available-intervals-container">
      <>
        <button
          className="reservation-modal-close"
          data-testid={`reservation-modal-button-close`}
          aria-label={"Close Modal"}
          onClick={closeIntervalPicker}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h3 className="interval-header">Reservation Hours</h3>
        <h2 className="interval-subheader">(Select all hours that apply)</h2>
        <div className="available-times-list" id="scroll">
          {timeIntervals.length > 0
            ? timeIntervals.map((time: any) => (
                <button
                  disabled={isAvailableInterval(time) ? undefined : true}
                  onClick={onTimeChange}
                  value={time}
                  name={time}
                  className={isAvailableInterval(time) ? "available-interval-button" : "unavailable-interval-button"}
                  id={time}
                  key={time}
                >
                  {time}
                </button>
              ))
            : null}
        </div>

        <button
          className="select-interval-submit"
          aria-label={"Select interval"}
          data-testid="select-interval"
          onClick={selectIntervals}
        >
          Submit
        </button>
      </>
    </div>
  );
};

export default IntervalPickerComponent;
