import React, { useEffect } from "react";
import "./calendarComponent.scss";
import Calendar from "react-calendar";
import TimePickerComponent from "../timepickercomponent/TimePickerComponent";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ForceReservationModal from "../forcereservationmodal/ForceReservationModal";
import RuleHelper from "../../../helpers/ruleHelper";
dayjs.extend(utc);

export interface CalendarProps {
  setDateTime?: any;
  setCalendarIsOpen?: any;
  currentSelectedDate?: string;
  currentSelectedTime?: string;
  selectedAirport: string;
  selectedType: string;
  isAdmin?: boolean;
  eventName: string;
  minDate?: Date;
  maxDate?: Date;
}

const CalendarComponent: React.FC<CalendarProps> = ({
  setDateTime,
  setCalendarIsOpen,
  currentSelectedDate,
  currentSelectedTime,
  selectedAirport,
  selectedType,
  isAdmin,
  eventName,
  minDate,
  maxDate,
}) => {
  const today = dayjs(dayjs().utc().format("MM/DD/YYYY")).toDate();
  const [dateValue, setDateValue] = React.useState(currentSelectedDate ? dayjs(currentSelectedDate).toDate() : (minDate? minDate: today));
  const [timeValue, setTimeValue] = React.useState("");
  const [availableTimes, setAvailableTime] = React.useState<string[]>([]);
  const [timePickerOpen, setTimePickerOpen] = React.useState(false);
  const [showModifyTime, setShowModifyTime] = React.useState(true);
  const [overLimit, setOverLimit] = React.useState(-1);
  const [forceReservationIsOpen, setForceReservationIsOpen] = React.useState(false);
  const [forceReservation, setForceReservation] = React.useState(false);

  const selectDateTime = () => {
    if (overLimit >= 0 && forceReservation === false) {
      setForceReservationIsOpen(true);
      return false;
    }
    if (
      timeValue === "" &&
      (currentSelectedTime == null ||
        currentSelectedDate !== dayjs(dateValue).utc().format("MM/DD/YYYY") ||
        showModifyTime === false)
    ) {
      return false;
    } else if (timeValue === "" && currentSelectedDate === dayjs(dateValue).utc().format("MM/DD/YYYY")) {
      setDateTime(dayjs(dateValue).utc().format("MM/DD/YYYY") + " " + currentSelectedTime);
    } else {
      setDateTime(dayjs(dateValue).utc().format("MM/DD/YYYY") + " " + timeValue);
    }
    setCalendarIsOpen(false);
  };

  function onDateChange(nextValue: Date) {
    if (timeValue !== "") {
      setShowModifyTime(false);
    }
    setTimeValue("");
    setDateValue(nextValue);
  }



  useEffect(() => {
    const fetchData = async () => {
      const goodTimes = await RuleHelper.getAvailableTimes(eventName, selectedAirport, selectedType, dateValue, currentSelectedDate, currentSelectedTime, isAdmin);
      if (typeof goodTimes === "string") {
        // error occurred
        console.log(goodTimes);
      }
      else {
        setAvailableTime(goodTimes);
        setTimePickerOpen(true);
      }
    }

    if (forceReservation === true) {
      selectDateTime();
    }

    fetchData()
    .catch(console.error);

    // eslint-disable-next-line
  }, [dateValue, selectedAirport, selectedType, currentSelectedTime, currentSelectedDate, forceReservation]);

const threeDayAwayDate = dayjs(today).add(3, "day").toDate();

  return (
    <div className="calendar-time-container">
      {timePickerOpen && (
        <>
          <div className="calendar-header">
            <h3 className="calendar-time-header">Select Date and Time</h3>
            <h3 className="selected-day" data-testid="selected-day">
              {dayjs(dateValue).utc().format("dddd, MMMM DD, YYYY")}
            </h3>
          </div>
          <hr className="horizontal-line"></hr>
          <div className="calendertime">
            <Calendar
              calendarType="US"
              onChange={onDateChange}
              value={dateValue}
              minDate={minDate? minDate: today}
              maxDate={maxDate? maxDate: threeDayAwayDate}
              className="calendar-component"
              minDetail="month"
              next2Label=""
              prev2Label=""
              formatShortWeekday={(_locale, date) => ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
              data-testid="calendar"
            />
            <div className="left-line"></div>
            <hr className="horizontal-line-mobile"></hr>
            <TimePickerComponent
              times={availableTimes}
              setTimeValue={setTimeValue}
              currentSelectedTime={
                showModifyTime === true && currentSelectedDate === dayjs(dateValue).utc().format("MM/DD/YYYY")
                  ? currentSelectedTime
                  : undefined
              }
              date={dayjs(dateValue).utc().format("YYYY/MM/DD")}
              isAdmin={isAdmin}
              setOverLimit={setOverLimit}
            />
          </div>
          <button
            className="select-calendar"
            aria-label={"Select Calendar"}
            data-testid="select-calendar"
            onClick={selectDateTime}
          >
            Select
          </button>
        </>
      )}
      {forceReservationIsOpen && (
        <ForceReservationModal
          setForceReservation={setForceReservation}
          time={timeValue}
          setForceReservationOpen={setForceReservationIsOpen}
          overLimit={overLimit}
        />
      )}
    </div>
  );
};

export default CalendarComponent;
