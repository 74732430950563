import { ReactElement } from "react";
import { ActionType, ReservationEvent, Rule, Event } from "../../../types";
import dayjs from "dayjs";
import CancelWarningModal from "../cancelwarningmodal/CancelWarningModal";
import DetailsModal from "../detailsmodal/DetailsModal";
import ReservationModal from "../newreservationmodal/ReservationModal";
import utc from "dayjs/plugin/utc.js";
dayjs.extend(utc);

interface DataRowProps {
  identifier: string;
  reservationEvent: ReservationEvent;
  index: number;
  events?: Event[];
  rules?: Rule[];
}

const DataRow = ({ identifier, reservationEvent, index, events, rules }: DataRowProps): ReactElement => {
  return (
    <tr
      className="list-view-table-row "
      data-testid={`${identifier}-table-row-${index}`}
      key={`${identifier}-row-${index}`}
    >
      <td className="list-view-table-airport-text">{reservationEvent.reservationDTO.airportId}</td>
      <td className="list-view-table-airport-text">{reservationEvent.reservationDTO.callSign}</td>
      <td className="list-view-table-airport-text" data-testid={`request-type-data-table`}>
        {reservationEvent.reservationDTO.requestType.charAt(0) +
          reservationEvent.reservationDTO.requestType.substring(1).toLowerCase()}
      </td>
      <td className="list-view-table-airport-text">
        {dayjs(reservationEvent.reservationDTO.reservDate).format("MM/DD/YYYY")}
      </td>
      <td className="list-view-table-airport-text">{reservationEvent.reservationDTO.reservTime + "Z"}</td>
      <td className="list-view-table-airport-text">{reservationEvent.reservationDTO.aircraftType}</td>
      <td className="list-view-table-airport-text">{reservationEvent.reservationDTO.reservNumb}</td>
      <td className="list-view-table-airport-text">
        {reservationEvent.reservationDTO.requestorName?.replace(/[,]+/g, " ")}
      </td>
      <td className="list-view-table-airport-text detail-button">
        <DetailsModal userEvent={{ ...reservationEvent.user, ...reservationEvent.reservationDTO }} />
      </td>
      <td className="list-view-table-airport-text">
        <ReservationModal
          actionType={ActionType.MODIFY}
          reservationEvent={reservationEvent.reservationDTO}
          isAdmin={true}
          user={reservationEvent.user}
          rules={rules}
          events={events}
        />
      </td>
      <td className="list-view-table-airport-text">
        <CancelWarningModal
          userEvent={reservationEvent}
          isAdmin={true}
          reservationEvent={reservationEvent.reservationDTO}
        />
      </td>
    </tr>
  );
};

export default DataRow;
