import React, { useEffect } from "react";
import dayjs from "dayjs";
import "./controlCenter.scss";
import { DialogAnswer, Event, Log, Rule, tableColumnSpec, TableEvent } from "../../types";
import SegmentHeader from "../../components/shared/segmentHeader/SegmentHeader";
import GenericTable from "../../components/shared/generictablecomponent/GenericTable";
import { Card } from "../../components/cards/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlusCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as NoEntryIcon } from "../../static/no_entry_icon.svg"
import { getAndApplyLogic } from "../../helpers/stateHelpers";
import restHelpers, { API } from "../../helpers/restHelpers";
import ConfirmationDialog from "../../components/shared/confirmationdialog/confirmationDialog";
import { AirportSelectOption } from "../../components/shared/airportrules/AirportRules";

export interface ControlCenterProps {
  setPlannerView?: any;
  airportOptions: AirportSelectOption[];
}

const eventColumnList: tableColumnSpec<TableEvent>[] = [
  {
    key: "eventName",
    title: "Event Name",
    cellClassName: "list-view-table-col-eventname",
  },
  {
    key: "startTimeStr",
    title: "Start",
    cellClassName: "list-view-table-col-startdate",
  },
  {
    key: "endTimeStr",
    title: "End",
    cellClassName: "list-view-table-col-enddate",
  },
  {
    key: "liveDateStr",
    title: "Go Live",
    cellClassName: "list-view-table-col-livedate",
  },
  {
    key: "eventStatus",
    title: "Status",
    cellClassName: "list-view-table-col-status",
  },
  {
    key: "statusButton",
    title: "Status-button",
    cellClassName: "list-view-table-col-status-button",
  },
];
const hdaColumnList: tableColumnSpec<TableEvent>[] = [
  {
    key: "airportName",
    title: "Airport Name",
    cellClassName: "list-view-table-col-eventname",
  },
  {
    key: "startTimeStr",
    title: "Start",
    cellClassName: "list-view-table-col-startdate",
  },
  {
    key: "endTimeStr",
    title: "End",
    cellClassName: "list-view-table-col-enddate",
  },
  {
    key: "liveDateStr",
    title: "Go Live",
    cellClassName: "list-view-table-col-livedate",
  },
  {
    key: "eventStatus",
    title: "Status",
    cellClassName: "list-view-table-col-status",
  },
  {
    key: "statusButton",
    title: "Status-button",
    cellClassName: "list-view-table-col-status-button",
  },
];

const ControlCenter: React.FC<ControlCenterProps> = ({ setPlannerView, airportOptions }) => {
  const [allEvents, setAllEvents] = React.useState<Event[]>([]);
  const [refreshEvents, setRefreshEvents] = React.useState(false);
  const [allRules, setAllRules] = React.useState<Rule[]>([]);

  const closeDialog = () => {
    setOpenChangeStatusConfirmation(hiddenDialogState);
  }
  const hiddenDialogState = {
    isOpen: false,
    title: undefined as string | undefined,
    description: <></>,
    question: undefined as JSX.Element | undefined,
    yesButonText:  undefined as string | undefined,
    noButonText:  undefined as string | undefined,
    closeHandle: closeDialog as (answer?: DialogAnswer, event?: TableEvent) => {},
    eventObj: undefined as TableEvent | undefined,
    autoDismiss: undefined as number | undefined,
    comment: undefined as string | undefined,
    width: undefined as string | undefined,
  }
  const [openChangeStatusConfirmation, setOpenChangeStatusConfirmation] = React.useState(hiddenDialogState);


  const closeModifyConfirmation = async (answer?: DialogAnswer, event?: TableEvent, comment?: string) => {
    closeDialog();
    if (answer === DialogAnswer.yes) {
      const url = encodeURI(`${API.LogEventAction}?action=Modify&eventId=${event!.id}&eventType=${event!.eventType}&comment=${comment??""}`);
      await restHelpers.post(url, null).catch(() => {
      });  
      setPlannerView!(event, event!.eventType, comment);
    }
  }

  const closeChangeStatusConfirmation = async (answer?: DialogAnswer, event?: TableEvent, comment?: string) => {
    closeDialog();
    if (answer === DialogAnswer.yes) {
      event!.comments = comment;
      await restHelpers.post(API.SetEventStatus, event)
      .then(() => {
        // secondary dialog
        setOpenChangeStatusConfirmation({
          ...hiddenDialogState,
          isOpen: true,
          description: ["In Progress", "Disabled"].includes(event!.eventStatus)
          ? (
            <>
              <div className="dialog-short-message"><FontAwesomeIcon icon={faCheck} className="content-lead-icon success-color"></FontAwesomeIcon></div>
              <div className="body-text-large success-color">&nbsp;&nbsp;&nbsp;Event Activated</div>
            </>
          )
          : (
            <>
              <div className="dialog-short-message"><NoEntryIcon className="content-noentry-icon"></NoEntryIcon></div>
              <div className="body-text-large error-color">&nbsp;&nbsp;&nbsp;Event Disabled</div>
            </>
          ),
          autoDismiss: 800,      
        });
        setRefreshEvents(toggle);
      })
      .catch(() => {
        setOpenChangeStatusConfirmation({
          ...hiddenDialogState,
          isOpen: true,
          description: 
          <>
          <div className="dialog-short-message"><FontAwesomeIcon icon={faWarning} className="content-lead-icon error-color"></FontAwesomeIcon></div>
          <div className="body-text-large error-color">&nbsp;&nbsp;&nbsp;Event Failed to&nbsp;{["In Progress", "Disabled"].includes(event!.eventStatus)? "Activate" : "Disable"}</div>,
          </>,
        });
      });
   }
  }

  function toggle(value: any) {
    return !value;
  }

  const getSubsequentEventInfo = () => {
    let getEventsUrl = `${API.Events}`;

    getAndApplyLogic(getEventsUrl, (json: Event[]) => {
      setAllEvents(() => {
        return json.map((event) => {
          return event as Event;
        });
      });
    });

    getAndApplyLogic(`${API.AllRules}`, (json: Rule[]) => {
      setAllRules(() => {
        return json.map((rule) => {
          return rule as Rule;
        });
      });
    });

  };

  useEffect(() => {
    getSubsequentEventInfo();
    // eslint-disable-next-line
  }, [refreshEvents]);

  const setEventStatus = async (event: any) => {
    const thisEvent = event as TableEvent;

    if (["In Progress", "Disabled"].includes(thisEvent.eventStatus)) {
      // activate event
      if (!thisEvent.liveDate) {        
        setOpenChangeStatusConfirmation({
          ...hiddenDialogState,
          isOpen: true,
          description: <span className="warning-color"><FontAwesomeIcon icon={faWarning} className="content-lead-icon"></FontAwesomeIcon>
            &nbsp;&nbsp;&nbsp;Unable to activate the event without a Go Live Date.</span>,
        });
        return;
      }
      if (!thisEvent.eventDesc || thisEvent.eventDesc.trim() === "") {
        setOpenChangeStatusConfirmation({
          ...hiddenDialogState,
          isOpen: true,
          description: <span className="warning-color"><FontAwesomeIcon icon={faWarning} className="content-lead-icon"></FontAwesomeIcon>
            &nbsp;&nbsp;&nbsp;Unable to activate the event without a description.</span>,
        });
        return;
      }
      if (allRules.findIndex((rule) => rule.eventName === thisEvent.eventName) < 0) {
        setOpenChangeStatusConfirmation({
          ...hiddenDialogState,
          isOpen: true,
          description: <span className="warning-color"><FontAwesomeIcon icon={faWarning} className="content-lead-icon"></FontAwesomeIcon>
            &nbsp;&nbsp;&nbsp;Unable to activate the event without defining at least one airport.</span>,
        });
        return;
      }
      setOpenChangeStatusConfirmation({
        ...hiddenDialogState,
        isOpen: true,
        description: <><div><FontAwesomeIcon icon={faWarning} className="content-lead-icon warning-color"></FontAwesomeIcon></div>
          <div className="content-lead-spacer">&nbsp;</div>
          <div className="warning-color">{thisEvent.eventName} will be visible to everyone on {dayjs(thisEvent.liveDate).utc().format("MMMM D, YYYY")}. Reservations will open {dayjs(thisEvent.startTime).utc().add(-3, "day").format("MMMM D, YYYY")}.</div></>,
        question: <div>Would you like to activate {thisEvent.eventName}?</div>,
        yesButonText: "Yes, Activate",
        noButonText: "No",
        closeHandle: closeChangeStatusConfirmation,
        eventObj: thisEvent,
        comment: "",
      });
    }
    else {
      // disable event
      setOpenChangeStatusConfirmation({
        ...hiddenDialogState,
        isOpen: true,
        description: <><div><FontAwesomeIcon icon={faWarning} className="content-lead-icon warning-color"></FontAwesomeIcon></div>
          <div>&nbsp;</div>
          <div className="warning-color">Disabling {thisEvent.eventName} will prevent users from viewing the event or creating reservations. This change will be effective immediately.</div></>,
        question: <div>Would you like to disable {thisEvent.eventName}?</div>,
        yesButonText: "Yes, Disable",
        noButonText: "No",
        closeHandle: closeChangeStatusConfirmation,
        eventObj: thisEvent,
        comment: "",
      });
    }
  };

  const editHDAEvent = (event?: TableEvent) => {
    openProgramSetup(event, "HDA");
  };

  const editSEEvent = (event?: TableEvent) => {
    openProgramSetup(event, "SE");
  };

  const openProgramSetup = (eventToEdit?: TableEvent, eventType?: string) => {
    if (eventToEdit && ["Active", "Disabled"].includes(eventToEdit.eventStatus)) {
      setOpenChangeStatusConfirmation({
        ...hiddenDialogState,
        isOpen: true,
        description: <><div><FontAwesomeIcon icon={faWarning} className="content-lead-icon warning-color"></FontAwesomeIcon></div>
          <div>&nbsp;</div>
          <div className="warning-color">You are about to modify {eventToEdit.eventName}.  Any modifications will be effective immediately.</div></>,
        question: <div>Would you like to modify {eventToEdit.eventName}?</div>,
        yesButonText: "Yes, Modify",
        noButonText: "No",
        closeHandle: closeModifyConfirmation,
        eventObj: eventToEdit,
        comment: "",
      });
    }
    else {
      // add event
      setPlannerView!(eventToEdit, eventType);
    }
  };

  const showHistory = (event: TableEvent) => {
      // retrieve history
      const url = encodeURI(`${API.EventLog}?eventId=${event.id}`);
      restHelpers.get(url).then((eventLogs: Log[]) => {
      // add detailed band for history
      const detailBand: JSX.Element = <div className="history-table-block">
      {eventLogs.length > 0 
      ?
      (<table className="history-table">
          {eventLogs.map((log, index) => {return <> <tr key={`event_log_entry${index}`}>
            <td className="history-action">{log.userAction}</td>
            <td className="history-date">{dayjs(log.createdAt).utc().format("MMMM D, YYYY HH:mm:ss[Z]")}</td>
            <td className="history-date">{log.userName}</td>
          </tr>
          <tr key={`event_log_detail${index}`}>
            <td className="history-action-empty">&nbsp;</td>
            <td colSpan={2} className="history-detail">{log.details}</td>
          </tr>
          </>
        })}        
       </table>   
      )
      :
      (<div>No Comments Found</div>)}
      </div>

      setOpenChangeStatusConfirmation({
        ...hiddenDialogState,
        isOpen: true,
        title: "Comments:",
        description: detailBand,
        width: "40rem",
      });
    });
  };

  const getAirportName = (airportId: string): string => {
    const airportOpt = airportOptions.find((ap) => ap.value === airportId);
    const airportName = airportOpt!.label;
    return airportName;
  }

  const formattedEvents: TableEvent[] = allEvents.map((myEvent) => {
    const newEvent: TableEvent = {
      ...myEvent,
      startTimeStr: "",
      endTimeStr: "",
      liveDateStr: "",
      statusButton: "",
    };
    if (myEvent.eventType === "HDA") {
      newEvent.airportName = getAirportName(myEvent.eventName);
    }
    if (!myEvent.endTime) {
      newEvent.endTimeStr = "On Going";
    } else {
      newEvent.endTimeStr = dayjs(myEvent.endTime).utc().format("MM/DD/YYYY HHmm[Z]");
    }
    newEvent.startTimeStr = dayjs(myEvent.startTime).utc().format("MM/DD/YYYY HHmm[Z]");

    // for now, blank out livedate and status
    if (myEvent.liveDate) {
      newEvent.liveDateStr = dayjs(myEvent.liveDate).utc().format("MM/DD/YYYY HHmm[Z]");
    }
    newEvent.eventStatus = myEvent.eventStatus;
    return newEvent;
  });

  const filterEvent = (evType: string): TableEvent[] =>
    formattedEvents.filter((theEvent) => theEvent.eventType === evType);
    
  return (
    <>
      <section className="event-blocks ignore-bold">
        <SegmentHeader>Slot Controlled Airports
          <div>
            <button className="new-event-button" onClick={() => editHDAEvent()}>
              <FontAwesomeIcon className="new-event-icon" data-testid="new-event-page-icon" icon={faPlusCircle} />
              New Slot Controlled Airport
            </button>
          </div>
        </SegmentHeader>
        <Card additionalClassNames="list-view-card" identifier={"controlCenterHDATableCard"}>
          <GenericTable<TableEvent>
            identifier={"ccHdaTable"}
            rowList={filterEvent("HDA")}
            columnList={hdaColumnList}
            actionHandlers={[editHDAEvent, setEventStatus, showHistory]}
          ></GenericTable>
        </Card>
      </section>
      <section className="event-blocks">
        <SegmentHeader>
          Special Events
          <div>
            <button className="new-event-button" onClick={() => editSEEvent()}>
              <FontAwesomeIcon className="new-event-icon" data-testid="new-event-page-icon" icon={faPlusCircle} />
              New Special Event
            </button>
          </div>
        </SegmentHeader>
        <Card additionalClassNames="list-view-card" identifier={"controlCenterSETableCard"}>
          <GenericTable<TableEvent>
            identifier={"ccSeTable"}
            rowList={filterEvent("SE")}
            columnList={eventColumnList}
            actionHandlers={[editSEEvent, setEventStatus, showHistory]}
          ></GenericTable>
        </Card>
      </section>
      <ConfirmationDialog 
        isOpen={openChangeStatusConfirmation.isOpen} 
        title={openChangeStatusConfirmation.title}
        description={openChangeStatusConfirmation.description} 
        question={openChangeStatusConfirmation.question}
        noButtonText={openChangeStatusConfirmation.noButonText}
        yesButtonText={openChangeStatusConfirmation.yesButonText}
        closeHandle={openChangeStatusConfirmation.closeHandle} 
        passthroughObj={openChangeStatusConfirmation.eventObj}
        autoDismissInMs={openChangeStatusConfirmation.autoDismiss}
        defaultComment={openChangeStatusConfirmation.comment}
        dialogWidth={openChangeStatusConfirmation.width}
      />            
    </>
  );
};

export default ControlCenter;
