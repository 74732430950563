import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlane, faUser, faTicket, faStamp } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Modal from "react-modal";
import { ReservationAndUserInfo } from "../../../types";
import "./detailsModal.scss";
import dayjs from "dayjs";

export interface DetailsModalProps {
  userEvent: ReservationAndUserInfo;
}

const DetailsModal: React.FC<DetailsModalProps> = ({ userEvent }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button className="details-open" onClick={openModal}>
        Details
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="details-modal"
        overlayClassName="reservation-modal-overlay"
        contentLabel="Reservation Modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
      >
        <button
          className="reservation-modal-close"
          data-testid={`reservation-modal-button-close`}
          aria-label={"Close Reservation Modal"}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="details-modal-field">
          <div className="details-header">
            <FontAwesomeIcon icon={faPlane} className="airplane-rotate" data-testid="details-modal-icon" />
            <h3>Reservation Details</h3>
          </div>
          <div className="details-modal-body">
            <div className="user-information-field">
              <FontAwesomeIcon icon={faUser} className="user-icon" />
              <div>
                <div className="details-form-fields" data-testid="details-user-name">
                  <label className="details-form-label-text">
                    {" "}
                    <b>User:</b> {userEvent?.requestorName?.replace(/[,]+/g, " ")}{" "}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-user-email">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Email:</b> {userEvent?.requestorEmail}
                  </label>
                </div>
              </div>
            </div>
            <hr className="details-horizontal-line"></hr>
            <div className="reservation-information-field">
              <FontAwesomeIcon icon={faTicket} className="ticket-icon" />
              <div>
                <div className="details-form-fields" data-testid="details-airport-id">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Airport:</b> {userEvent?.airportId}{" "}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-callsign">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Call Sign:</b> {userEvent?.callSign}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-request-type">
                  <label className="details-form-label-text">
                    {" "}
                    <b>RequestType:</b>{" "}
                    {userEvent?.requestType.charAt(0) + userEvent?.requestType.substring(1).toLowerCase()}{" "}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-date">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Date:</b> {dayjs(userEvent?.reservDate).format("MMMM D, YYYY")}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-time">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Time:</b>{" "}
                    {userEvent?.reservTime.includes("Z") ? userEvent?.reservTime : userEvent?.reservTime + "Z"}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-time">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Aircraft Type:</b> {userEvent?.aircraftType}{" "}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-request-type">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Reservation Number:</b> {userEvent?.reservNumb}
                  </label>
                </div>
              </div>
            </div>
            <hr className="details-horizontal-line"></hr>
            <div className="reservation-information-field">
              <FontAwesomeIcon icon={faStamp} className="stamp-icon" />
              <div>
                <div className="details-form-fields" data-testid="details-user-name">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Created By:</b> {userEvent?.firstName} {userEvent?.lastName}{" "}
                  </label>
                </div>
                <div className="details-form-fields" data-testid="details-user-email">
                  <label className="details-form-label-text">
                    {" "}
                    <b>Created on:</b> {dayjs(userEvent?.createdAt)?.utc().format("MMMM D, YYYY HH:mm:ss") + "Z"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DetailsModal;
