import React from "react";
//@ts-ignore
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AcceptancePage from "./pages/nonprod/acceptance/AcceptancePage";
import AppContext from "./config/AppContext";
import MainDashboardEntry from "./MainDashboardEntry";
import LandingPage from "./LandingPage";

const FlyrRouter: React.FC = () => {
  return (
    <AppContext>
      <BrowserRouter>
        <Routes>
          <Route path="/reservations" element={<MainDashboardEntry />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<LandingPage redireUrl="/reservations" />} />
          <Route path="/acceptance" element={<AcceptancePage />} />
        </Routes>
      </BrowserRouter>
    </AppContext>
  );
};

export default FlyrRouter;
