import React, { ReactElement, useEffect, useState } from "react";
import "./adminView.scss";
import SectionHeader from "../../components/SectionHeader";
import restHelpers, { API } from "../../helpers/restHelpers";
import { ReservationEvent, Event, Rule } from "../../types";
import DataTable from "../../components/shared/tablecomponent/DataTable";

export interface AdminPageProps {
  events?: Event[];
  rules?: Rule[];
}

const AdminView: React.FC<AdminPageProps> = ({ events, rules }): ReactElement => {
  const [reservations, setReservations] = useState<ReservationEvent[]>([]);
  const [eventQualifier, seteventQualifier] = React.useState({
    selectedEvent: sessionStorage.getItem("event") || (events ? events[0].eventName : ""),
    clearSearch: true,
  });
  const [validRules, setValidRules] = React.useState<Rule[]>([]);

  const getSubsequentEventInfo = () => {
    let getEventsUrl = `${API.ActiveReservations}?airportId=${eventQualifier.selectedEvent}`;

    var tempRules: Rule[] = [];
    const hashSet: Set<string> = new Set<string>();

    if (events && rules) {
      for (const event of events!) {
        hashSet.add(event.eventName);
      }
      for (const rule of rules!) {
        if (hashSet.has(rule.eventName)) {
          tempRules.push(rule);
        }
      }
    }

    setValidRules(tempRules);
    if (eventQualifier.selectedEvent !== "") {
      restHelpers
        .get(getEventsUrl)
        .then((activeReservations: ReservationEvent[]) => {
          setReservations(() => {
            return activeReservations.map((myEvent) => {
              const rule = rules?.find((x) => x.airportId === myEvent.reservationDTO.airportId);
              myEvent.reservationDTO.eventName = rule ? rule.eventName : "-";
              return myEvent;
            });
          });
        })
        .catch((error: any) => ({}));
    };
  };

  const getAirportsForEvent = (): string[] => {
    return validRules!
      .filter((rule) => rule.eventName === eventQualifier.selectedEvent)
      .map((item) => item.airportId)
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  useEffect(() => {
    if(events && events.findIndex(events => events.eventName === eventQualifier.selectedEvent)!==-1)
    {
      getSubsequentEventInfo();
      sessionStorage.setItem("event", eventQualifier.selectedEvent);
    }
    // eslint-disable-next-line
  }, [eventQualifier,events]);

  useEffect(() => {
    if(events && events.findIndex(events => events.eventName === eventQualifier.selectedEvent)===-1)
    {
      seteventQualifier(prevValues => ({...prevValues, selectedEvent: events[0].eventName}));
    }
    else if(events && events.findIndex(events => events.eventName === eventQualifier.selectedEvent)===undefined)
    {
      seteventQualifier(prevValues => ({...prevValues, selectedEvent: ""}));
    }
    // eslint-disable-next-line
  }, [events]);

  return (
    <>
      <section className="event-blocks" data-testid="admin-view">
        <SectionHeader isAdmin={true} rules={validRules} events={events}>
          Reservations
          <div className="scroll-container">
            <div className={events && events.length > 3 ? "hda-container-with-events" : "hda-container"} id="HDA-container-scroll">
              {events !== undefined && events.length > 0 && validRules !== undefined && validRules.length > 0
                ? events
                  .sort((a, b) => a.eventName.localeCompare(b.eventName))
                  .sort((a, b) => a.eventType.localeCompare(b.eventType))
                  .map((theEvent: Event) => (
                    <button
                      className={
                        eventQualifier.selectedEvent === theEvent.eventName ? "hda-button-active" : "hda-button"
                      }
                      key={`tab-button-${theEvent.eventName}`}
                      onClick={() => {
                        seteventQualifier({
                          selectedEvent: theEvent.eventName,
                          clearSearch: true,
                        });
                      }}
                    >
                      {theEvent.eventName}
                    </button>
                  ))
                : null}
            </div>
          </div>
        </SectionHeader>
        <div className="reservation-table">
        {reservations.length > 0 && eventQualifier.selectedEvent!=="" ? (
              <DataTable
              identifier="list-view"
              reservations={reservations}
              resetSearch={eventQualifier.clearSearch}
              airports={getAirportsForEvent()}              
              rules={validRules}
              events={events}
            />
          ) : (
            <p className="empty-state" data-testid="empty-state">
              No Active Reservations.
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default AdminView;
