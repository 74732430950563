import React from "react";
import { ActionType, ReservationDTO, Event, Rule } from "../../../types";
import CancelWarningModal from "../../shared/cancelwarningmodal/CancelWarningModal";
import ReservationModal from "../../shared/newreservationmodal/ReservationModal";
import { Card } from "../Card";
import "./reservationCard.scss";

export interface ReservationProps {
  reservationEvent: ReservationDTO;
  events?: Event[];
  rules?: Rule[];
}

const ReservationCard: React.FC<ReservationProps> = ({ reservationEvent, events, rules }) => {
  return (
    <div>
      <>
        <div className="main-page-container" data-testid="main-page-container">
          <Card identifier={""} cardStatus="card">
            <div className="reservation-card-container" data-testid="reservation-card-container" aria-label={`card-${reservationEvent.airportId}-${reservationEvent.requestType.toLowerCase()}`}>
              <div className="reservation-card-info small-card-info-mobile">
                <p className="mobile-card-label">Airport</p>
                <h3>{reservationEvent.airportId}</h3>
              </div>
              <div className="reservation-card-info event-mobile">
                <p className="mobile-card-label">Event</p>
                <h3>
                  {reservationEvent.eventName! === reservationEvent.airportId || reservationEvent.eventName! === "-" ? String.fromCodePoint(0x2014) : reservationEvent.eventName}
                </h3>
              </div>
              <div className="reservation-card-info small-card-info-mobile">
                <p className="mobile-card-label">Call Sign</p>
                <h3>{reservationEvent.callSign}</h3>
              </div>
              <div className="small-card-info-mobile reservation-card-info card-eol-mobile">
                <p className="mobile-card-label">Request</p>
                <h3>
                  {reservationEvent.requestType.charAt(0) + reservationEvent.requestType.substring(1).toLowerCase()}
                </h3>
              </div>
              <div className="medium-card-info-mobile reservation-card-info">
                <p className="mobile-card-label">Date</p>
                <h3>{reservationEvent.reservDate}</h3>
              </div>
              <div className="small-card-info-mobile reservation-card-info time-mobile">
                <p className="mobile-card-label">Time</p>
                <h3>{reservationEvent.reservTime}Z</h3>
              </div>
              <div className="small-card-info-mobile reservation-card-info type-mobile">
                <p className="aircraft-type">Aircraft Type</p>
                <p className="aircraft-type-tablet">Aircraft</p>
                <p className="aircraft-type-mobile mobile-card-label">Type</p>
                <h3>{reservationEvent.aircraftType}</h3>
              </div>
              <div className="long-card-info-mobile reservation-card-last-info">
                <p className="mobile-card-label">Reservation Number</p>
                <h3>{reservationEvent.reservNumb}</h3>
              </div>
              <div className="reservation-card-buttons">
                <ReservationModal
                  actionType={ActionType.MODIFY}
                  reservationEvent={reservationEvent}
                  rules={rules}
                  events={events}
                ></ReservationModal>
                <CancelWarningModal reservationEvent={reservationEvent}></CancelWarningModal>
              </div>
            </div>
          </Card>
        </div>
      </>
    </div>
  );
};

export default ReservationCard;
