import React, { useEffect, useState } from "react";
import { toggleZuluAndLocalTime } from "../../helpers/dateHelper";

const TickingClock = () => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date(Date.now()));

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date(Date.now())), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={"current-time incrementing-time"} data-testid="current-time">
      Current: {toggleZuluAndLocalTime(currentTime, false, true)}
    </div>
  );
};

export default TickingClock;
