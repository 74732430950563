import React from "react";
import { ReactComponent as Dot } from "../../static/DOT-logo-white.svg";
import "./dotLinks.scss";
import ExternalLink from "../ExternalLink";

export const DotLinks: React.FC = () => {
  return (
    <div className="dot-bar" data-testid="dot-bar">
      <ExternalLink
        className="dot-header-link"
        href="https://www.transportation.gov"
        metricLog={"Link DOT Home in Header"}
      >
        <div className="dot-logo-header-container">
          <Dot className="dot-logo-header" data-testid="dot-logo" />
          United States Department of Transportation
        </div>
      </ExternalLink>
      <div className="dot-about-links">
        <ExternalLink
          className="dot-header-link"
          href="https://www.transportation.gov/about"
          metricLog={"Link DOT About in Header"}
        >
          About DOT
        </ExternalLink>
        <ExternalLink
          className="dot-header-link"
          href="https://www.transportation.gov/our-activities"
          metricLog={"Link DOT Activities in Header"}
        >
          Our Activities
        </ExternalLink>
        <ExternalLink
          className="dot-header-link"
          href="https://www.transportation.gov/mission/areas-focus"
          metricLog={"Link Areas of Focus in Header"}
        >
          Areas of Focus
        </ExternalLink>
      </div>
    </div>
  );
};
