import jwtDecode from "jwt-decode";
import React, { useContext, useEffect } from "react";
import { UserInfoContext } from "./config/AppContext";
import InactiveLogout from "./components/InactiveLogout";
import restHelpers, { API } from "./helpers/restHelpers";
import Home from "./pages/home/Home";
import { Config, OAuth2IdToken, LoggedUser } from "./types";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const MainDashboardEntry: React.FC = () => {
  const { updateLoggedUser } = useContext(UserInfoContext);
  const [siteKey, setSiteKey] = React.useState<string>("");
  const [roleTest, setRole] = React.useState("admin");
  const [inFaaNetwork, setInFaaNetwork] = React.useState(false);

  useEffect(() => {
    const fetchRole = async () => {
      const response = await restHelpers.getResult(`${API.Role}`);
      const idToken = response.headers.get("oauth2idtoken") || "";
      const decodedToken = jwtDecode<OAuth2IdToken>(idToken);
      response.json().then((myRole) => {
        const loggedUser: LoggedUser = {
          firstName: decodedToken.FirstName,
          email: decodedToken.email,
          role: myRole.role,
          inFaaNetwork: myRole.inFaaNetwork,
        };
        updateLoggedUser(loggedUser);
        setRole(myRole.role);
        setInFaaNetwork(myRole.inFaaNetwork);
      });
    };

    const fetchConfig = async () => {
      const response = await restHelpers.getResult(`${API.GetConfig}`);
      response.json().then((myConfig: Config) => {
        console.log("recaptchaSiteKey : ", myConfig.recaptchaSiteKey);
        setSiteKey(myConfig.recaptchaSiteKey);
      });
    };
    fetchRole().catch(console.error);
    fetchConfig().catch(console.error);
    // eslint-disable-next-line
  }, []);

  return (
    <InactiveLogout>
      {siteKey && (
        <>
          {roleTest === "PUBLIC" || roleTest === "FAA" || inFaaNetwork === false ? (
            <GoogleReCaptchaProvider
              reCaptchaKey={siteKey as string}
              scriptProps={{ async: true, defer: true, appendTo: "body" }}
            >
              <Home />
            </GoogleReCaptchaProvider>
          ) : (
            <Home />
          )}
        </>
      )}
    </InactiveLogout>
  );
};
export default MainDashboardEntry;
