import React, { useEffect } from "react";
import "./reservationModal.scss";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faPen, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import restHelpers, { API } from "../../../helpers/restHelpers";
import { ReservationDTO, ActionType, UserEntity, Rule, Event, Config, ReservationReqDTO } from "../../../types";
import ConfirmationModal from "../confirmationmodal/ConfirmationModal";
import CalendarComponent from "../calendarcomponent/CalendarComponent";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import dayjs from "dayjs";
import RuleHelper from "../../../helpers/ruleHelper";
import Dropdown from "rsuite/Dropdown";
import { getAndApplyLogic } from "../../../helpers/stateHelpers";
import Select, { SingleValue, ActionMeta } from "react-select";
import ExternalLink from "../../ExternalLink";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

export interface ReservationModalProps {
  actionType?: string;
  reservationEvent?: ReservationDTO;
  isAdmin?: boolean;
  user?: UserEntity;
  rules?: Rule[];
  events?: Event[];
}
export interface AircraftSelectOption {
  label: string;
  value: string;
}

const ReservationModal: React.FC<ReservationModalProps> = ({
  actionType,
  reservationEvent,
  isAdmin,
  user,
  rules,
  events,
}) => {
  const [calendarIsOpen, setCalendarIsOpen] = React.useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = React.useState<boolean>(false);
  const [subMenu, setSubMenu] = React.useState<string>("close");
  const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
  const [requestor, setRequestor] = React.useState({ firstName: "", lastName: "", email: "" });
  const [formValues, setFormValues] = React.useState({
    airportId: "",
    requestType: "",
    callSign: "",
    aircraftType: "",
  });
  const [submitHasBeenClicked, setSubmitHasBeenClicked] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [response, setResponse] = React.useState<ReservationDTO>();
  const [dateTime, setDateTime] = React.useState<string>("");
  const [validEmail, setValidEmail] = React.useState<boolean>(true);
  const [validCallSign, setValidCallSign] = React.useState<boolean>(false);
  const [uniqueEvents, setUniqueEvents] = React.useState<string[]>([]);
  const [uniqueEventAirport, setUniqueEventAirport] = React.useState<string[]>([]);
  const [HDAAirports, setHDAAirports] = React.useState<string[]>([]);
  const [HDAEvent, setHDAEvent] = React.useState<string[]>([]);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [tokenV3, setTokenV3] = React.useState<string>("");
  const [siteKeyV2, setSiteKeyV2] = React.useState<string>("");
  const recaptchaRef = React.useRef<ReCAPTCHA>();
  const [isCaptchaExecuted, setIsCaptchaExecuted] = React.useState<boolean>(false);

  const [aircraftTypeList, setAircraftTypeList] = React.useState<AircraftSelectOption[]>([]);

  const getAirportId = () =>
    actionType === ActionType.MODIFY ? reservationEvent!.airportId! : formValues.airportId.split("(")[1].split(")")[0];

  const getEventName = () =>
    actionType === ActionType.MODIFY && reservationEvent?.eventName !== undefined
      ? reservationEvent.eventName
      : formValues.airportId.split("|")[1];

  const getMinCalendarDate = () => {
    let minDate;
    const evtName = getEventName();
    if (events && events.length > 0) {
      const todayjs = dayjs(dayjs().utc().format("MM/DD/YYYY"));
      const foundEvent = events.find((evt: Event) => evt.eventName === evtName);
      if (foundEvent !== undefined && foundEvent !== null) {
        const startDayjs = dayjs(dayjs(foundEvent.startTime).utc().format("MM/DD/YYYY"));
        if (todayjs.isBefore(startDayjs)) {
          minDate = startDayjs.toDate();
        }
      }
    }
    return minDate;
  };

  const getMaxCalendarDate = () => {
    let maxDate: Date | undefined;
    const evtName = getEventName();
    if (events && events.length > 0) {
      const threeDayjs = dayjs(dayjs().utc().format("MM/DD/YYYY")).add(3, "day");
      const foundEvent = events.find((evt: Event) => evt.eventName === evtName);
      if (foundEvent !== undefined && foundEvent !== null && foundEvent.endTime) {
        const endDayjs = dayjs(dayjs(foundEvent.endTime).utc().format("MM/DD/YYYY"));
        if (endDayjs.isBefore(threeDayjs)) {
          maxDate = endDayjs.toDate();
        }
      }
    }
    return maxDate;
  };

  const handleAiportSelect = (airportName: any) => {
    setFormValues((prevValues) => ({ ...prevValues, airportId: airportName }));
    setDateTime("");
  };

  const handleAircraftTypeChange = (newValue: SingleValue<any>, action: ActionMeta<string>) => {
    if (action.action === "select-option") {
      setFormValues((prevValues) => ({ ...prevValues, aircraftType: newValue!.value }));
    }
  };

  const filterAircraftType = (option: { label: string; }, inputValue: string) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const target = event.target.name;
    if (target === "airportId" || target === "requestType") {
      // clear the date time field when airport or request type changes
      setDateTime("");
    }
    setFormValues({ ...formValues, [target]: event.target.value });
    setRequestor({ ...requestor, [target]: event.target.value });
    if (target === "callSign") {
      const inputValue = event.target.value;
      const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");
      setFormValues({ ...formValues, [target]: alphanumericValue.toLocaleUpperCase() });
    }
  };

  const validateEmail = (event: any): void => {
    if (
      event.target.value === "" ||
      new RegExp("[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\\.)+[A-Za-z]{2,4}$").test(event.target.value)
    ) {
      setValidEmail(true);
      setErrorMessage("");
    } else {
      setValidEmail(false);
      setErrorMessage("Please enter a valid email address (ex: johndoe@gmail.com)");
    }
  };

  const validateCallSign = (event: any): void => {
    if (new RegExp("^[A-Z][A-Z0-9]{1,6}$").test(event.target.value)) {
      setValidCallSign(true);
      setErrorMessage("");
    } else {
      setValidCallSign(false);
      setErrorMessage("Please enter a valid call sign (ex: N87913X)");
    }
  };

  const validateThirtyMinuteRule = async (requestTime: string, airportId: string, requestDate: string) => {
    if (
      !(
        (actionType === ActionType.MODIFY &&
          requestTime === reservationEvent?.reservTime &&
          formValues.callSign === reservationEvent?.callSign)
      )
    ) {
      let thirtyMinuteRuleURL = `${API.Validate30MinuteRule}?date=${requestDate}&time=${requestTime}&callsign=${formValues.callSign}`;

      if (actionType === ActionType.MODIFY) {
        const tempDate = new Date();
        const hour = parseInt(reservationEvent!.reservTime.substring(0, 2), 10);
        const minute = parseInt(reservationEvent!.reservTime.substring(2, 4), 10);
        tempDate.setHours(hour, minute, 0, 0);
        const fifteenMinutesBefore = new Date(tempDate);
        fifteenMinutesBefore.setMinutes(minute - 15);
        const fifteenMinutesAfter = new Date(tempDate);
        fifteenMinutesAfter.setMinutes(minute + 15);

        const prevDate = new Date();
        const newRequestedHour = parseInt(requestTime.substring(0, 2), 10);
        const newRequestedMinute = parseInt(requestTime.substring(2, 4), 10);
        prevDate.setHours(newRequestedHour, newRequestedMinute, 0, 0);

        if (
          reservationEvent &&
          (prevDate.getTime() === fifteenMinutesBefore.getTime() ||
            prevDate.getTime() === fifteenMinutesAfter.getTime())
        ) {
          thirtyMinuteRuleURL = `${API.Validate30MinuteRule}?date=${requestDate}&time=${requestTime + reservationEvent?.reservTime
          }&callsign=${formValues.callSign}`;
        }
      }
      let validatedThirtyMinuteRule = await restHelpers.get(thirtyMinuteRuleURL);
      if (validatedThirtyMinuteRule === false) {
        return false;
      }
    }
    return true;
  };

  const getRequestorName = (firstName: string, lastName: string) => {
    if (firstName === "") {
      firstName = "Unknown";
    }

    if (lastName === "") {
      lastName = "Unknown";
    }

    return firstName + "," + lastName;
  };

  const handleSubmit = async (event: any): Promise<boolean | undefined> => {
    if (!submitHasBeenClicked) {
      setSubmitHasBeenClicked(true);
      try {

        event.preventDefault();
        let error = Boolean(false);
        let tokenV2 = "";

        if (recaptchaRef.current) {
          tokenV2 = recaptchaRef.current.getValue() as string;
          console.log("tokenV2: " + tokenV2);
        }

        if (
          (reservationEvent?.airportId == null && formValues.airportId.length === 0) ||
          (formValues.requestType.length === 0 && reservationEvent?.requestType == null) ||
          (reservationEvent?.reservDate == null && reservationEvent?.reservTime == null && dateTime.length === 0) ||
          (reservationEvent?.callSign == null && formValues.callSign.length === 0)
        ) {
          error = true;
        }

        if (validEmail === false && isAdmin === true) {
          setErrorMessage("Please enter a valid email address (ex: johndoe@gmail.com)");
          return false;
        }
        if (validCallSign === false) {
          setErrorMessage("Please enter a valid call sign (ex: N87913X)");
          return false;
        }

        if (error === true) {
          setErrorMessage("Information incomplete. Please fill out and try again.");
          return false;
        }

        const airportId = getAirportId();
        const eventName = getEventName();
        const requestDate = dateTime.split(" ")[0];
        const requestTime = dateTime.split(" ")[1];
        const validatedThirtyMinuteRule = await validateThirtyMinuteRule(requestTime, airportId, requestDate);
        if (validatedThirtyMinuteRule === false) {
          setErrorMessage("Sorry, reservations must be at least 30 minutes apart.");
          return false;
        }

        if (isAdmin !== true) {
          // recheck if the request datetime is still available
          const rdate = dayjs(requestDate).toDate();
          const goodTimes = await RuleHelper.getAvailableTimes(
            eventName,
            airportId,
            formValues.requestType,
            rdate,
            reservationEvent?.reservDate,
            reservationEvent?.reservTime,
            isAdmin
          );
          if (typeof goodTimes === "string") {
            // error occurred
            setErrorMessage("No response from the server. Please try again.");
            return false;
          } else if (!(goodTimes as string[]).includes(requestTime)) {
            setErrorMessage("Sorry, the selected time is no longer available. Please select another time.");
            return false;
          }
        }

        if (actionType === ActionType.MODIFY) {
          const reservationData: ReservationReqDTO = {
            airportId: `${reservationEvent?.airportId}`,
            requestType: `${reservationEvent?.requestType}`,
            reservDate: requestDate,
            reservTime: requestTime,
            callSign: formValues.callSign,
            aircraftType: formValues.aircraftType,
            reservNumb: `${reservationEvent?.reservNumb}`,
            requestorEmail: requestor.email,
            requestorName: requestor.firstName + "," + requestor.lastName,
            tokenV3: tokenV3,
            tokenV2: tokenV2,
          };

          if (formValues.callSign === "") {
            reservationData.callSign = reservationEvent?.callSign!;
          }

          if (dateTime === "") {
            reservationData.reservDate = reservationEvent?.reservDate!;
            reservationData.reservTime = reservationEvent?.reservTime!;
          }
          await restHelpers
            .post(API.ModifyReservation, reservationData)
            .then((json: any) => {
              setResponse(json);
            })
            .catch(() => {
              error = true;
              setErrorMessage("Sorry, there was an error processing your reservation. Please try again.");
            });
        } else {
          // new reservation
          const reservationData: ReservationReqDTO = {
            airportId: airportId,
            requestType: formValues.requestType,
            reservDate: requestDate,
            reservTime: requestTime,
            callSign: formValues.callSign,
            aircraftType: formValues.aircraftType,
            requestorEmail: requestor.email === "" ? "Unknown" : requestor.email,
            requestorName: getRequestorName(requestor.firstName, requestor.lastName),
            reservNumb: "",
            tokenV3: tokenV3,
            tokenV2: tokenV2,
          };
          await restHelpers
            .post(API.CreateReservation, reservationData)
            .then((json: any) => {
              setResponse(json);
            })
            .catch(() => {
              setErrorMessage("Sorry, there was an error processing your reservation. Please try again.");
              error = true;
            });
        }
        if (error === Boolean(true)) {
          return false;
        } else {
          setIsConfirmed(true);
        }
      }
      finally {
        setSubmitHasBeenClicked(false)
      }
    }
  };

  function openModal() {
    setFormValues({ airportId: "", requestType: "", callSign: "", aircraftType: "" });
    setRequestor({ firstName: "", lastName: "", email: "" });
    setDateTime("");

    let uniqueEvents = rules!
      .map((item) => item.eventName)
      .filter((value, index, self) => self.indexOf(value) === index);
    const uniqueTags: string[] = [];
    const tempEvents: string[] = [];
    const tempHDAAirport: string[] = [];
    const tempHDAEvent: string[] = [];
    rules!.map((rule) => {
      if (uniqueTags.indexOf(rule.airportName + " (" + rule.airportId + ")|" + rule.eventName) === -1) {
        if (rule.eventName === rule.airportId) {
          // HDA
          uniqueEvents.splice(uniqueEvents.indexOf(rule.airportId), 1);
          tempEvents.unshift(rule.airportId);
          uniqueTags.unshift(rule.airportName + " (" + rule.airportId + ")|" + rule.eventName);
          tempHDAAirport.unshift(rule.airportName + " (" + rule.airportId + ")");
          tempHDAEvent.unshift("(" + rule.airportId + ")|" + rule.eventName);
        } else {
          // SE
          uniqueTags.push(rule.airportName + " (" + rule.airportId + ")|" + rule.eventName);
        }
      }
      return null;
    });
    uniqueEvents = tempEvents.sort().concat(uniqueEvents.sort());
    setUniqueEvents(uniqueEvents);
    setHDAEvent(tempHDAEvent);
    setHDAAirports(tempHDAAirport.sort());
    setUniqueEventAirport(uniqueTags.sort());

    if (actionType === ActionType.MODIFY) {
      setRequestor({
        firstName: `${reservationEvent?.requestorName?.split(",")[0]}`,
        lastName: `${reservationEvent?.requestorName?.split(",")[1]}`,
        email: `${reservationEvent?.requestorEmail}`,
      });
      setFormValues({
        airportId: uniqueTags.find((ea) => ea.includes("(" + reservationEvent!.airportId + ")")) ?? "",
        requestType: `${reservationEvent?.requestType}`,
        callSign: `${reservationEvent?.callSign}`,
        aircraftType: `${reservationEvent?.aircraftType}`,
      });
      setValidEmail(true);
      setValidCallSign(true);
      setDateTime(`${reservationEvent?.reservDate} ${reservationEvent?.reservTime}`);
    }
    setCalendarIsOpen(false);
    setIsOpen(true);
    setErrorMessage("");

    const aircraftURL = encodeURI(`${API.AllAircraftTypes}`);
    getAndApplyLogic(aircraftURL, (jsonObj: string[]) => {
      const options = jsonObj.map((aircraftType: string) => {
        return {
          label: aircraftType,
          value: aircraftType,
        };
      });
      options.unshift({ label: "", value: "" });
      setAircraftTypeList(options);
    });
  }

  function openSubMenu() {
    if (subMenu === "open") {
      setSubMenu("close");
    } else {
      setSubMenu("open");
    }
  }

  function closeSubMenu() {
    setSubMenu("close");
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeCalendarModal() {
    setCalendarIsOpen(false);
  }

  function openDateTime() {
    setErrorMessage("");
    setCalendarIsOpen(true);
  }

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) return;
    if (isCaptchaExecuted || isAdmin) return;

    await executeRecaptcha("reserv").then((token) => setTokenV3(token));
    // console.log("ReCAPTCHA TOKEN UI::::" + token);
    setIsCaptchaExecuted(true);
  };

  const fetchConfig = async () => {
    if (siteKeyV2 === "" && modalIsOpen) {
      const response = await restHelpers.getResult(`${API.GetConfig}`);
      response.json().then((myConfig: Config) => {
        console.log("recaptchaSiteKey : ", myConfig.recaptchaSiteKey);
        setSiteKeyV2(myConfig.recaptchaV2SiteKey);
      });
    }
  };

  useEffect(() => {
    fetchConfig().catch(console.error);

    handleReCaptchaVerify();
  });

  return (
    <div>
      {/* remove zoom from mobile */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      {isConfirmed && <ConfirmationModal reservationEvent={response} actionType={actionType} isAdmin={isAdmin} />}
      <button
        className={
          isAdmin === true
            ? actionType?.toLowerCase() + "-admin-reservation-button"
            : actionType?.toLowerCase() + "-reservation-button"
        }
        data-testid={actionType?.toLowerCase() + "-reservation-button"}
        onClick={openModal}
        id={
          actionType === ActionType.MODIFY
            ? actionType?.toLowerCase() +
            "-reservation-button-" +
            reservationEvent?.airportId +
            "-" +
            reservationEvent?.requestType +
            "-" +
            reservationEvent?.callSign
            : actionType + " Reservation"
        }
      // aria-label={actionType?.toLowerCase() + "-reservation-button-" + reservationEvent?.airportId + "-" + reservationEvent?.requestType + "-" + reservationEvent?.callSign}
      >
        {actionType === ActionType.NEW && (
          <FontAwesomeIcon className="new-icon" data-testid="new-reservation-modal-icon" icon={faPlusCircle} />
        )}
        {actionType === ActionType.MODIFY && isAdmin === true && (
          <FontAwesomeIcon className="modify-admin-icon" icon={faPen} />
        )}
        {actionType}
        {actionType === ActionType.NEW && <div className="reservation-label">&nbsp; Reservation</div>}
      </button>
      {!isConfirmed && (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="reservation-modal"
            overlayClassName="reservation-modal-overlay"
            contentLabel="Reservation Modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={false}
          >
            {!calendarIsOpen && (
              <div className="modal-body">
                <h3 className="reservation-modal-title">{actionType} Reservation</h3>
                <form
                  className="reservation-form-container"
                  data-testid="reservation-form-container"
                  data-sitekey="reCAPTCHA_site_key"
                  onSubmit={handleSubmit}
                >
                  <ul className="reservation-fields-list">
                    {isAdmin && (
                      <>
                        <li>
                          <div className="reservation-form-fields">
                            <label className="reservation-form-label-text"> First Name:</label>
                            {actionType === ActionType.MODIFY && reservationEvent?.requestorEmail === user?.email ? (
                              <h4>{reservationEvent?.requestorName?.split(",")[0]}</h4>
                            ) : (
                              <input
                                type="text"
                                name="firstName"
                                data-testid="firstName"
                                aria-label="firstName"
                                value={requestor.firstName}
                                onChange={handleChange}
                                className="reservation-modal-input"
                              />
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="reservation-form-fields">
                            <label className="reservation-form-label-text"> Last Name:</label>
                            {actionType === ActionType.MODIFY && reservationEvent?.requestorEmail === user?.email ? (
                              <h4>{reservationEvent?.requestorName?.split(",")[1]}</h4>
                            ) : (
                              <input
                                type="text"
                                name="lastName"
                                data-testid="lastName"
                                aria-label="lastName"
                                value={requestor.lastName}
                                onChange={handleChange}
                                className="reservation-modal-input"
                              />
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="reservation-form-fields">
                            <label className="reservation-form-label-text"> Email:</label>
                            {actionType === ActionType.MODIFY && reservationEvent?.requestorEmail === user?.email ? (
                              <h4>{reservationEvent?.requestorEmail}</h4>
                            ) : (
                              <input
                                type="text"
                                name="email"
                                data-testid="email"
                                aria-label="email"
                                value={requestor.email}
                                onChange={handleChange}
                                onBlur={validateEmail}
                                className="reservation-modal-input"
                              />
                            )}
                          </div>
                        </li>
                      </>
                    )}
                    <li>
                      <div className="reservation-form-fields">
                        <label className="reservation-form-label-text"> Airport:</label>
                        {actionType === ActionType.MODIFY ? (
                          <h4>{formValues.airportId.split("|")[0]}</h4>
                        ) : (
                          <div onChange={handleChange} className="airport-input-div">
                            <Dropdown
                              title={formValues.airportId.split("|")[0]}
                              placement="bottomStart"
                              aria-label="airportId"
                              activeKey={formValues.airportId}
                            >
                              {uniqueEvents && uniqueEvents.length > 0
                                ? uniqueEvents.map((eventName: string, index) => (
                                  <React.Fragment key={`rfrag-${eventName}`}>
                                    {uniqueEventAirport.length > 0 &&
                                      HDAEvent.includes("(" + eventName + ")|" + eventName) ? (
                                      <Dropdown.Item
                                        onSelect={handleAiportSelect}
                                        eventKey={
                                          HDAAirports[index] + "|" + HDAAirports[index].split("(")[1].split(")")[0]
                                        }
                                        aria-label={eventName}
                                      >
                                        {HDAAirports[index]}
                                      </Dropdown.Item>
                                    ) : (
                                      <div onClick={openSubMenu} onMouseLeave={closeSubMenu} className="submenu">
                                        <Dropdown.Menu
                                          title={eventName}
                                          className={`${subMenu}`}
                                          aria-label={eventName}
                                        >
                                          {uniqueEventAirport.length > 0 &&
                                            uniqueEventAirport.includes(eventName + ")|" + eventName) === false &&
                                            uniqueEventAirport.map((airport: string) => {
                                              return airport.includes(eventName) ? (
                                                <Dropdown.Item
                                                  onSelect={handleAiportSelect}
                                                  eventKey={airport}
                                                  id={airport.split("|")[0]}
                                                  aria-label={airport.split("|")[0]}
                                                >
                                                  {airport.split("|")[0]}
                                                </Dropdown.Item>
                                              ) : null;
                                            })}
                                        </Dropdown.Menu>
                                      </div>
                                    )}
                                  </React.Fragment>
                                ))
                                : null}
                            </Dropdown>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="reservation-form-fields" onChange={handleChange}>
                        <label className="reservation-form-label-text"> Type of Request:</label>
                        {actionType === ActionType.MODIFY ? (
                          <h4>
                            {reservationEvent?.requestType.charAt(0)! +
                              reservationEvent?.requestType.substring(1).toLowerCase()}
                          </h4>
                        ) : (
                          <div className="radio-button-container">
                            <label className="reservation-radio-button">
                              <input
                                type="radio"
                                name="requestType"
                                data-testid="arrivalRequestType"
                                value="ARRIVAL"
                                defaultChecked={formValues.requestType === "ARRIVAL"}
                              />{" "}
                              Arrival
                            </label>
                            <label className="reservation-radio-button">
                              <input
                                type="radio"
                                name="requestType"
                                data-testid="departureRequestType"
                                value="DEPARTURE"
                                defaultChecked={formValues.requestType === "DEPARTURE"}
                              />{" "}
                              Departure
                            </label>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="reservation-form-fields">
                        <label className="reservation-form-label-text"> Date/Time:</label>
                        {formValues.requestType === "" || formValues.airportId === "" ? (
                          <div className="disabled-datetime-input-button">
                            <FontAwesomeIcon icon={faCalendarDays} className="disabled-open-calendar" />
                          </div>
                        ) : (
                          <div className="datetime-input-button">
                            {actionType === ActionType.MODIFY ? (
                              <input
                                type="text"
                                name="dateTime"
                                onClick={openDateTime}
                                className="reservation-setup-date-pointer"
                                data-testid="dateTime"
                                placeholder={
                                  reservationEvent?.reservDate +
                                  " " +
                                  reservationEvent?.reservTime +
                                  (reservationEvent?.reservDate ? "Z" : "")
                                }
                                id="date-time-input"
                                value={dateTime + (dateTime ? "Z" : "")}
                                onChange={() => { }}
                              />
                            ) : (
                              <>
                                <input
                                  type="text"
                                  onClick={openDateTime}
                                  className="reservation-setup-date-pointer"
                                  name="dateTime"
                                  data-testid="dateTime"
                                  value={dateTime + (dateTime ? "Z" : "")}
                                  onChange={() => { }}
                                />
                              </>
                            )}
                            <button
                              className="open-calendar"
                              aria-label={"open-calendar"}
                              data-testid={"open-calendar"}
                              onClick={openDateTime}
                              type="button"
                            >
                              <FontAwesomeIcon icon={faCalendarDays} />
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="reservation-form-fields">
                        <label className="reservation-form-label-text"> Aircraft Call Sign:</label>
                        <input
                          type="text"
                          name="callSign"
                          data-testid="callSign"
                          aria-label="callSign"
                          value={formValues.callSign}
                          onChange={handleChange}
                          onBlur={validateCallSign}
                          className="reservation-modal-input reservation-callsign-input"
                          user-scalable="no"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="reservation-form-fields">
                        <label className="reservation-form-label-text"> Aircraft Type:</label>
                        <Select
                          className="aircraft-name-dropdown"
                          name="aircraftType"
                          aria-label="aircraftType"
                          data-testid="aircraftType"
                          options={aircraftTypeList as any}
                          onChange={handleAircraftTypeChange}
                          isClearable={false}
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          placeholder={formValues.aircraftType}
                          defaultValue={formValues.aircraftType}
                          filterOption={filterAircraftType}
                          user-scalable="no"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: "#707070",
                              borderWidth: 2,
                              backgroundColor: "#f0f0f0",
                              minHeight: 32,
                              height: 32,
                              textAlign: "left",
                              cursor: "pointer",
                            }),
                            menu: (baseStyles) => ({
                              ...baseStyles,
                              textIndent: "0rem",
                              fontSize: "0.83rem",
                              cursor: "pointer",
                              textAlign: "left",
                            }),
                            valueContainer: (baseStyles) => ({
                              ...baseStyles,
                              fontSize: "0.83rem",
                              textAlign: "left",
                              padding: "0.2rem 1.2rem 0.4rem 0.4rem",
                            }),
                            input: (baseStyles) => ({
                              ...baseStyles,
                              textAlign: "left",
                              justifyContent: "left",
                            }),
                            placeholder: (baseStyles) => ({
                              ...baseStyles,
                              color: "black",
                            }),
                          }}
                        />
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 21 32"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          className="rs-dropdown-toggle-caret rs-icon aircraft-dropdown-icon"
                          aria-label="angle down"
                          data-category="legacy"
                        >
                          <path d="M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z"></path>
                        </svg>
                        <div>
                          <ExternalLink
                            href={"https://www.faa.gov/documentLibrary/media/Order/2024-04-29_FAA_Order_JO_7360.1J_Aircraft_Type_Designators--post.pdf"}
                            testId={"aircraft-pdf-info-link"}
                            aria-hidden="false"
                            aria-label="Aircraft Type PDF Info Clickable Link"
                            openInNewTab={true}
                          >
                            <div >
                              <FontAwesomeIcon icon={faCircleQuestion} className={
                                isAdmin === true
                                  ? "admin-circle-question-icon"
                                  : "circle-question-icon"
                              } />
                            </div>
                          </ExternalLink>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div id="error" className="error-message">
                    {errorMessage && <p className="error-message"> {errorMessage}</p>}
                  </div>
                  {!isAdmin && (
                    <div>
                      {siteKeyV2 && (
                        <ReCAPTCHA
                          data-testid="recaptcha-v2"
                          ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                          sitekey={siteKeyV2}
                        />
                      )}
                    </div>
                  )}
                  {isAdmin ? (
                    <input
                      className={
                        (dateTime === "" && reservationEvent?.reservDate === undefined) ||
                          formValues.callSign === "" ||
                          formValues.aircraftType === ""
                          ? "disabled-submit-button"
                          : "submit-button"
                      }
                      type="submit"
                      data-testid="submit-button"
                      value="Submit"
                      disabled={
                        (dateTime === "" && reservationEvent?.reservDate === undefined) ||
                          formValues.callSign === "" ||
                          formValues.aircraftType === ""
                          ? true
                          : false
                      }
                    />
                  ) : (
                    // onKeyDown={(e)=>{e.preventDefault();}}
                    <input
                      className={
                        (dateTime === "" && reservationEvent?.reservDate === undefined) ||
                          formValues.callSign === "" ||
                          formValues.aircraftType === ""
                          ? "disabled-submit-button"
                          : "submit-button"
                      }
                      type="submit"
                      data-testid="submit-button"
                      value="Submit"
                      disabled={
                        (dateTime === "" && reservationEvent?.reservDate === undefined) ||
                          formValues.callSign === "" ||
                          formValues.aircraftType === "" ||
                          submitHasBeenClicked
                          ? true
                          : false
                      }
                    />
                  )}
                </form>
              </div>
            )}

            {calendarIsOpen && (
              <CalendarComponent
                setDateTime={setDateTime}
                setCalendarIsOpen={setCalendarIsOpen}
                currentSelectedDate={reservationEvent?.reservDate}
                currentSelectedTime={reservationEvent?.reservTime}
                selectedAirport={getAirportId()}
                selectedType={formValues.requestType}
                isAdmin={isAdmin}
                eventName={getEventName()}
                minDate={getMinCalendarDate()}
                maxDate={getMaxCalendarDate()}
              />
            )}
            <button
              className="reservation-modal-close"
              data-testid={`reservation-modal-button-close`}
              aria-label={"Close Reservation Modal"}
              onClick={calendarIsOpen === true ? closeCalendarModal : closeModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ReservationModal;
