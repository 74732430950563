import { ReactNode } from "react";
import "./segmentHeader.scss";

const SegmentHeader = (props: { children: ReactNode }) => {
  const children = props.children;

  return (
    <div>
      <h2 className="section-header" data-testid="section-header">
        {children}
      </h2>
    </div>
  );
};

export default SegmentHeader;
