import React from "react";
import "./beta-banner.scss";
import { ReactComponent as PlaneDepartureIcon } from "../../static/Plane-departure-icon.svg";

export const BetaBanner: React.FC = () => (
  <section className="beta-banner" data-testid="beta-banner">
    <PlaneDepartureIcon className="plane-departure-icon" />
    <div className="beta-banner-span">
      <span> Welcome to FLYR! </span>
    </div>
  </section>
);

export default BetaBanner;
