import React from "react";
import "./card.scss";

interface CardProps {
  identifier: string;
  children: React.ReactNode;
  additionalClassNames?: string;
  cardStatus?: string;
}

export const Card: React.FC<CardProps> = ({ children, identifier, additionalClassNames = "", cardStatus }) => (
  <div className={"card " + additionalClassNames} data-testid={"card-" + identifier}>
    {cardStatus === "card" && <section className="status-banner" data-testid="status-banner" />}
    {children}
  </div>
);
