import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "./config/AppContext";
import InactiveLogout from "./components/InactiveLogout";
import restHelpers, { API } from "./helpers/restHelpers";
import Landing from "./pages/landing/landing";
import { LoggedUser, OAuth2IdToken } from "./types";

export interface LandingProps {
  redireUrl?: string;
}

const LandingPage: React.FC<LandingProps> = ({ redireUrl }) => {
  const { loggedUser, updateLoggedUser } = useContext(UserInfoContext);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const isLoggedin = () => localStorage.getItem("loggedin") === "true";

  useEffect(() => {
    const fetchRole = async () => {
      const response = await restHelpers.getResult(`${API.Role}`);
      const idToken = response.headers.get("oauth2idtoken") || "";
      const decodedToken = jwtDecode<OAuth2IdToken>(idToken);
      response.json().then((myRole) => {
        const loggedUser: LoggedUser = {
          firstName: decodedToken.FirstName,
          email: decodedToken.email,
          role: myRole.role,
          inFaaNetwork: myRole.inFaaNetwork,
        };
        if (isLoggedin() === false ) {
          restHelpers.post(`${API.LogLogIn}`,null);
        }
        updateLoggedUser(loggedUser);
        setUserName(decodedToken.FirstName);
        localStorage.setItem("loggedin", "true");
      });
    };

    if (isLoggedin() && !loggedUser.firstName) {
      // for opening another tab, etc where OKTA is logged in
      // but user context is not available
      restHelpers.post(`${API.LogLogIn}`,null);
      fetchRole().catch();
      return;
    }

    if (redireUrl && redireUrl !== "") {
      // for sign in link
      fetchRole().catch();
      navigate(redireUrl);
    }

    // eslint-disable-next-line
  }, [userName]);

  return redireUrl && redireUrl !== "" ? (
    // empty page before redirect
    <></>
  ) : loggedUser.firstName ? (
    <InactiveLogout>
      <Landing />
    </InactiveLogout>
  ) : (
    <Landing />
  );
};
export default LandingPage;

