import React, { useEffect, useRef } from "react";
import "./availabilityGraph.scss";
import { API } from "../../../helpers/restHelpers";
import { getAndApplyLogic } from "../../../helpers/stateHelpers";
import { Rule } from "../../../types";
import RuleHelper, { AvailabilityGraphData } from "../../../helpers/ruleHelper";
import dayjs from "dayjs";
import { BarChart, XAxis, YAxis, Tooltip, Bar, Cell } from "recharts";

export interface AvailabilityGraphProps {
  selectedAirport: string;
  requestType: string;
  eventName: string;
  rules: Rule[];
  eventStartTime?: Date;
  isSubmitted?: boolean;
  startDate?: string;
  endDate?: string;
  context?: string;
}

const AvailabilityGraph: React.FC<AvailabilityGraphProps> = ({
  selectedAirport,
  requestType,
  eventName,
  rules,
  eventStartTime,
  isSubmitted,
  startDate,
  endDate,
  context,
}) => {
  const [dataList, setDataList] = React.useState<AvailabilityGraphData[]>([]);
  const prevX = useRef(0);
  const lastTickMark = useRef(0);
  const eventStartDate = dayjs(eventStartTime).utc();
  const currentDate = useRef(startDate ? dayjs(startDate) : dayjs());
  const graphStartDate = useRef((eventStartDate >= currentDate.current && context !== "reports") ? eventStartDate : currentDate.current);

  const RenderDayTick = (tickProps: { x: any; y: any; payload: any; }) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const tempLastTickMark = lastTickMark.current;
    const tempCurrentDate = graphStartDate.current;

    lastTickMark.current = lastTickMark.current + 1;

    if (
      (value === "2300" && eventName === selectedAirport) ||
      (value === "2345" && eventName !== selectedAirport) ||
      tempLastTickMark === dataList.length - 1
    ) {
      const tempX = prevX.current;
      prevX.current = x;
      graphStartDate.current = graphStartDate.current.add(1, "day");
      return (
        <>
          <text x={(tempX + x) / 2 + offset} y={y} dy={16} textAnchor="middle">
            {tempCurrentDate.format("MMMM DD, YYYY")}
          </text>

          {((value === "2300" && eventName === selectedAirport) ||
            (value === "2345" && eventName !== selectedAirport)) && (
              <path d={`M${x + offset},${y - 39}v${+40}`} stroke="black" />
            )}
        </>
      );
    }
    return <div></div>;
  };

  const renderTooltip = (tickProps: { active?: any; payload?: any; label?: any }) => {
    const { active, payload, label } = tickProps;
    if (active && payload && payload.length) {
      return (
        <>
          {payload[0].value === 0 && payload[1].value === 0 ? (
            <></>
          ) : (context === "reports" && eventName === selectedAirport) ? (
            <div className="custom-tooltip" aria-label="Reserved popup">

            <p>
            <p className="label-reserved">{`Arrivals: ${payload[0].value}`}</p>
            <p className="label-departures">{`Departures: ${payload[1].value}`}</p>
            <p className="label-available">{`Available: ${payload[2].value}`}</p>

          </p>
          </div>
            ) : (
            <div className="custom-tooltip" aria-label="Reserved popup">
              <p className="label">{`${label}Z`}</p>
              {payload[1].value === 0 ? (
                <p className="label-full">{`Reserved: ${payload[0].value}`}</p>
              ) : (
                <p>
                  <p className="label-reserved">{`Reserved: ${payload[0].value}`}</p>
                  <p className="label-available">{`Available: ${payload[1].value}`}</p>
                </p>
              )}
            </div>
          )}
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!(selectedAirport === "" || requestType === "")) {
      const airportRules = rules
        .filter((rule) => {
          if (requestType === "Arrival-Departure") {
            return rule.airportId === selectedAirport && rule.eventName === eventName;
          } else
            return (
              rule.airportId === selectedAirport &&
              rule.eventName === eventName &&
              rule.requestType === requestType.toUpperCase()
            );
        })
        .sort((a, b) => dayjs(a.createdAt).diff(b.createdAt));

      if (context === "reports") {
        getAndApplyLogic(`${API.AvailabilityGraphTimes}?airportId=${selectedAirport}&requestType=${requestType}&eventName=${eventName}&context=reports&startDate=${startDate}&endDate=${endDate}`, (json: string[]) => {
          if (eventName !== selectedAirport) {
            const graphDataList = RuleHelper.applyRulesToTimesForAvailabiltyGraph(json, airportRules, eventStartDate);
            setDataList(graphDataList);
          }
          else {
            const graphDataList = RuleHelper.applyRulesToTimesForAvailabiltyGraphSlotControlledReport(json, airportRules, eventStartDate);
            setDataList(graphDataList);
          }
        });
      }
      else {
        getAndApplyLogic(`${API.AvailabilityGraphTimes}?airportId=${selectedAirport}&requestType=${requestType}&eventName=${eventName}`, (json: string[]) => {
          const graphDataList = RuleHelper.applyRulesToTimesForAvailabiltyGraph(json, airportRules, eventStartDate);
          setDataList(graphDataList);
        });
      }
    }

    // eslint-disable-next-line
  }, [selectedAirport, requestType, isSubmitted]);


  prevX.current = 0;
  lastTickMark.current = 0;
  graphStartDate.current =
    (eventStartDate >= currentDate.current && context !== "reports")
      ? dayjs(dayjs(eventStartTime).utc().format().replace("Z", ""))
      : dayjs(dayjs(startDate).utc().format().replace("Z", ""));
  return (
    <>
      <div className="availability-graph" data-testid={selectedAirport + "-barchart"}>
        <BarChart
          width={dataList.length * 20}
          height={300}
          data={dataList}
          margin={{
            top: 5,
            right: 30,
            left: 20,
          }}
        >
          <XAxis dataKey="reservationTime" type="category" interval={3} />
          <XAxis
            dataKey="reservationTime"
            height={50}
            interval={0}
            axisLine={false}
            tick={RenderDayTick}
            tickLine={false}
            xAxisId="dayTickLine"
          />
          <YAxis allowDecimals={false} type="number" domain={[0, "dataMax"]} />
          <Tooltip content={renderTooltip} />
          {(context === "reports" && eventName === selectedAirport) &&
            <Bar dataKey="HDAReportArrivals" stackId="a" fill="orange" name="HDAReportArrivals" strokeWidth={1} stroke={"black"} barSize={30} id="HDAReportArrivals" />
          }
          <Bar
            dataKey="reservedReservations"
            fill="orange"
            stackId="a"
            name="Reserved"
            strokeWidth={1}
            stroke={"black"}
            id="reserved"
          >
            {dataList.map((entry: AvailabilityGraphData, index) => {
              if (context === "reports" && eventName === selectedAirport) {
                return (<Cell key={`cell-${index}`} fill="#AAAAAA" />
                )
              } else if (dataList[index].availableReservation === 0) {
                return (<Cell key={`cell-${index}`} fill="#B84040" />
                )
              } else {
                return (<Cell key={`cell-${index}`} fill="orange" />
                )
              }
            })};
          </Bar>
          <Bar dataKey="availableReservation" stackId="a" fill="white" name="Available" strokeWidth={1} stroke={"black"} barSize={30} id="available" />
        </BarChart>
      </div>
      {(context === "reports" && eventName === selectedAirport) ? (
        <div >
          <ul className="legend">
            <li className="legend-list">
              <span className="available"></span> Available
            </li><li className="legend-list">
              <span className="reserved"></span> Arrival Reservation
            </li><li className="legend-list">
              <span className="departures"></span> Departure Reservation
            </li>
          </ul>
        </div>
      ) : (
        <div >
          <ul className="legend">
            <li className="legend-list">
              <span className="available"></span> Available
            </li><li className="legend-list">
              <span className="reserved"></span> Reserved
            </li><li>
              <span className="full"></span> Full
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default AvailabilityGraph;