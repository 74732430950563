export type Reservation = {
  airportId: string;
  requestType: string;
  reservDate: string;
  reservTime: string;
  callSign: string;
  aircraftType: string;
  requestorName: string;
  requestorEmail: string;
};

export interface ReservationDTO {
  airportId: string;
  requestType: string;
  reservDate: string;
  reservTime: string;
  callSign: string;
  aircraftType: string;
  reservNumb: string;
  requestorName?: string;
  requestorEmail?: string;
  createdAt?: Date;
  eventName?: string;
}

export interface ReservationReqDTO {
  airportId: string;
  requestType: string;
  reservDate: string;
  reservTime: string;
  callSign: string;
  aircraftType: string;
  reservNumb: string;
  requestorName?: string;
  requestorEmail?: string;
  createdAt?: Date;
  eventName?: string;
  tokenV3?: string;
  tokenV2?: string;
}

export interface CreateReservationResponse {
  airportId: string;
  requestType: string;
  reservDate: string;
  reservTime: string;
  callSign: string;
  aircraftType: string;
  reservNumb: string;
  requestorName?: string;
  requestorEmail?: string;
  createdAt?: Date;
  isRecaptchaSuccessful: boolean;
  recaptchaErrors: string[];
}

export interface Event {
  id?: string;
  eventType: string;
  eventName: string;
  startTime: Date;
  endTime: Date | undefined | null;
  liveDate: Date | undefined | null;
  eventStatus: string;
  eventDesc: string;
  comments?: string;
}

export interface TableEvent extends Event {
  airportName?: string;
  startTimeStr: string;
  endTimeStr: string;
  liveDateStr: string;
  statusButton: any;
}

export interface Rule {
  eventName: string;
  airportId: string;
  airportName: string;
  requestType: string;
  startDate: Date;
  startTime: string;
  endDate?: Date;
  endTime?: string;
  q1?: number;
  q2?: number;
  q3?: number;
  q4?: number;
  isRepeat: string; // Y - yes; N - no
  occurrence: string; // 0,1,2,3,4,5,6 - every day; 0,3,5 - Su,We,Fr
  createdAt?: Date;
}

export interface ReservationEvent {
  user: UserEntity;
  reservationDTO: ReservationDTO;
}

export interface ReservationAndUserInfo {
  airportId: string;
  requestType: string;
  reservDate: string;
  reservTime: string;
  callSign: string;
  aircraftType: string;
  reservNumb: string;
  requestorName?: string;
  requestorEmail?: string;
  createdAt?: Date;
  eventName?: string;
  oktaId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export type UserEntity = {
  oktaId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type User = {
  oktaId: string;
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  createdAt: string;
  updatedAt: string;
};

export type GroupAccess = {
  company: string;
  group: boolean;
};

export type Group = {
  company: string;
  domain: string;
  contactName: string;
  email: string;
  accessCode: string;
  createdAt: string;
  updatedAt: string;
};

export interface ReservApiResponse extends ReservationDTO {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
}

export type LoggedUser = {
  firstName: string;
  email: string;
  role: string;
  inFaaNetwork: boolean;
};

export interface OAuth2IdToken {
  FirstName: string;
  email: string;
}

export type ActuatorInfo = {
  env: string;
  build?: { version?: string };
  git?: {
    closest?: {
      tag?: {
        name?: string;
      };
    };
  };
};

export interface tableColumnSpec<T> {
  key: keyof T;
  title: string;
  cellClassName: string;
}

export interface Airport {
  airportId: string;
  airportName: string;
}

export interface AircraftType {
  designator: string;
}

export enum MessageType {
  RESERVATIONS = "RESERVATIONS",
}

export enum ActionType {
  NEW = "New",
  MODIFY = "Modify",
  CANCEL = "Cancel",
}

export type RecaptchaActions = "create_reservation" | "modify_reservation";

export enum RecaptchaActionsEnum {
  CREATE = "create_reservation",
  MODIFY = "modify_reservation",
}

export enum DialogAnswer {
  yes,
  no,
  cancel,
}

export interface Configuration {
  recaptchaSiteKey: string | null;
}

export type Config = {
  recaptchaSiteKey: string;
  recaptchaV2SiteKey: string;
};

export interface DataTableSettings {
  segmentHeader: string;
  sortColumn: string;
  sortOrder: string;
  tableId?: string;
  columns: DataTableProperties[];
}

export interface ColumnDefinitionType {
  field: string;
  header: string;
  style?: StyleDefinitionType;
  width?: string;
  dropdown?: string;
}

export interface StyleDefinitionType {
  table?: string;
  tr?: string;
  th?: string;
  td?: string;
  p?: string;
}

export interface DataTableSettings {
  sortColumn: string;
  sortOrder: string;
  selectable?: string;
  select?: any;
  filter: boolean;
  paginator: boolean;
  summary?: string;
  export?: boolean;
  tableId?: string;
  id?: string;
  columns: Array<DataTableProperties>;
  refresh?: boolean;
}

export interface DataTableProperties {
  field: string;
  sort_field?: string;
  header: string;
  format?: string;
  link?: string;
  router_link?: string;
  width?: string;
  btnText?: string;
  btnIcon?: string;
  color?: string;
  tooltipText?: string;
  queryParams?: string;
  icon?: string;
  iconTitle?: string;
  iconAltText?: string;
}

export interface Log {
  id: string;
  oktaId: string;
  userName: string;
  userRole: string;
  actionType: string;
  userAction: string;
  details: string;
  targetId: string;
  createdAt: Date;
}

export interface FormValues {
  airportId: string;
  requestType: string;
  startDate: string;
  endDate: string;
}
