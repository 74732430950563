import React, { useEffect, useState } from "react";
import "./accounts.scss";
// import SectionHeader from "../../components/SectionHeader";
import restHelpers, { API } from "../../helpers/restHelpers";
import { Group, User } from "../../types";
import NewDataTable from "../../components/shared/newtablecomponent/NewDataTable";

export interface AccountsProps {
  setAccountsView?: any;
}

const AccountDataTableSettings: any = {
  segmentHeader: "Roles",
  sortColumn: "",
  sortOrder: "",
  columns: [
    {
      field: "firstName",
      header: "First Name",
      style: {
        th: "list-view-table-header  list-view-table-padding-left list-view-table-fixed",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "lastName",
      header: "Last Name",
      style: {
        th: "list-view-table-header list-view-table-padding-right  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "email",
      header: "Email",
      style: {
        th: "list-view-table-header list-view-table-padding-right  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "userRole",
      header: "User Role",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
      dropdown: true,
    },
  ],
};

const GroupDataTableSettings: any = {
  segmentHeader: "Domains",
  sortColumn: "",
  sortOrder: "",
  columns: [
    {
      field: "company",
      header: "Company",
      style: {
        th: "list-view-table-header  list-view-table-padding-left list-view-table-fixed",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "domain",
      header: "Domain",
      style: {
        th: "list-view-table-header list-view-table-padding-right  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "contactName",
      header: "Contact Name",
      style: {
        th: "list-view-table-header list-view-table-padding-right  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "email",
      header: "Email",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "accessCode",
      header: "Access Code",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text",
        p: "list-view-header-text",
      },
    },
    {
      field: "New Code",
      header: " ",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text group-table-actions new-code",
        p: "list-view-header-text",
      },
    },
    {
      field: "Modify",
      header: " ",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text modify-admin-reservation-button group-table-actions",
        p: "list-view-header-text",
      },
    },
    {
      field: "Remove",  
      header: " ",
      style: {
        th: "list-view-table-header list-view-table-scope-padding  list-view-table-padding-left",
        td: "list-view-table-text admin-cancel-reservation-button group-table-actions",
        p: "list-view-header-text",
      },
    },
  ],
};

const Accounts: React.FC<AccountsProps> = ({ setAccountsView }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedTable, setSelectedTable] = useState('Users');
  const [rerenderTable, setRerenderTable] = useState<Boolean>(false);
  // const [eventQualifier, seteventQualifier] = React.useState({
  //   clearSearch: true,
  // });

  const getSubsequentUsers = () => {
    restHelpers
      .get(API.GetAllUsers)
      .then((activeUsers: User[]) => {
        setUsers(activeUsers);
      })
      .catch((error: any) => ({}));
  };

  const getSubsequentGroups = () => {
    restHelpers
      .get(API.GetAllGroups)
      .then((groups: Group[]) => {
        setGroups(groups);
      })
      .catch((error: any) => ({}));
  };

  useEffect(() => {
    getSubsequentUsers();
    getSubsequentGroups();
  // eslint-disable-next-line
  }, [rerenderTable]);

  return (
    <>
      <section className="event-blocks" data-testid="admin-view">
        <div className="user-group-toggle-container"> 
          <div className="user-group-toggle">
              <div className={selectedTable==='Users' ? "toggle-button-active" : "toggle-button"} id="Users" onClick={() => setSelectedTable('Users')}>
                Users
              </div> 
              <div className={selectedTable==='Groups' ? "toggle-button-active" : "toggle-button"} id="Groups" onClick={() => setSelectedTable('Groups')}>
                Groups
              </div> 
          </div>
        </div>
        {selectedTable==='Users' &&
          <div className="accounts-table">
            {users.length > 0 ? (
              <NewDataTable settings={AccountDataTableSettings} data={users} setData={setUsers}/>
            ) : (
              <p className="empty-state" data-testid="empty-state">
                No Active Accounts.
              </p>
            )}
          </div>
        }
        {selectedTable==='Groups' &&
          <div className="groups-table">
            {users.length > 0 ? (
              <NewDataTable settings={GroupDataTableSettings} data={groups} setData={setGroups} rerenderTable={rerenderTable} setRerenderTable={setRerenderTable}  />
            ) : (
              <p className="empty-state" data-testid="empty-state">
                No Active Groups.
              </p>
            )}
          </div>
        }
      </section>
    </>
  );
};
export default Accounts;
