import React, { useEffect, useState } from "react";
import "./footer.scss";
import { ReactComponent as DotLogo } from "../../static/DOT-logo-blue.svg";
import ExternalLink from "../ExternalLink";
import { ActuatorInfo } from "../../types";
import restHelpers, { API } from "../../helpers/restHelpers";

const Footer: React.FC = () => {
  const [actuatorInfo, setActuatorInfo] = useState<ActuatorInfo>({ env: "PROD" });
  const version = actuatorInfo.git?.closest?.tag?.name!;

  useEffect(() => {
    const fetchActuatorInfo = async () => {
      const response = await restHelpers.getResult(`${API.ActuatorInfo}`);
      response.json().then((info: ActuatorInfo) => {
        setActuatorInfo(info);
      });
    };

    fetchActuatorInfo().catch(console.error);
    // eslint-disable-next-line
  }, []);

  return (
    <footer data-testid="app-footer" aria-label={"Footer Links"}>
      <section className="faa-footer"></section>
      <section className="dot-footer">
        <div className="dot-logo-container">
          <DotLogo data-testid="dot-logo" />
          <address className="dot-address">
            <ExternalLink
              href="https://www.transportation.gov/"
              className={"dot-link"}
              metricLog={"Link Footer DOT Transportation"}
            >
              U.S. Department of Transportation
            </ExternalLink>
            <p>Federal Aviation Administration</p>
            <p>
              800 Independence Avenue, <abbr>SW</abbr>
            </p>
            <p>
              Washington, <abbr>DC</abbr> 20591
            </p>
            <p>
              (866) <span className="uppercase">tell</span>-<abbr>FAA</abbr> (<small>(866) 835-5322</small>)
            </p>
          </address>
        </div>
        <div className="footer-column">
          <h3 className="footer-column-header">Web Policies</h3>
          <a className="dot-link" target="_blank" rel="noreferrer noopener" href="https://www.faa.gov/privacy/">
            Privacy Policy
          </a>
          <a className="dot-link" target="_blank" rel="noreferrer noopener" href="https://www.faa.gov/accessibility/">
            Accessibility
          </a>
          {version && (
            <span className="version-style" data-testid="app-version">
              VERSION {version}
            </span>
          )}
        </div>
      </section>
    </footer>
  );
};

export default Footer;
