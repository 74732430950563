import React, { useEffect } from "react";
import Dropdown from "rsuite/Dropdown";
import { API } from "../../../helpers/restHelpers";
import { Event, Rule, FormValues } from "../../../types";
import { getAndApplyLogic } from "../../../helpers/stateHelpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
dayjs.extend(utc);

export interface EventsAirportDropDownProps {
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  events: Event[];
  reportType: string;
}

const EventsAirportDropDown: React.FC<EventsAirportDropDownProps> = ({
  formValues,
  setFormValues,
  setIsSubmit,
  events,
  reportType,
}) => {
  const [rules, setRules] = React.useState<Rule[]>([]);
  const [uniqueEvents, setUniqueEvents] = React.useState<string[]>([]);
  const [uniqueEventAirport, setUniqueEventAirport] = React.useState<string[]>([]);
  const [HDAAirports, setHDAAirports] = React.useState<string[]>([]);
  const [HDAEvent, setHDAEvent] = React.useState<string[]>([]);
  const [subMenu, setSubMenu] = React.useState<string>("close");

  const handleAiportSelect = (airportName: any) => {
    setFormValues((prevValues) => ({ ...prevValues, airportId: airportName }));
    setIsSubmit(false);
  };

  const openSubMenu = () => {
    if (subMenu === "open") {
      setSubMenu("close");
    } else {
      setSubMenu("open");
    }
  };

  const closeSubMenu = () => {
    setSubMenu("close");
  };

  const getdatafromApi = () => {
    getAndApplyLogic(`${API.ActiveAndDisabledRules}`, (json: Rule[]) => {
      setRules(() => {
        return json.map((rule) => {
          return rule as Rule;
        });
      });
    });
  };

  const getUniqueEvents = () => {
    let uniqEvents = rules!
      .map((item) => item.eventName)
      .filter(
        (value, index, self) =>
          self.indexOf(value) === index);
    const uniqueTags: string[] = [];
    const tempEvents: string[] = [];
    const tempHDAAirport: string[] = [];
    const tempHDAEvent: string[] = [];
    rules!.map((rule) => {
      if (uniqueTags.indexOf(rule.airportName + " (" + rule.airportId + ")|" + rule.eventName) === -1) {
        if (rule.eventName === rule.airportId) {
          // HDA
          uniqEvents.splice(uniqEvents.indexOf(rule.airportId), 1);
          tempEvents.unshift(rule.airportId);
          uniqueTags.unshift(rule.airportName + " (" + rule.airportId + ")|" + rule.eventName);
          tempHDAAirport.unshift(rule.airportName + " (" + rule.airportId + ")");
          tempHDAEvent.unshift("(" + rule.airportId + ")|" + rule.eventName);
        } else {
          // SE
          uniqueTags.push(rule.airportName + " (" + rule.airportId + ")|" + rule.eventName);
        }
      }
      return null;
    });
    // tempHDAAirport.unshift("All Airports");
    uniqEvents = tempEvents.sort().concat(uniqEvents.sort());
    setUniqueEvents(uniqEvents);
    setHDAEvent(tempHDAEvent);
    setHDAAirports(tempHDAAirport.sort());
    setUniqueEventAirport(uniqueTags.sort());
  };

  useEffect(() => {
    getdatafromApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUniqueEvents();
    // eslint-disable-next-line
  }, [rules]);

  return (
    <Dropdown
      disabled={reportType===""}
      title={formValues.airportId.split("|")[0]}
      placement="bottomStart"
      aria-label="airportId"
      activeKey={formValues.airportId}
      data-testid="drop-down-airportId"
    >
      {reportType === "Reservation Table" && 
        <Dropdown.Item onSelect={handleAiportSelect} eventKey="Airports (All)| All Events" aria-label="all-airports">
          All Airports
        </Dropdown.Item>
      }
      {uniqueEvents && uniqueEvents.length > 0
        ? uniqueEvents.map((eventName: string, index) => (
            <React.Fragment key={`rfragment-${eventName}-${index}`}>
              {uniqueEventAirport.length > 0 && HDAEvent.includes("(" + eventName + ")|" + eventName) ? (
                <Dropdown.Item
                  onSelect={handleAiportSelect}
                  eventKey={HDAAirports[index] + "|" + HDAAirports[index]?.split("(")[1].split(")")[0]}
                  aria-label={eventName}
                >
                  {HDAAirports[index]}
                </Dropdown.Item>
              ) : (
                <div onClick={openSubMenu} onMouseLeave={closeSubMenu} className="submenu">
                  <Dropdown.Menu title={eventName} className={`${subMenu}`} aria-label={eventName}>
                    {uniqueEventAirport.length > 1 && reportType==="Reservation Table" && (
                      <Dropdown.Item
                        onSelect={handleAiportSelect}
                        eventKey={eventName + " (All Airports)|" + eventName}
                        id="allAirports"
                        aria-label="allAirports"
                      >
                        {"All Airports"}
                      </Dropdown.Item>
                    )}
                    {uniqueEventAirport.length > 0 &&
                      uniqueEventAirport.includes(eventName + ")|" + eventName) === false &&
                      uniqueEventAirport.map((airport: string) => {
                        return airport.includes(eventName) ? (
                          <Dropdown.Item
                            onSelect={handleAiportSelect}
                            eventKey={airport}
                            id={airport.split("|")[0]}
                            aria-label={airport.split("|")[0]}
                          >
                            {airport.split("|")[0]}
                          </Dropdown.Item>
                        ) : null;
                      })}
                  </Dropdown.Menu>
                </div>
              )}
            </React.Fragment>
          ))
        : null}
    </Dropdown>
  );
};

export default EventsAirportDropDown;
