import { DateTime } from "luxon";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface DateRangeInDayjs {
  rangeStart: dayjs.Dayjs;
  rangeEnd: dayjs.Dayjs;
}

export const toggleZuluAndLocalTime = (
  date: Date | undefined,
  toLocal: boolean = false,
  isHeaderTime: boolean = false
) => {
  if (date) {
    const dateMoment = dayjs(date);

    const dt = DateTime.local(
      dateMoment.year(),
      parseInt(dateMoment.format("MM")),
      parseInt(dateMoment.format("DD")),
      dateMoment.hour(),
      dateMoment.minute(),
      dateMoment.second()
    );

    const localTime = dt.toLocal().toFormat(isHeaderTime ? "DD tt ZZZZ" : "t ZZZZ");
    const utcTime = dt.toUTC().toFormat(isHeaderTime ? "DD HH:mm:ss" : "HHmm") + (isHeaderTime ? " Z" : "Z");

    return toLocal ? localTime : utcTime;
  } else {
    return "";
  }
};

export const formatDateToTimeString = (date: Date | undefined) => {
  if (date) {
    return dayjs(date).utc().format("MMM D, YYYY HH:mm:ss");
  } else {
    return "";
  }
};

export const formatDateString = (date: Date | undefined) => {
  if (date) {
    return dayjs(date).utc().format("MM/D/YYYY HHmm");
  } else {
    return "";
  }
};

export const roundEndDatePlusThreeDays = (minutesToRound: number, date: Date | undefined, eventType: string) => {
  let threeDays = 3 * 86400000;
  let coeff = 1000 * 60 * minutesToRound;
  let formattedEndTime = undefined;

  if (date) {
    let endTme = new Date(date);

    if (date.getMinutes().valueOf() === 0 && date.getSeconds().valueOf() === 0) {
      if (eventType === "SE") {
        formattedEndTime = dayjs(endTme.getTime() + threeDays).add(14, 'minutes')
      } else {
        formattedEndTime = dayjs(endTme.getTime() + threeDays).add(59, 'minutes')
      }
    } else {
      let endTimePlusThreeDays = endTme.getTime() + threeDays;
      let roundedEndTime = new Date(Math.ceil(endTimePlusThreeDays / coeff) * coeff)
      formattedEndTime = dayjs(roundedEndTime).subtract(1, 'minute');
    }

    return dayjs(formattedEndTime).utc().format("MM/D/YYYY HHmm");
  } else {
    return "";
  }
};

export const roundDate = (minutesToRoundBy: number, date: Date) => {
  let coeff = 1000 * 60 * minutesToRoundBy;
  let eventDate = new Date(date);
  let roundedDate = new Date(Math.floor(eventDate.getTime() / coeff) * coeff)
  let formatRoundedDate = formatDateString(roundedDate)

  return formatRoundedDate;
}

export const formatReservationOpenDateString = (date: Date | undefined) => {
  let threeDays = 3 * 86400000

  if (date) {
    let startTime = new Date(date);
    let reservationDateAndTime = startTime.getTime() - threeDays;
    let reservationDate = dayjs(reservationDateAndTime).utc().format("MM/D/YYYY");
    let reservstionTime = dayjs(reservationDateAndTime).utc().format("HHmm");

    return reservationDate + " at " + reservstionTime + "Z.";
  } else {
    return "";
  }
};

// **************************************************************************************
// build a date range in dayjs
// empty string for begin and end date is allowed
export const getDateRange = (startDate: string, endDate: string, midDate?: string, maxRange = 4, maxBack = -2): DateRangeInDayjs | undefined => {
  let dateRange: DateRangeInDayjs | undefined;
  const currentDayjs = midDate ? dayjs(midDate) : dayjs();

  // max range is two months;
  const dayjsMinStartDate = currentDayjs.add(maxBack, "month");
  let dayjsStartDate = (startDate === "" ? dayjsMinStartDate : dayjs(startDate));
  if (dayjsStartDate.isBefore(dayjsMinStartDate)) {
    dayjsStartDate = dayjsMinStartDate;
  }
  const dayjsMaxEndDate = dayjsStartDate.add(maxRange, "month");
  let dayjsEnddate = (endDate === "" ? dayjsMaxEndDate : dayjs(endDate));
  if (dayjsEnddate.isAfter(dayjsMaxEndDate)) {
    dayjsEnddate = dayjsMaxEndDate;
  }
  // sanity check
  if (!dayjsEnddate.isBefore(dayjsStartDate)) {
    dateRange = {
      rangeStart: dayjsStartDate,
      rangeEnd: dayjsEnddate
    };
  }
  return dateRange;
}

// occurrence is in the format such as 0,3,5 which allows Sun, Wed, and Fri.
export const getDaysInRange = (startDate: string, endDate: string, midDate?: string, seed?: string[], occurrence?: string): string[] => {
  const days: string[] = seed ? [...seed] : [];
  const eventDateRange = getDateRange(startDate, endDate, midDate);
  if (eventDateRange) {
    days.push(eventDateRange.rangeStart.format("MM/DD/YY"));
    let thisDay = eventDateRange.rangeStart.add(1, "day");
    while (!thisDay.isAfter(eventDateRange.rangeEnd)) {
      const day = thisDay.day().toString(); // get day of week
      if (occurrence === undefined ||
        occurrence === "" || occurrence.includes(day)) {
        days.push(thisDay.format("MM/DD/YY"));
      }
      thisDay = thisDay.add(1, "day");
    }
  }
  return days;
}

export const isDayInRange = (startDate: string, endDate: string, queryForDay: string, occurrence?: string): boolean => {
  const queryDayjs = dayjs(queryForDay);
  if (endDate !== "" && queryDayjs.isAfter(dayjs(endDate))) {
    return false;
  }
  if (startDate !== "" && queryDayjs.isBefore(dayjs(startDate))) {
    return false;
  }

  if (!occurrence || occurrence === "") {
    // don't care about repeat
    return true;
  }

  const dow = queryDayjs.day().toString(); // get day of week
  if (occurrence.includes(dow)) {
    return true;
  }

  return false;
}
